/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_5a145_jw759_9:not(#\9) {
  display: contents;
}

.awsui_inline-container_5a145_jw759_13:not(#\9) {
  word-break: break-all;
}
.awsui_inline-container-trigger_5a145_jw759_16:not(#\9) {
  margin-inline-end: var(--space-scaled-xxs-7597g1, 4px);
}