div.header {
  color: #023858;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 30px;
  text-align: center;
}

div.subheader {
  padding: 0 30px 30px 30px;
  text-align: center;
}

div.actions {
  padding-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}