/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_box_18wu0_ij102_172.awsui_p-variant_18wu0_ij102_172.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_b-variant_18wu0_ij102_172.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_strong-variant_18wu0_ij102_172.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_code-variant_18wu0_ij102_172.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_pre-variant_18wu0_ij102_172.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_samp-variant_18wu0_ij102_172.awsui_color-default_18wu0_ij102_172:not(#\9) {
  color: var(--color-text-body-default-7v1jfn, #0f141a);
}

.awsui_box_18wu0_ij102_172.awsui_h1-variant_18wu0_ij102_176.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h2-variant_18wu0_ij102_176.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h3-variant_18wu0_ij102_176.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h4-variant_18wu0_ij102_176.awsui_color-default_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h5-variant_18wu0_ij102_176.awsui_color-default_18wu0_ij102_172:not(#\9) {
  color: var(--color-text-heading-default-6yaix2, #0f141a);
}

.awsui_box_18wu0_ij102_172.awsui_small-variant_18wu0_ij102_180.awsui_color-default_18wu0_ij102_172:not(#\9) {
  color: var(--color-text-small-nzfntg, #656871);
}

.awsui_box_18wu0_ij102_172.awsui_a-variant_18wu0_ij102_184.awsui_color-default_18wu0_ij102_172:not(#\9) {
  color: var(--color-text-link-default-enwvrt, #006ce0);
}

.awsui_box_18wu0_ij102_172.awsui_small-variant_18wu0_ij102_180.awsui_font-size-default_18wu0_ij102_188:not(#\9), .awsui_box_18wu0_ij102_172.awsui_code-variant_18wu0_ij102_172.awsui_font-size-default_18wu0_ij102_188:not(#\9) {
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
}

.awsui_box_18wu0_ij102_172.awsui_p-variant_18wu0_ij102_172.awsui_font-size-default_18wu0_ij102_188:not(#\9), .awsui_box_18wu0_ij102_172.awsui_b-variant_18wu0_ij102_172.awsui_font-size-default_18wu0_ij102_188:not(#\9), .awsui_box_18wu0_ij102_172.awsui_strong-variant_18wu0_ij102_172.awsui_font-size-default_18wu0_ij102_188:not(#\9), .awsui_box_18wu0_ij102_172.awsui_pre-variant_18wu0_ij102_172.awsui_font-size-default_18wu0_ij102_188:not(#\9), .awsui_box_18wu0_ij102_172.awsui_samp-variant_18wu0_ij102_172.awsui_font-size-default_18wu0_ij102_188:not(#\9), .awsui_box_18wu0_ij102_172.awsui_a-variant_18wu0_ij102_184.awsui_font-size-default_18wu0_ij102_188:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_h5-variant_18wu0_ij102_176.awsui_font-size-default_18wu0_ij102_188:not(#\9) {
  font-size: var(--font-size-heading-xs-wo4hpf, 14px);
  line-height: var(--line-height-heading-xs-aeleja, 18px);
}

.awsui_box_18wu0_ij102_172.awsui_h4-variant_18wu0_ij102_176.awsui_font-size-default_18wu0_ij102_188:not(#\9) {
  font-size: var(--font-size-heading-s-yykzif, 16px);
  line-height: var(--line-height-heading-s-4i6ewn, 20px);
  letter-spacing: var(--letter-spacing-heading-s-jr3mor, -0.005em);
}

.awsui_box_18wu0_ij102_172.awsui_h3-variant_18wu0_ij102_176.awsui_font-size-default_18wu0_ij102_188:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
}

.awsui_box_18wu0_ij102_172.awsui_h2-variant_18wu0_ij102_176.awsui_font-size-default_18wu0_ij102_188:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
  line-height: var(--line-height-heading-l-mmm3my, 24px);
  letter-spacing: var(--letter-spacing-heading-l-qqjd4y, -0.015em);
}

.awsui_box_18wu0_ij102_172.awsui_h1-variant_18wu0_ij102_176.awsui_font-size-default_18wu0_ij102_188:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  line-height: var(--line-height-heading-xl-avbttk, 30px);
  letter-spacing: var(--letter-spacing-heading-xl-sagq9c, -0.02em);
}

.awsui_box_18wu0_ij102_172.awsui_p-variant_18wu0_ij102_172.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_code-variant_18wu0_ij102_172.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_pre-variant_18wu0_ij102_172.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_samp-variant_18wu0_ij102_172.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_small-variant_18wu0_ij102_180.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_a-variant_18wu0_ij102_184.awsui_font-weight-default_18wu0_ij102_228:not(#\9) {
  font-weight: 400;
}

.awsui_box_18wu0_ij102_172.awsui_strong-variant_18wu0_ij102_172.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_b-variant_18wu0_ij102_172.awsui_font-weight-default_18wu0_ij102_228:not(#\9) {
  font-weight: 700;
}

.awsui_box_18wu0_ij102_172.awsui_h5-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9) {
  font-weight: var(--font-weight-heading-xs-l7rqme, 700);
}

.awsui_box_18wu0_ij102_172.awsui_h4-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9) {
  font-weight: var(--font-weight-heading-s-cwn6wc, 700);
}

.awsui_box_18wu0_ij102_172.awsui_h3-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9) {
  font-weight: var(--font-weight-heading-m-m2ekmb, 700);
}

.awsui_box_18wu0_ij102_172.awsui_h2-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9) {
  font-weight: var(--font-weight-heading-l-f8711v, 700);
}

.awsui_box_18wu0_ij102_172.awsui_h1-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9) {
  font-weight: var(--font-weight-heading-xl-yvsksd, 700);
}

.awsui_box_18wu0_ij102_172.awsui_h1-variant_18wu0_ij102_176:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h2-variant_18wu0_ij102_176:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h3-variant_18wu0_ij102_176:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h4-variant_18wu0_ij102_176:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h5-variant_18wu0_ij102_176:not(#\9), .awsui_box_18wu0_ij102_172.awsui_p-variant_18wu0_ij102_172:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  text-decoration: none;
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: 0;
}
.awsui_box_18wu0_ij102_172.awsui_small-variant_18wu0_ij102_180:not(#\9) {
  display: inline-block;
}
.awsui_box_18wu0_ij102_172.awsui_code-variant_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_pre-variant_18wu0_ij102_172:not(#\9), .awsui_box_18wu0_ij102_172.awsui_samp-variant_18wu0_ij102_172:not(#\9) {
  font-family: var(--font-family-monospace-bftdwf, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background: transparent;
}
.awsui_box_18wu0_ij102_172.awsui_key-label-variant_18wu0_ij102_270:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  font-weight: var(--font-display-label-weight-815ja9, 700);
  color: var(--color-text-label-4yezqy, #0f141a);
  margin-block-end: var(--space-key-value-gap-0517k5, 0px);
}
.awsui_box_18wu0_ij102_172.awsui_gen-ai-label-variant_18wu0_ij102_277:not(#\9) {
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  font-style: italic;
  color: var(--color-text-label-gen-ai-76ots7, #7300e5);
}
.awsui_box_18wu0_ij102_172.awsui_value-large-variant_18wu0_ij102_284:not(#\9) {
  font-size: var(--font-size-display-l-htkq3e, 42px);
  line-height: var(--line-height-display-l-8nioft, 48px);
  letter-spacing: var(--letter-spacing-display-l-9cigop, -0.03em);
  font-weight: var(--font-box-value-large-weight-onoq9k, 700);
  color: inherit;
}
.awsui_box_18wu0_ij102_172.awsui_h1-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h2-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h3-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h4-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_h5-variant_18wu0_ij102_176.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_value-large-variant_18wu0_ij102_284.awsui_font-weight-default_18wu0_ij102_228:not(#\9), .awsui_box_18wu0_ij102_172.awsui_font-weight-heavy_18wu0_ij102_291:not(#\9) {
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}

.awsui_box_18wu0_ij102_172.awsui_color-inverted_18wu0_ij102_296:not(#\9) {
  color: var(--color-text-notification-default-0l2rzu, #f9f9fa);
}
.awsui_box_18wu0_ij102_172.awsui_color-text-label_18wu0_ij102_299:not(#\9) {
  color: var(--color-text-label-4yezqy, #0f141a);
}
.awsui_box_18wu0_ij102_172.awsui_color-text-body-secondary_18wu0_ij102_302:not(#\9) {
  color: var(--color-text-body-secondary-cwla8d, #424650);
}
.awsui_box_18wu0_ij102_172.awsui_color-text-status-error_18wu0_ij102_305:not(#\9) {
  color: var(--color-text-status-error-5676bj, #db0000);
}
.awsui_box_18wu0_ij102_172.awsui_color-text-status-success_18wu0_ij102_308:not(#\9) {
  color: var(--color-text-status-success-gqp5xk, #00802f);
}
.awsui_box_18wu0_ij102_172.awsui_color-text-status-info_18wu0_ij102_311:not(#\9) {
  color: var(--color-text-status-info-7rlubr, #006ce0);
}
.awsui_box_18wu0_ij102_172.awsui_color-text-status-inactive_18wu0_ij102_314:not(#\9) {
  color: var(--color-text-status-inactive-5megna, #656871);
}
.awsui_box_18wu0_ij102_172.awsui_color-text-status-warning_18wu0_ij102_317:not(#\9) {
  color: var(--color-text-status-warning-csaw41, #855900);
}
.awsui_box_18wu0_ij102_172.awsui_color-inherit_18wu0_ij102_320:not(#\9) {
  color: inherit;
}
.awsui_box_18wu0_ij102_172.awsui_font-size-body-s_18wu0_ij102_323:not(#\9) {
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
}
.awsui_box_18wu0_ij102_172.awsui_font-size-body-m_18wu0_ij102_328:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
}
.awsui_box_18wu0_ij102_172.awsui_font-size-heading-xs_18wu0_ij102_332:not(#\9) {
  font-size: var(--font-size-heading-xs-wo4hpf, 14px);
  line-height: var(--line-height-heading-xs-aeleja, 18px);
}
.awsui_box_18wu0_ij102_172.awsui_font-size-heading-s_18wu0_ij102_336:not(#\9) {
  font-size: var(--font-size-heading-s-yykzif, 16px);
  line-height: var(--line-height-heading-s-4i6ewn, 20px);
  letter-spacing: var(--letter-spacing-heading-s-jr3mor, -0.005em);
}
.awsui_box_18wu0_ij102_172.awsui_font-size-heading-m_18wu0_ij102_341:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
}
.awsui_box_18wu0_ij102_172.awsui_font-size-heading-l_18wu0_ij102_346:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
  line-height: var(--line-height-heading-l-mmm3my, 24px);
  letter-spacing: var(--letter-spacing-heading-l-qqjd4y, -0.015em);
}
.awsui_box_18wu0_ij102_172.awsui_font-size-heading-xl_18wu0_ij102_351:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  line-height: var(--line-height-heading-xl-avbttk, 30px);
  letter-spacing: var(--letter-spacing-heading-xl-sagq9c, -0.02em);
}
.awsui_box_18wu0_ij102_172.awsui_font-size-display-l_18wu0_ij102_356:not(#\9) {
  font-size: var(--font-size-display-l-htkq3e, 42px);
  line-height: var(--line-height-display-l-8nioft, 48px);
  letter-spacing: var(--letter-spacing-display-l-9cigop, -0.03em);
}
.awsui_box_18wu0_ij102_172.awsui_font-weight-light_18wu0_ij102_361:not(#\9) {
  font-weight: 300;
}
.awsui_box_18wu0_ij102_172.awsui_font-weight-normal_18wu0_ij102_364:not(#\9) {
  font-weight: 400;
}
.awsui_box_18wu0_ij102_172.awsui_font-weight-bold_18wu0_ij102_367:not(#\9) {
  font-weight: 700;
}
.awsui_box_18wu0_ij102_172.awsui_font-weight-heavy_18wu0_ij102_291:not(#\9) {
  font-weight: var(--font-weight-heavy-cqd9pv, 700);
}

.awsui_t-left_18wu0_ij102_374:not(#\9) {
  text-align: start;
}

.awsui_t-right_18wu0_ij102_378:not(#\9) {
  text-align: end;
}

.awsui_t-center_18wu0_ij102_382:not(#\9) {
  text-align: center;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_box_18wu0_ij102_172.awsui_p-n_18wu0_ij102_399:not(#\9) {
  padding-block: var(--space-scaled-none-vhzhxe, 0px);
  padding-inline: var(--space-none-zrljql, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-n_18wu0_ij102_404:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-n_18wu0_ij102_405:not(#\9) {
  padding-block-start: var(--space-scaled-none-vhzhxe, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-n_18wu0_ij102_409:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-n_18wu0_ij102_410:not(#\9) {
  padding-inline-end: var(--space-none-zrljql, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-n_18wu0_ij102_414:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-n_18wu0_ij102_405:not(#\9) {
  padding-block-end: var(--space-scaled-none-vhzhxe, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-n_18wu0_ij102_419:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-n_18wu0_ij102_410:not(#\9) {
  padding-inline-start: var(--space-none-zrljql, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_p-xxxs_18wu0_ij102_424:not(#\9) {
  padding-block: var(--space-scaled-xxxs-27y4hv, 2px);
  padding-inline: var(--space-xxxs-zbmxqb, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-xxxs_18wu0_ij102_429:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xxxs_18wu0_ij102_430:not(#\9) {
  padding-block-start: var(--space-scaled-xxxs-27y4hv, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-xxxs_18wu0_ij102_434:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xxxs_18wu0_ij102_435:not(#\9) {
  padding-inline-end: var(--space-xxxs-zbmxqb, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-xxxs_18wu0_ij102_439:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xxxs_18wu0_ij102_430:not(#\9) {
  padding-block-end: var(--space-scaled-xxxs-27y4hv, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-xxxs_18wu0_ij102_444:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xxxs_18wu0_ij102_435:not(#\9) {
  padding-inline-start: var(--space-xxxs-zbmxqb, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_p-xxs_18wu0_ij102_449:not(#\9) {
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: var(--space-xxs-p8yyaw, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-xxs_18wu0_ij102_454:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xxs_18wu0_ij102_455:not(#\9) {
  padding-block-start: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-xxs_18wu0_ij102_459:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xxs_18wu0_ij102_460:not(#\9) {
  padding-inline-end: var(--space-xxs-p8yyaw, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-xxs_18wu0_ij102_464:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xxs_18wu0_ij102_455:not(#\9) {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-xxs_18wu0_ij102_469:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xxs_18wu0_ij102_460:not(#\9) {
  padding-inline-start: var(--space-xxs-p8yyaw, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_p-xs_18wu0_ij102_474:not(#\9) {
  padding-block: var(--space-scaled-xs-26e2du, 8px);
  padding-inline: var(--space-xs-zb16t3, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-xs_18wu0_ij102_479:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xs_18wu0_ij102_480:not(#\9) {
  padding-block-start: var(--space-scaled-xs-26e2du, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-xs_18wu0_ij102_484:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xs_18wu0_ij102_485:not(#\9) {
  padding-inline-end: var(--space-xs-zb16t3, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-xs_18wu0_ij102_489:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xs_18wu0_ij102_480:not(#\9) {
  padding-block-end: var(--space-scaled-xs-26e2du, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-xs_18wu0_ij102_494:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xs_18wu0_ij102_485:not(#\9) {
  padding-inline-start: var(--space-xs-zb16t3, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_p-s_18wu0_ij102_499:not(#\9) {
  padding-block: var(--space-scaled-s-aqzyko, 12px);
  padding-inline: var(--space-s-34lx8l, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-s_18wu0_ij102_504:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-s_18wu0_ij102_505:not(#\9) {
  padding-block-start: var(--space-scaled-s-aqzyko, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-s_18wu0_ij102_509:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-s_18wu0_ij102_510:not(#\9) {
  padding-inline-end: var(--space-s-34lx8l, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-s_18wu0_ij102_514:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-s_18wu0_ij102_505:not(#\9) {
  padding-block-end: var(--space-scaled-s-aqzyko, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-s_18wu0_ij102_519:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-s_18wu0_ij102_510:not(#\9) {
  padding-inline-start: var(--space-s-34lx8l, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_p-m_18wu0_ij102_524:not(#\9) {
  padding-block: var(--space-scaled-m-mo5yse, 16px);
  padding-inline: var(--space-m-udix3p, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-m_18wu0_ij102_529:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-m_18wu0_ij102_530:not(#\9) {
  padding-block-start: var(--space-scaled-m-mo5yse, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-m_18wu0_ij102_534:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-m_18wu0_ij102_535:not(#\9) {
  padding-inline-end: var(--space-m-udix3p, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-m_18wu0_ij102_539:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-m_18wu0_ij102_530:not(#\9) {
  padding-block-end: var(--space-scaled-m-mo5yse, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-m_18wu0_ij102_544:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-m_18wu0_ij102_535:not(#\9) {
  padding-inline-start: var(--space-m-udix3p, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_p-l_18wu0_ij102_419:not(#\9) {
  padding-block: var(--space-scaled-l-0hpmd7, 20px);
  padding-inline: var(--space-l-t419sm, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-l_18wu0_ij102_554:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-l_18wu0_ij102_555:not(#\9) {
  padding-block-start: var(--space-scaled-l-0hpmd7, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-l_18wu0_ij102_559:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-l_18wu0_ij102_560:not(#\9) {
  padding-inline-end: var(--space-l-t419sm, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-l_18wu0_ij102_564:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-l_18wu0_ij102_555:not(#\9) {
  padding-block-end: var(--space-scaled-l-0hpmd7, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-l_18wu0_ij102_569:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-l_18wu0_ij102_560:not(#\9) {
  padding-inline-start: var(--space-l-t419sm, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_p-xl_18wu0_ij102_574:not(#\9) {
  padding-block: var(--space-scaled-xl-kswcw7, 24px);
  padding-inline: var(--space-xl-lmui9r, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-xl_18wu0_ij102_579:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xl_18wu0_ij102_580:not(#\9) {
  padding-block-start: var(--space-scaled-xl-kswcw7, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-xl_18wu0_ij102_584:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xl_18wu0_ij102_585:not(#\9) {
  padding-inline-end: var(--space-xl-lmui9r, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-xl_18wu0_ij102_589:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xl_18wu0_ij102_580:not(#\9) {
  padding-block-end: var(--space-scaled-xl-kswcw7, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-xl_18wu0_ij102_594:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xl_18wu0_ij102_585:not(#\9) {
  padding-inline-start: var(--space-xl-lmui9r, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_p-xxl_18wu0_ij102_599:not(#\9) {
  padding-block: var(--space-scaled-xxl-wbot5q, 32px);
  padding-inline: var(--space-xxl-cu2m1r, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-xxl_18wu0_ij102_604:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xxl_18wu0_ij102_605:not(#\9) {
  padding-block-start: var(--space-scaled-xxl-wbot5q, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-xxl_18wu0_ij102_609:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xxl_18wu0_ij102_610:not(#\9) {
  padding-inline-end: var(--space-xxl-cu2m1r, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-xxl_18wu0_ij102_614:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xxl_18wu0_ij102_605:not(#\9) {
  padding-block-end: var(--space-scaled-xxl-wbot5q, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-xxl_18wu0_ij102_619:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xxl_18wu0_ij102_610:not(#\9) {
  padding-inline-start: var(--space-xxl-cu2m1r, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_p-xxxl_18wu0_ij102_624:not(#\9) {
  padding-block: var(--space-scaled-xxxl-fdg8ai, 40px);
  padding-inline: var(--space-xxxl-4x2gki, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_p-top-xxxl_18wu0_ij102_629:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xxxl_18wu0_ij102_630:not(#\9) {
  padding-block-start: var(--space-scaled-xxxl-fdg8ai, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_p-right-xxxl_18wu0_ij102_634:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xxxl_18wu0_ij102_635:not(#\9) {
  padding-inline-end: var(--space-xxxl-4x2gki, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_p-bottom-xxxl_18wu0_ij102_639:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-vertical-xxxl_18wu0_ij102_630:not(#\9) {
  padding-block-end: var(--space-scaled-xxxl-fdg8ai, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_p-left-xxxl_18wu0_ij102_644:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_p-horizontal-xxxl_18wu0_ij102_635:not(#\9) {
  padding-inline-start: var(--space-xxxl-4x2gki, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_m-n_18wu0_ij102_649:not(#\9) {
  margin-block: var(--space-scaled-none-vhzhxe, 0px);
  margin-inline: var(--space-none-zrljql, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-n_18wu0_ij102_654:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-n_18wu0_ij102_655:not(#\9) {
  margin-block-start: var(--space-scaled-none-vhzhxe, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-n_18wu0_ij102_659:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-n_18wu0_ij102_660:not(#\9) {
  margin-inline-end: var(--space-none-zrljql, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-n_18wu0_ij102_664:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-n_18wu0_ij102_655:not(#\9) {
  margin-block-end: var(--space-scaled-none-vhzhxe, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-n_18wu0_ij102_669:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-n_18wu0_ij102_660:not(#\9) {
  margin-inline-start: var(--space-none-zrljql, 0px);
}

.awsui_box_18wu0_ij102_172.awsui_m-xxxs_18wu0_ij102_674:not(#\9) {
  margin-block: var(--space-scaled-xxxs-27y4hv, 2px);
  margin-inline: var(--space-xxxs-zbmxqb, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-xxxs_18wu0_ij102_679:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xxxs_18wu0_ij102_680:not(#\9) {
  margin-block-start: var(--space-scaled-xxxs-27y4hv, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-xxxs_18wu0_ij102_684:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xxxs_18wu0_ij102_685:not(#\9) {
  margin-inline-end: var(--space-xxxs-zbmxqb, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-xxxs_18wu0_ij102_689:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xxxs_18wu0_ij102_680:not(#\9) {
  margin-block-end: var(--space-scaled-xxxs-27y4hv, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-xxxs_18wu0_ij102_694:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xxxs_18wu0_ij102_685:not(#\9) {
  margin-inline-start: var(--space-xxxs-zbmxqb, 2px);
}

.awsui_box_18wu0_ij102_172.awsui_m-xxs_18wu0_ij102_699:not(#\9) {
  margin-block: var(--space-scaled-xxs-7597g1, 4px);
  margin-inline: var(--space-xxs-p8yyaw, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-xxs_18wu0_ij102_704:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xxs_18wu0_ij102_705:not(#\9) {
  margin-block-start: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-xxs_18wu0_ij102_709:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xxs_18wu0_ij102_710:not(#\9) {
  margin-inline-end: var(--space-xxs-p8yyaw, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-xxs_18wu0_ij102_714:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xxs_18wu0_ij102_705:not(#\9) {
  margin-block-end: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-xxs_18wu0_ij102_719:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xxs_18wu0_ij102_710:not(#\9) {
  margin-inline-start: var(--space-xxs-p8yyaw, 4px);
}

.awsui_box_18wu0_ij102_172.awsui_m-xs_18wu0_ij102_724:not(#\9) {
  margin-block: var(--space-scaled-xs-26e2du, 8px);
  margin-inline: var(--space-xs-zb16t3, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-xs_18wu0_ij102_729:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xs_18wu0_ij102_730:not(#\9) {
  margin-block-start: var(--space-scaled-xs-26e2du, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-xs_18wu0_ij102_734:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xs_18wu0_ij102_735:not(#\9) {
  margin-inline-end: var(--space-xs-zb16t3, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-xs_18wu0_ij102_739:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xs_18wu0_ij102_730:not(#\9) {
  margin-block-end: var(--space-scaled-xs-26e2du, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-xs_18wu0_ij102_744:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xs_18wu0_ij102_735:not(#\9) {
  margin-inline-start: var(--space-xs-zb16t3, 8px);
}

.awsui_box_18wu0_ij102_172.awsui_m-s_18wu0_ij102_749:not(#\9) {
  margin-block: var(--space-scaled-s-aqzyko, 12px);
  margin-inline: var(--space-s-34lx8l, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-s_18wu0_ij102_754:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-s_18wu0_ij102_755:not(#\9) {
  margin-block-start: var(--space-scaled-s-aqzyko, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-s_18wu0_ij102_759:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-s_18wu0_ij102_760:not(#\9) {
  margin-inline-end: var(--space-s-34lx8l, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-s_18wu0_ij102_764:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-s_18wu0_ij102_755:not(#\9) {
  margin-block-end: var(--space-scaled-s-aqzyko, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-s_18wu0_ij102_769:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-s_18wu0_ij102_760:not(#\9) {
  margin-inline-start: var(--space-s-34lx8l, 12px);
}

.awsui_box_18wu0_ij102_172.awsui_m-m_18wu0_ij102_774:not(#\9) {
  margin-block: var(--space-scaled-m-mo5yse, 16px);
  margin-inline: var(--space-m-udix3p, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-m_18wu0_ij102_779:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-m_18wu0_ij102_780:not(#\9) {
  margin-block-start: var(--space-scaled-m-mo5yse, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-m_18wu0_ij102_784:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-m_18wu0_ij102_785:not(#\9) {
  margin-inline-end: var(--space-m-udix3p, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-m_18wu0_ij102_789:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-m_18wu0_ij102_780:not(#\9) {
  margin-block-end: var(--space-scaled-m-mo5yse, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-m_18wu0_ij102_794:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-m_18wu0_ij102_785:not(#\9) {
  margin-inline-start: var(--space-m-udix3p, 16px);
}

.awsui_box_18wu0_ij102_172.awsui_m-l_18wu0_ij102_669:not(#\9) {
  margin-block: var(--space-scaled-l-0hpmd7, 20px);
  margin-inline: var(--space-l-t419sm, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-l_18wu0_ij102_804:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-l_18wu0_ij102_805:not(#\9) {
  margin-block-start: var(--space-scaled-l-0hpmd7, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-l_18wu0_ij102_809:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-l_18wu0_ij102_810:not(#\9) {
  margin-inline-end: var(--space-l-t419sm, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-l_18wu0_ij102_814:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-l_18wu0_ij102_805:not(#\9) {
  margin-block-end: var(--space-scaled-l-0hpmd7, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-l_18wu0_ij102_819:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-l_18wu0_ij102_810:not(#\9) {
  margin-inline-start: var(--space-l-t419sm, 20px);
}

.awsui_box_18wu0_ij102_172.awsui_m-xl_18wu0_ij102_824:not(#\9) {
  margin-block: var(--space-scaled-xl-kswcw7, 24px);
  margin-inline: var(--space-xl-lmui9r, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-xl_18wu0_ij102_829:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xl_18wu0_ij102_830:not(#\9) {
  margin-block-start: var(--space-scaled-xl-kswcw7, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-xl_18wu0_ij102_834:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xl_18wu0_ij102_835:not(#\9) {
  margin-inline-end: var(--space-xl-lmui9r, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-xl_18wu0_ij102_839:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xl_18wu0_ij102_830:not(#\9) {
  margin-block-end: var(--space-scaled-xl-kswcw7, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-xl_18wu0_ij102_844:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xl_18wu0_ij102_835:not(#\9) {
  margin-inline-start: var(--space-xl-lmui9r, 24px);
}

.awsui_box_18wu0_ij102_172.awsui_m-xxl_18wu0_ij102_849:not(#\9) {
  margin-block: var(--space-scaled-xxl-wbot5q, 32px);
  margin-inline: var(--space-xxl-cu2m1r, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-xxl_18wu0_ij102_854:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xxl_18wu0_ij102_855:not(#\9) {
  margin-block-start: var(--space-scaled-xxl-wbot5q, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-xxl_18wu0_ij102_859:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xxl_18wu0_ij102_860:not(#\9) {
  margin-inline-end: var(--space-xxl-cu2m1r, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-xxl_18wu0_ij102_864:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xxl_18wu0_ij102_855:not(#\9) {
  margin-block-end: var(--space-scaled-xxl-wbot5q, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-xxl_18wu0_ij102_869:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xxl_18wu0_ij102_860:not(#\9) {
  margin-inline-start: var(--space-xxl-cu2m1r, 32px);
}

.awsui_box_18wu0_ij102_172.awsui_m-xxxl_18wu0_ij102_874:not(#\9) {
  margin-block: var(--space-scaled-xxxl-fdg8ai, 40px);
  margin-inline: var(--space-xxxl-4x2gki, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_m-top-xxxl_18wu0_ij102_879:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xxxl_18wu0_ij102_880:not(#\9) {
  margin-block-start: var(--space-scaled-xxxl-fdg8ai, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_m-right-xxxl_18wu0_ij102_884:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xxxl_18wu0_ij102_885:not(#\9) {
  margin-inline-end: var(--space-xxxl-4x2gki, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_m-bottom-xxxl_18wu0_ij102_889:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-vertical-xxxl_18wu0_ij102_880:not(#\9) {
  margin-block-end: var(--space-scaled-xxxl-fdg8ai, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_m-left-xxxl_18wu0_ij102_894:not(#\9),
.awsui_box_18wu0_ij102_172.awsui_m-horizontal-xxxl_18wu0_ij102_885:not(#\9) {
  margin-inline-start: var(--space-xxxl-4x2gki, 40px);
}

.awsui_box_18wu0_ij102_172.awsui_d-block_18wu0_ij102_899:not(#\9) {
  display: block;
}
.awsui_box_18wu0_ij102_172.awsui_d-inline_18wu0_ij102_902:not(#\9) {
  display: inline;
}
.awsui_box_18wu0_ij102_172.awsui_d-inline-block_18wu0_ij102_905:not(#\9) {
  display: inline-block;
}
.awsui_box_18wu0_ij102_172.awsui_d-none_18wu0_ij102_908:not(#\9) {
  display: none;
}

.awsui_f-left_18wu0_ij102_912:not(#\9) {
  float: inline-start;
}

.awsui_f-right_18wu0_ij102_916:not(#\9) {
  float: inline-end;
}

.awsui_root_18wu0_ij102_920:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}