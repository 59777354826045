/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_grid_14yj0_1emv2_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  flex-wrap: wrap;
  margin-block: calc(var(--space-grid-gutter-ieqn7e, 20px) / -2);
  margin-inline: calc(var(--space-grid-gutter-ieqn7e, 20px) / -2);
  pointer-events: none;
}
.awsui_grid_14yj0_1emv2_145.awsui_no-gutters_14yj0_1emv2_181:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
}

.awsui_grid-column_14yj0_1emv2_186:not(#\9) {
  box-sizing: border-box;
  position: relative;
  padding-block: calc(var(--space-grid-gutter-ieqn7e, 20px) / 2);
  padding-inline: calc(var(--space-grid-gutter-ieqn7e, 20px) / 2);
  display: flex;
}
.awsui_grid_14yj0_1emv2_145.awsui_no-gutters_14yj0_1emv2_181 > .awsui_grid-column_14yj0_1emv2_186:not(#\9) {
  padding-block: 0;
  padding-inline: 0;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-1_14yj0_1emv2_197:not(#\9) {
  flex: 0 0 8.3333333333%;
  max-inline-size: 8.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-1_14yj0_1emv2_201:not(#\9) {
  inset-inline-start: 8.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-1_14yj0_1emv2_204:not(#\9) {
  inset-inline-end: 8.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-2_14yj0_1emv2_207:not(#\9) {
  flex: 0 0 16.6666666667%;
  max-inline-size: 16.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-2_14yj0_1emv2_211:not(#\9) {
  inset-inline-start: 16.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-2_14yj0_1emv2_214:not(#\9) {
  inset-inline-end: 16.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-3_14yj0_1emv2_217:not(#\9) {
  flex: 0 0 25%;
  max-inline-size: 25%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-3_14yj0_1emv2_221:not(#\9) {
  inset-inline-start: 25%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-3_14yj0_1emv2_224:not(#\9) {
  inset-inline-end: 25%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-4_14yj0_1emv2_227:not(#\9) {
  flex: 0 0 33.3333333333%;
  max-inline-size: 33.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-4_14yj0_1emv2_231:not(#\9) {
  inset-inline-start: 33.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-4_14yj0_1emv2_234:not(#\9) {
  inset-inline-end: 33.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-5_14yj0_1emv2_237:not(#\9) {
  flex: 0 0 41.6666666667%;
  max-inline-size: 41.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-5_14yj0_1emv2_241:not(#\9) {
  inset-inline-start: 41.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-5_14yj0_1emv2_244:not(#\9) {
  inset-inline-end: 41.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-6_14yj0_1emv2_247:not(#\9) {
  flex: 0 0 50%;
  max-inline-size: 50%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-6_14yj0_1emv2_251:not(#\9) {
  inset-inline-start: 50%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-6_14yj0_1emv2_254:not(#\9) {
  inset-inline-end: 50%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-7_14yj0_1emv2_257:not(#\9) {
  flex: 0 0 58.3333333333%;
  max-inline-size: 58.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-7_14yj0_1emv2_261:not(#\9) {
  inset-inline-start: 58.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-7_14yj0_1emv2_264:not(#\9) {
  inset-inline-end: 58.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-8_14yj0_1emv2_267:not(#\9) {
  flex: 0 0 66.6666666667%;
  max-inline-size: 66.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-8_14yj0_1emv2_271:not(#\9) {
  inset-inline-start: 66.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-8_14yj0_1emv2_274:not(#\9) {
  inset-inline-end: 66.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-9_14yj0_1emv2_277:not(#\9) {
  flex: 0 0 75%;
  max-inline-size: 75%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-9_14yj0_1emv2_281:not(#\9) {
  inset-inline-start: 75%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-9_14yj0_1emv2_284:not(#\9) {
  inset-inline-end: 75%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-10_14yj0_1emv2_287:not(#\9) {
  flex: 0 0 83.3333333333%;
  max-inline-size: 83.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-10_14yj0_1emv2_291:not(#\9) {
  inset-inline-start: 83.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-10_14yj0_1emv2_294:not(#\9) {
  inset-inline-end: 83.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-11_14yj0_1emv2_297:not(#\9) {
  flex: 0 0 91.6666666667%;
  max-inline-size: 91.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-11_14yj0_1emv2_301:not(#\9) {
  inset-inline-start: 91.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-11_14yj0_1emv2_304:not(#\9) {
  inset-inline-end: 91.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_colspan-12_14yj0_1emv2_307:not(#\9) {
  flex: 0 0 100%;
  max-inline-size: 100%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-12_14yj0_1emv2_311:not(#\9) {
  inset-inline-start: 100%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-12_14yj0_1emv2_314:not(#\9) {
  inset-inline-end: 100%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_push-0_14yj0_1emv2_317:not(#\9) {
  inset-inline-start: auto;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_pull-0_14yj0_1emv2_320:not(#\9) {
  inset-inline-end: auto;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-1_14yj0_1emv2_323:not(#\9) {
  margin-inline-start: 8.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-2_14yj0_1emv2_326:not(#\9) {
  margin-inline-start: 16.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-3_14yj0_1emv2_329:not(#\9) {
  margin-inline-start: 25%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-4_14yj0_1emv2_332:not(#\9) {
  margin-inline-start: 33.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-5_14yj0_1emv2_335:not(#\9) {
  margin-inline-start: 41.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-6_14yj0_1emv2_338:not(#\9) {
  margin-inline-start: 50%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-7_14yj0_1emv2_341:not(#\9) {
  margin-inline-start: 58.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-8_14yj0_1emv2_344:not(#\9) {
  margin-inline-start: 66.6666666667%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-9_14yj0_1emv2_347:not(#\9) {
  margin-inline-start: 75%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-10_14yj0_1emv2_350:not(#\9) {
  margin-inline-start: 83.3333333333%;
}
.awsui_grid-column_14yj0_1emv2_186.awsui_offset-11_14yj0_1emv2_353:not(#\9) {
  margin-inline-start: 91.6666666667%;
}

.awsui_restore-pointer-events_14yj0_1emv2_357:not(#\9) {
  pointer-events: auto;
  inline-size: 100%;
}