/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_dropdown-footer_vm8qf_jxdvh_5:not(#\9) {
  overflow: hidden;
  flex-shrink: 0;
}

.awsui_dropdown-content_vm8qf_jxdvh_10:not(#\9) {
  display: contents;
}