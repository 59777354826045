/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_link_4c84z_dfp90_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: inline;
  white-space: inherit;
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: none;
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_dfp90_145:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_dfp90_145:not(#\9), .awsui-mode-entering .awsui_link_4c84z_dfp90_145:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_dfp90_145:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_dfp90_145:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145:not(#\9):active, .awsui_link_4c84z_dfp90_145:not(#\9):focus, .awsui_link_4c84z_dfp90_145:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9) {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: none;
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9), .awsui-mode-entering .awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9):active, .awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9):focus, .awsui_link_4c84z_dfp90_145.awsui_variant-secondary_4c84z_dfp90_211:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9) {
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9), .awsui-mode-entering .awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9):active, .awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9):focus, .awsui_link_4c84z_dfp90_145.awsui_variant-primary_4c84z_dfp90_246:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9) {
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: 700;
  letter-spacing: 0.005em;
  text-decoration-line: none;
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9), .awsui-mode-entering .awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9):active, .awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9):focus, .awsui_link_4c84z_dfp90_145.awsui_variant-info_4c84z_dfp90_279:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: var(--color-text-link-button-underline-hover-gyir88, transparent);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9) {
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: var(--font-box-value-large-weight-onoq9k, 700);
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9), .awsui-mode-entering .awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9):active, .awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9):focus, .awsui_link_4c84z_dfp90_145.awsui_variant-value-large_4c84z_dfp90_314:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9) {
  color: var(--color-text-interactive-default-tbacaa, #424650);
  font-weight: 700;
  letter-spacing: 0.005em;
  text-decoration-line: none;
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9), .awsui-mode-entering .awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-interactive-hover-r6bflx, #0f141a);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9):active {
  color: var(--color-text-interactive-active-oauice, #0f141a);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9):active, .awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9):focus, .awsui_link_4c84z_dfp90_145.awsui_variant-top-navigation_4c84z_dfp90_346:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: transparent;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9) {
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: var(--font-link-button-weight-jephf1, 700);
  letter-spacing: var(--font-link-button-letter-spacing-yi9f0l, 0.005em);
  text-decoration-line: underline;
  text-decoration-color: var(--color-text-link-button-underline-ik5r9i, transparent);
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9), .awsui-mode-entering .awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9):active, .awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9):focus, .awsui_link_4c84z_dfp90_145.awsui_variant-recovery_4c84z_dfp90_379:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: var(--color-text-link-button-underline-hover-gyir88, transparent);
}
.awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9) {
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  color: var(--color-text-link-button-normal-default-4g1o6u, #006ce0);
  font-weight: 700;
  letter-spacing: var(--font-button-letter-spacing-65ug0o, 0.005em);
  text-decoration-line: none;
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9), .awsui-mode-entering .awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-button-normal-hover-9zux4u, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9):active {
  color: var(--color-text-link-button-normal-active-awr7yi, #002b66);
}
.awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9):active, .awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9):focus, .awsui_link_4c84z_dfp90_145.awsui_button_4c84z_dfp90_412:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: transparent;
}
.awsui_link_4c84z_dfp90_145.awsui_color-inverted_4c84z_dfp90_447:not(#\9) {
  color: var(--color-text-notification-default-0l2rzu, #f9f9fa);
}
.awsui_link_4c84z_dfp90_145.awsui_color-inverted_4c84z_dfp90_447:not(#\9):not(.awsui_button_4c84z_dfp90_412) {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_link_4c84z_dfp90_145.awsui_color-inverted_4c84z_dfp90_447:not(#\9):hover {
  color: var(--color-text-link-inverted-hover-zsv0ev, #ffffff);
}
body[data-awsui-focus-visible=true] .awsui_link_4c84z_dfp90_145:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-kkfop6, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-nv6mhz, #006ce0);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-v8hkrl, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-body-s_4c84z_dfp90_468:not(#\9) {
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  text-underline-offset: 0.3em;
  text-decoration-thickness: 1px;
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-body-m_4c84z_dfp90_475:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-heading-xs_4c84z_dfp90_481:not(#\9) {
  font-size: var(--font-size-heading-xs-wo4hpf, 14px);
  line-height: var(--line-height-heading-xs-aeleja, 18px);
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-heading-s_4c84z_dfp90_487:not(#\9) {
  font-size: var(--font-size-heading-s-yykzif, 16px);
  line-height: var(--line-height-heading-s-4i6ewn, 20px);
  letter-spacing: var(--letter-spacing-heading-s-jr3mor, -0.005em);
  text-underline-offset: 0.3em;
  text-decoration-thickness: 1px;
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-heading-m_4c84z_dfp90_494:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-heading-l_4c84z_dfp90_501:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
  line-height: var(--line-height-heading-l-mmm3my, 24px);
  letter-spacing: var(--letter-spacing-heading-l-qqjd4y, -0.015em);
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-heading-xl_4c84z_dfp90_508:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  line-height: var(--line-height-heading-xl-avbttk, 30px);
  letter-spacing: var(--letter-spacing-heading-xl-sagq9c, -0.02em);
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-display-l_4c84z_dfp90_515:not(#\9) {
  font-size: var(--font-size-display-l-htkq3e, 42px);
  line-height: var(--line-height-display-l-8nioft, 48px);
  letter-spacing: var(--letter-spacing-display-l-9cigop, -0.03em);
  text-underline-offset: 0.25em;
  text-decoration-thickness: 2px;
}
.awsui_link_4c84z_dfp90_145.awsui_font-size-inherit_4c84z_dfp90_522:not(#\9) {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
}

.awsui_icon-wrapper_4c84z_dfp90_530:not(#\9) {
  white-space: nowrap;
}

.awsui_icon_4c84z_dfp90_530:not(#\9) {
  display: inline-block;
}