.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.search {
  margin: 0 20%;
  text-align: center;
  color: #023858;
  margin-bottom: 50px;

  >span {
    display: block;
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  .suggestions {
    margin-top: 30px;

    >span {
      display: block;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 20px;
    }

    >.suggestions-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      & button {
        font-weight: 400;
        color: #5f6b7a;
        font-style: italic;
        font-size: 16px;
        border: 1px solid #7d8998;
        background-color: transparent;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}


.browse {
  text-align: center;
  color: #023858;
  margin-bottom: 20px;

  >span {
    display: block;
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  >div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    >button {
      font-size: 16px;
      border-radius: 20px;
      background-color: transparent;
      width: 210px;
      cursor: pointer;
      display: flex;
      align-items: center;

      & img {
        float: left;
        width: 50px;
        height: 50px;
        padding: 5px 0 5px 5px;
      }

      & div {
        font-size: 15px;
        font-weight: 700;
        width: 100%;
        display: block;
        text-align: center;
        line-height: 1.5;
        white-space: nowrap;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
      }
    }
  }
}

.or {
  color: #023858;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
}

.framework {
  margin-bottom: 40px;

  >button {
    width: 100%;
    color: #023858;
    background-color: transparent;
    border: 2px solid #7d8998;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    padding: 20px;

    >div {
      font-size: 22px;
      font-weight: 700;
    }

    >img {
      width: 90%;
    }
  }
}

@media (min-width: 1550px) {
  .browse {
    >div {
      flex-direction: row;
      gap: 30px;
    }
  }

  .framework {
    margin: 0 20%;

    >button {
      flex-direction: row;
      padding: 5px 0;

      >div {
        white-space: nowrap;
      }

      >img {
        height: 175px;
        width: auto;
      }
    }
  }
}