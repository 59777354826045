/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_expand-toggle-icon_1ss49_kxr50_153:not(#\9) {
  transition: transform var(--motion-duration-rotate-90-4ry26y, 135ms) var(--motion-easing-rotate-90-sb4dk7, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_expand-toggle-icon_1ss49_kxr50_153:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_expand-toggle-icon_1ss49_kxr50_153:not(#\9), .awsui-mode-entering .awsui_expand-toggle-icon_1ss49_kxr50_153:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_expand-toggle-icon_1ss49_kxr50_153:not(#\9) {
  transform: rotate(-90deg);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_expand-toggle-icon_1ss49_kxr50_153:not(#\9):dir(rtl) {
  transform: rotate(90deg);
}
.awsui_expand-toggle-icon-expanded_1ss49_kxr50_174:not(#\9) {
  transform: rotate(0deg);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_expand-toggle-icon-expanded_1ss49_kxr50_174:not(#\9):dir(rtl) {
  transform: rotate(0deg);
}

.awsui_expand-toggle_1ss49_kxr50_153:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  cursor: pointer;
  inline-size: var(--space-m-udix3p, 16px);
  block-size: var(--space-m-udix3p, 16px);
  border-block: 0;
  border-inline: 0;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  background: none;
  outline: 0;
  color: var(--color-text-interactive-default-tbacaa, #424650);
}
body[data-awsui-focus-visible=true] .awsui_expand-toggle_1ss49_kxr50_153:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_expand-toggle_1ss49_kxr50_153:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_expand-toggle_1ss49_kxr50_153:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px));
  inset-block-start: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px));
  inline-size: calc(100% + var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px) + var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px));
  block-size: calc(100% + var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px) + var(--space-button-inline-icon-focus-outline-gutter-vk4r2v, 0px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_expand-toggle_1ss49_kxr50_153:not(#\9):hover {
  color: var(--color-text-interactive-hover-r6bflx, #0f141a);
}
.awsui_expand-toggle_1ss49_kxr50_153:not(#\9):active {
  color: var(--color-text-interactive-active-oauice, #0f141a);
}