/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_resize-active_x7peu_1gvv3_145:not(#\9):not(.awsui_resize-active-with-focus_x7peu_1gvv3_145) * {
  cursor: col-resize;
  -webkit-user-select: none;
          user-select: none;
}

th:not(#\9):not(:last-child) > .awsui_divider_x7peu_1gvv3_150 {
  position: absolute;
  outline: none;
  pointer-events: none;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-block-start: 0;
  min-block-size: var(--line-height-heading-xs-aeleja, 18px);
  max-block-size: calc(100% - calc(2 * var(--space-xs-zb16t3, 8px) + var(--space-xxxs-zbmxqb, 2px)));
  margin-block: auto;
  margin-inline: auto;
  border-inline-start: var(--border-item-width-yel47s, 2px) solid var(--color-border-divider-interactive-default-0c10au, #8c8c94);
  box-sizing: border-box;
}
th:not(#\9):not(:last-child) > .awsui_divider-disabled_x7peu_1gvv3_164 {
  border-inline-start-color: var(--color-border-divider-default-cx07f2, #c6c6cd);
}
th:not(#\9):not(:last-child) > .awsui_divider-active_x7peu_1gvv3_167 {
  border-inline-start: 2px solid var(--color-border-divider-active-tn8t2p, #0f141a);
}

.awsui_resizer_x7peu_1gvv3_171:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  border-block: none;
  border-inline: none;
  background: none;
  inset-block: 0;
  cursor: col-resize;
  position: absolute;
  inset-inline-end: calc(-1 * var(--space-l-t419sm, 20px) / 2);
  inline-size: var(--space-l-t419sm, 20px);
  z-index: 10;
}
.awsui_resizer_x7peu_1gvv3_171:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_resize-active_x7peu_1gvv3_145 .awsui_resizer_x7peu_1gvv3_171:not(#\9) {
  pointer-events: none;
}
th:not(#\9):last-child > .awsui_resizer_x7peu_1gvv3_171 {
  inline-size: calc(var(--space-l-t419sm, 20px) / 2);
  inset-inline-end: 0;
}
.awsui_resizer_x7peu_1gvv3_171:not(#\9):hover + .awsui_divider_x7peu_1gvv3_150 {
  border-inline-start: 2px solid var(--color-border-divider-active-tn8t2p, #0f141a);
}
body[data-awsui-focus-visible=true] .awsui_resizer_x7peu_1gvv3_171.awsui_has-focus_x7peu_1gvv3_225:not(#\9) {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_resizer_x7peu_1gvv3_171.awsui_has-focus_x7peu_1gvv3_225:not(#\9) {
  outline: 2px dotted transparent;
  outline-offset: calc(calc(var(--space-table-header-focus-outline-gutter-7js4en, 0px) - 2px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_resizer_x7peu_1gvv3_171.awsui_has-focus_x7peu_1gvv3_225:not(#\9)::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * calc(var(--space-table-header-focus-outline-gutter-7js4en, 0px) - 2px));
  inset-block-start: calc(-1 * calc(var(--space-table-header-focus-outline-gutter-7js4en, 0px) - 2px));
  inline-size: calc(100% + calc(var(--space-table-header-focus-outline-gutter-7js4en, 0px) - 2px) + calc(var(--space-table-header-focus-outline-gutter-7js4en, 0px) - 2px));
  block-size: calc(100% + calc(var(--space-table-header-focus-outline-gutter-7js4en, 0px) - 2px) + calc(var(--space-table-header-focus-outline-gutter-7js4en, 0px) - 2px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_resizer_x7peu_1gvv3_171.awsui_has-focus_x7peu_1gvv3_225:not(#\9) {
  position: absolute;
}

.awsui_tracker_x7peu_1gvv3_250:not(#\9) {
  display: none;
  position: absolute;
  border-inline-start: var(--border-divider-list-width-27y3k5, 1px) dashed var(--color-border-divider-active-tn8t2p, #0f141a);
  inline-size: 0;
  inset-block: 0;
}
.awsui_resize-active_x7peu_1gvv3_145 .awsui_tracker_x7peu_1gvv3_250:not(#\9) {
  display: block;
}