/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_alert_mx3cw_fqqyr_193:not(#\9) {
  animation: awsui_awsui-motion-fade-in_mx3cw_fqqyr_1 var(--motion-duration-show-paced-q2lo2h, 180ms) var(--motion-easing-show-paced-ne2o0s, ease-out);
}
@keyframes awsui_awsui-motion-fade-in_mx3cw_fqqyr_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_alert_mx3cw_fqqyr_193:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_alert_mx3cw_fqqyr_193:not(#\9), .awsui-mode-entering .awsui_alert_mx3cw_fqqyr_193:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_mx3cw_fqqyr_215:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
  max-inline-size: 100%;
  overflow: hidden;
  display: block;
}
.awsui_root_mx3cw_fqqyr_215.awsui_hidden_mx3cw_fqqyr_250:not(#\9) {
  display: none;
}

.awsui_alert_mx3cw_fqqyr_193:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  border-block: var(--border-width-alert-t0f3yn, 2px) solid;
  border-inline: var(--border-width-alert-t0f3yn, 2px) solid;
  border-start-start-radius: var(--border-radius-alert-mdtrse, 12px);
  border-start-end-radius: var(--border-radius-alert-mdtrse, 12px);
  border-end-start-radius: var(--border-radius-alert-mdtrse, 12px);
  border-end-end-radius: var(--border-radius-alert-mdtrse, 12px);
  padding-block: var(--space-alert-vertical-l6cc71, 8px);
  padding-inline: var(--space-alert-horizontal-eyt1xx, 16px);
  background-color: var(--color-background-container-content-epgqkc, #ffffff);
}
.awsui_alert_mx3cw_fqqyr_193.awsui_with-dismiss_mx3cw_fqqyr_297:not(#\9), .awsui_alert_mx3cw_fqqyr_193.awsui_with-action_mx3cw_fqqyr_297:not(#\9) {
  grid-template-columns: 1fr auto;
}
.awsui_alert_mx3cw_fqqyr_193.awsui_with-action_mx3cw_fqqyr_297.awsui_with-dismiss_mx3cw_fqqyr_297:not(#\9) {
  grid-template-columns: 1fr auto auto;
}
.awsui_alert_mx3cw_fqqyr_193.awsui_breakpoint-default_mx3cw_fqqyr_303.awsui_with-action_mx3cw_fqqyr_297:not(#\9) {
  grid-template-columns: 1fr auto;
}
.awsui_alert_mx3cw_fqqyr_193.awsui_breakpoint-default_mx3cw_fqqyr_303:not(#\9):not(.awsui_with-dismiss_mx3cw_fqqyr_297) {
  grid-template-columns: 1fr;
}

.awsui_hidden_mx3cw_fqqyr_250:not(#\9) {
  display: none;
}

.awsui_initial-hidden_mx3cw_fqqyr_314:not(#\9) {
  overflow: hidden;
  block-size: 0;
}

.awsui_header_mx3cw_fqqyr_319:not(#\9),
.awsui_header-replacement_mx3cw_fqqyr_320:not(#\9) {
  font-weight: 700;
}

.awsui_action_mx3cw_fqqyr_324:not(#\9) {
  white-space: nowrap;
  margin-inline-start: var(--space-alert-action-left-ea9czk, 12px);
}

.awsui_action-slot_mx3cw_fqqyr_329:not(#\9),
.awsui_action-button_mx3cw_fqqyr_330:not(#\9) {
  /* used in test-utils */
}

.awsui_alert-focus-wrapper_mx3cw_fqqyr_334:not(#\9) {
  display: grid;
  grid-template-columns: min-content auto;
}
.awsui_alert-focus-wrapper_mx3cw_fqqyr_334:not(#\9):focus {
  outline: none;
}
body[data-awsui-focus-visible=true] .awsui_alert-focus-wrapper_mx3cw_fqqyr_334:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_alert-focus-wrapper_mx3cw_fqqyr_334:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-cqfd0c, 4px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_alert-focus-wrapper_mx3cw_fqqyr_334:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inset-block-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inline-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  block-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_text_mx3cw_fqqyr_363:not(#\9) {
  min-inline-size: 0;
  padding-block: var(--border-width-alert-t0f3yn, 2px);
  padding-inline: 0;
  margin-block: var(--space-scaled-xxs-7597g1, 4px);
  margin-inline: var(--space-xxs-p8yyaw, 4px);
}
.awsui_text_mx3cw_fqqyr_363.awsui_icon_mx3cw_fqqyr_370:not(#\9) {
  margin-inline-start: 0;
}
.awsui_text_mx3cw_fqqyr_363.awsui_message_mx3cw_fqqyr_373:not(#\9) {
  margin-inline-end: var(--space-alert-message-right-knlb6u, 4px);
}

.awsui_breakpoint-default_mx3cw_fqqyr_303 > .awsui_action_mx3cw_fqqyr_324:not(#\9) {
  grid-row: 2;
  margin-block-end: var(--space-xxs-p8yyaw, 4px);
}
.awsui_breakpoint-default_mx3cw_fqqyr_303.awsui_icon-size-medium_mx3cw_fqqyr_381 > .awsui_action_mx3cw_fqqyr_324:not(#\9) {
  margin-inline-start: calc(var(--size-icon-medium-y9xuoq, 20px) + var(--space-xs-zb16t3, 8px));
}
.awsui_breakpoint-default_mx3cw_fqqyr_303.awsui_icon-size-big_mx3cw_fqqyr_384 > .awsui_action_mx3cw_fqqyr_324:not(#\9) {
  margin-inline-start: calc(var(--size-icon-big-zkdryd, 32px) + var(--space-xs-zb16t3, 8px));
}
.awsui_breakpoint-default_mx3cw_fqqyr_303.awsui_icon-size-normal_mx3cw_fqqyr_387 > .awsui_action_mx3cw_fqqyr_324:not(#\9) {
  margin-inline-start: calc(var(--size-icon-normal-0m1722, 16px) + var(--space-xs-zb16t3, 8px));
}

.awsui_content_mx3cw_fqqyr_391:not(#\9),
.awsui_content-replacement_mx3cw_fqqyr_392:not(#\9) {
  /* used in test-utils */
}

.awsui_dismiss_mx3cw_fqqyr_396:not(#\9) {
  margin-inline-end: calc(-1 * var(--space-xxs-p8yyaw, 4px));
  margin-inline-start: var(--space-s-34lx8l, 12px);
}

.awsui_dismiss-button_mx3cw_fqqyr_401:not(#\9) {
  /* used in test-utils */
}

.awsui_type-error_mx3cw_fqqyr_405:not(#\9) {
  border-color: var(--color-border-status-error-adxa56, #db0000);
  background-color: var(--color-background-status-error-5fz45j, #fff5f5);
}
.awsui_type-error_mx3cw_fqqyr_405 > .awsui_alert-focus-wrapper_mx3cw_fqqyr_334 > .awsui_icon_mx3cw_fqqyr_370:not(#\9) {
  color: var(--color-text-status-error-5676bj, #db0000);
}

.awsui_type-warning_mx3cw_fqqyr_413:not(#\9) {
  border-color: var(--color-border-status-warning-3feumr, #855900);
  background-color: var(--color-background-status-warning-03nxlw, #fffef0);
}
.awsui_type-warning_mx3cw_fqqyr_413 > .awsui_alert-focus-wrapper_mx3cw_fqqyr_334 > .awsui_icon_mx3cw_fqqyr_370:not(#\9) {
  color: var(--color-text-status-warning-csaw41, #855900);
}

.awsui_type-success_mx3cw_fqqyr_421:not(#\9) {
  border-color: var(--color-border-status-success-4x6srk, #00802f);
  background-color: var(--color-background-status-success-ua29ej, #effff1);
}
.awsui_type-success_mx3cw_fqqyr_421 > .awsui_alert-focus-wrapper_mx3cw_fqqyr_334 > .awsui_icon_mx3cw_fqqyr_370:not(#\9) {
  color: var(--color-text-status-success-gqp5xk, #00802f);
}

.awsui_type-info_mx3cw_fqqyr_429:not(#\9) {
  border-color: var(--color-border-status-info-z6pwqy, #006ce0);
  background-color: var(--color-background-status-info-gflgo4, #f0fbff);
}
.awsui_type-info_mx3cw_fqqyr_429 > .awsui_alert-focus-wrapper_mx3cw_fqqyr_334 > .awsui_icon_mx3cw_fqqyr_370:not(#\9) {
  color: var(--color-text-status-info-7rlubr, #006ce0);
}