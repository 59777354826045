/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_selectable-item_15o6u_ieaqn_145:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  position: relative;
  list-style: none;
  z-index: 1;
  border-block: var(--border-divider-list-width-27y3k5, 1px) solid transparent;
  border-inline: var(--border-divider-list-width-27y3k5, 1px) solid transparent;
  border-block-start-color: var(--color-border-dropdown-item-default-xiw1xo, #c6c6cd);
  border-block-end-color: var(--color-border-dropdown-item-default-xiw1xo, #c6c6cd);
  background-color: var(--color-background-dropdown-item-default-u8emhk, #ffffff);
  color: var(--color-text-dropdown-item-default-wpxsrk, #0f141a);
  border-inline-start-width: 0;
  border-inline-end-width: 0;
  padding-block: calc(var(--space-xxs-p8yyaw, 4px) + var(--border-item-width-yel47s, 2px) - var(--border-divider-list-width-27y3k5, 1px));
  padding-inline: calc(var(--space-field-horizontal-gg19kw, 12px) + var(--border-item-width-yel47s, 2px));
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_pad-bottom_15o6u_ieaqn_167:not(#\9) {
  padding-block-end: calc(calc(var(--space-xxs-p8yyaw, 4px) + var(--border-item-width-yel47s, 2px) - var(--border-divider-list-width-27y3k5, 1px)) + var(--space-xxxs-zbmxqb, 2px));
  border-block-end-color: transparent;
}
.awsui_selectable-item_15o6u_ieaqn_145:not(#\9):not(:first-child) {
  margin-block-start: calc(-1 * var(--border-item-width-yel47s, 2px));
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_has-background_15o6u_ieaqn_174:not(#\9) {
  background-color: var(--color-background-dropdown-item-hover-zhzc84, #f3f3f7);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_highlighted_15o6u_ieaqn_177:not(#\9), .awsui_selectable-item_15o6u_ieaqn_145.awsui_selected_15o6u_ieaqn_177:not(#\9) {
  color: var(--color-text-dropdown-item-highlighted-rnfxxj, #0f141a);
  border-width: var(--border-item-width-yel47s, 2px);
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: var(--space-field-horizontal-gg19kw, 12px);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_highlighted_15o6u_ieaqn_177.awsui_pad-bottom_15o6u_ieaqn_167:not(#\9), .awsui_selectable-item_15o6u_ieaqn_145.awsui_selected_15o6u_ieaqn_177.awsui_pad-bottom_15o6u_ieaqn_167:not(#\9) {
  padding-block-end: calc(var(--space-xxs-p8yyaw, 4px) + var(--space-xxxs-zbmxqb, 2px));
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_highlighted_15o6u_ieaqn_177:not(#\9) {
  z-index: 3;
  background-color: var(--color-background-dropdown-item-hover-zhzc84, #f3f3f7);
  border-color: var(--color-border-dropdown-item-hover-4p0gsi, #8c8c94);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_highlighted_15o6u_ieaqn_177.awsui_disabled_15o6u_ieaqn_195:not(#\9) {
  color: var(--color-text-dropdown-item-dimmed-7c5xo0, #b4b4bb);
  border-color: var(--color-border-dropdown-item-dimmed-hover-6qayyi, #8c8c94);
  background-color: var(--color-background-dropdown-item-dimmed-dlm6wk, transparent);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_selected_15o6u_ieaqn_177:not(#\9) {
  z-index: 2;
  background-color: var(--color-background-dropdown-item-selected-idfej7, #f0fbff);
  border-color: var(--color-border-dropdown-item-selected-rtthnt, #006ce0);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_selected_15o6u_ieaqn_177.awsui_next-item-selected_15o6u_ieaqn_205:not(#\9) {
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_selected_15o6u_ieaqn_177.awsui_highlighted_15o6u_ieaqn_177:not(#\9) {
  border-color: var(--color-border-dropdown-item-hover-4p0gsi, #8c8c94);
  z-index: 3;
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_selected_15o6u_ieaqn_177 + .awsui_selectable-item_15o6u_ieaqn_145.awsui_selected_15o6u_ieaqn_177:not(#\9) {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_highlighted_15o6u_ieaqn_177.awsui_is-keyboard_15o6u_ieaqn_217:not(#\9) {
  border-color: var(--color-border-dropdown-item-focused-5afjvu, #424650);
  box-shadow: inset 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_parent_15o6u_ieaqn_221:not(#\9) {
  font-weight: bold;
  color: var(--color-text-dropdown-group-label-iy2p4t, #424650);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_parent_15o6u_ieaqn_221:not(#\9):not(.awsui_interactiveGroups_15o6u_ieaqn_225) {
  border-block-start-color: var(--color-border-dropdown-group-2xoluv, #c6c6cd);
  padding-block: var(--space-xs-zb16t3, 8px);
  padding-inline: var(--space-xs-zb16t3, 8px);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_parent_15o6u_ieaqn_221.awsui_interactiveGroups_15o6u_ieaqn_225:not(#\9) {
  padding-block: calc(var(--space-xs-zb16t3, 8px) + var(--border-item-width-yel47s, 2px) - var(--border-divider-list-width-27y3k5, 1px));
  padding-inline: calc(var(--space-field-horizontal-gg19kw, 12px) + var(--border-item-width-yel47s, 2px));
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_parent_15o6u_ieaqn_221.awsui_interactiveGroups_15o6u_ieaqn_225.awsui_highlighted_15o6u_ieaqn_177:not(#\9) {
  color: var(--color-text-dropdown-item-highlighted-rnfxxj, #0f141a);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_parent_15o6u_ieaqn_221.awsui_interactiveGroups_15o6u_ieaqn_225.awsui_highlighted_15o6u_ieaqn_177:not(#\9), .awsui_selectable-item_15o6u_ieaqn_145.awsui_parent_15o6u_ieaqn_221.awsui_interactiveGroups_15o6u_ieaqn_225.awsui_selected_15o6u_ieaqn_177:not(#\9) {
  padding-block: var(--space-xs-zb16t3, 8px);
  padding-inline: var(--space-field-horizontal-gg19kw, 12px);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_child_15o6u_ieaqn_241:not(#\9) {
  padding-inline-start: calc(var(--space-xxl-cu2m1r, 32px) + var(--border-item-width-yel47s, 2px));
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_child_15o6u_ieaqn_241.awsui_highlighted_15o6u_ieaqn_177:not(#\9), .awsui_selectable-item_15o6u_ieaqn_145.awsui_child_15o6u_ieaqn_241.awsui_selected_15o6u_ieaqn_177:not(#\9) {
  padding-inline-start: var(--space-xxl-cu2m1r, 32px);
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_disabled_15o6u_ieaqn_195:not(#\9) {
  color: var(--color-text-dropdown-item-disabled-7fg5u8, #b4b4bb);
}
.awsui_selectable-item_15o6u_ieaqn_145:not(#\9):not(.awsui_disabled_15o6u_ieaqn_195):not(.awsui_parent_15o6u_ieaqn_221) {
  cursor: pointer;
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_interactiveGroups_15o6u_ieaqn_225:not(#\9):not(.awsui_disabled_15o6u_ieaqn_195) {
  cursor: pointer;
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_virtual_15o6u_ieaqn_256:not(#\9) {
  position: absolute;
  inset-block-start: var(--border-dropdown-virtual-offset-width-kekgc0, 2px);
  inset-inline-start: 0;
  inline-size: 100%;
  box-sizing: border-box;
}
.awsui_selectable-item_15o6u_ieaqn_145.awsui_virtual_15o6u_ieaqn_256:not(#\9):first-of-type:not(.awsui_selected_15o6u_ieaqn_177, .awsui_highlighted_15o6u_ieaqn_177) {
  border-block-start-color: var(--color-border-dropdown-item-top-ky64jq, transparent);
}

.awsui_measure-strut_15o6u_ieaqn_267:not(#\9) {
  position: absolute;
  pointer-events: none;
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  padding-block-start: var(--border-divider-list-width-27y3k5, 1px);
}
.awsui_measure-strut-first_15o6u_ieaqn_276:not(#\9) {
  padding-block-end: var(--border-divider-list-width-27y3k5, 1px);
}

.awsui_screenreader-content_15o6u_ieaqn_280:not(#\9) {
  position: absolute !important;
  inset-block-start: -9999px !important;
  inset-inline-start: -9999px !important;
}

.awsui_option-content_15o6u_ieaqn_286:not(#\9) {
  /* used in test-utils */
}