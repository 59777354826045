/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
:root {
  --color-transparent-0ltpn5:transparent;
  --color-charts-red-300-l64jkq:#ea7158;
  --color-charts-red-400-wa91b2:#dc5032;
  --color-charts-red-500-clvvu0:#d13313;
  --color-charts-red-600-n5f5z7:#ba2e0f;
  --color-charts-red-700-9qnabz:#a82a0c;
  --color-charts-red-800-03myov:#972709;
  --color-charts-red-900-t7y4st:#892407;
  --color-charts-red-1000-2569eu:#7d2105;
  --color-charts-red-1100-utabtk:#721e03;
  --color-charts-red-1200-p41vo4:#671c00;
  --color-charts-orange-300-8xdemd:#e07941;
  --color-charts-orange-400-98kh01:#cc5f21;
  --color-charts-orange-500-3exfo1:#bc4d01;
  --color-charts-orange-600-tyzxch:#a84401;
  --color-charts-orange-700-9es7jg:#983c02;
  --color-charts-orange-800-zzyr3p:#8a3603;
  --color-charts-orange-900-ozn3mk:#7e3103;
  --color-charts-orange-1000-2j9x6g:#732c02;
  --color-charts-orange-1100-gbb7zd:#692801;
  --color-charts-orange-1200-k1a2bc:#602400;
  --color-charts-yellow-300-ud5z8m:#b2911c;
  --color-charts-yellow-400-er893i:#9c7b0b;
  --color-charts-yellow-500-zf6yw3:#8a6b05;
  --color-charts-yellow-600-e1b0zr:#7b5f04;
  --color-charts-yellow-700-3ihyrv:#6f5504;
  --color-charts-yellow-800-2luor7:#654d03;
  --color-charts-yellow-900-k8cds4:#5d4503;
  --color-charts-yellow-1000-9ibbxn:#553f03;
  --color-charts-yellow-1100-igbqae:#4d3901;
  --color-charts-yellow-1200-qg1amh:#483300;
  --color-charts-green-300-gduyir:#67a353;
  --color-charts-green-400-jvx3c1:#41902c;
  --color-charts-green-500-uj364g:#1f8104;
  --color-charts-green-600-mbov5u:#1a7302;
  --color-charts-green-700-m7xx1p:#176702;
  --color-charts-green-800-97d577:#145d02;
  --color-charts-green-900-79vtkm:#125502;
  --color-charts-green-1000-dw0ace:#104d01;
  --color-charts-green-1100-fdxgm3:#0f4601;
  --color-charts-green-1200-0w78ld:#0d4000;
  --color-charts-teal-300-b1amlp:#2ea597;
  --color-charts-teal-400-75fmn4:#1c8e81;
  --color-charts-teal-500-5p6o7k:#0d7d70;
  --color-charts-teal-600-1ar5in:#096f64;
  --color-charts-teal-700-3m4vci:#06645a;
  --color-charts-teal-800-0fx1b9:#045b52;
  --color-charts-teal-900-a0x7u4:#03524a;
  --color-charts-teal-1000-n2wukj:#014b44;
  --color-charts-teal-1100-3rvzfn:#01443e;
  --color-charts-teal-1200-hfik8s:#003e38;
  --color-charts-blue-1-300-uwr1wq:#529ccb;
  --color-charts-blue-1-400-y4dmzv:#3184c2;
  --color-charts-blue-1-500-jj2qqx:#0273bb;
  --color-charts-blue-1-600-ntcnzw:#0166ab;
  --color-charts-blue-1-700-vhr7bu:#015b9d;
  --color-charts-blue-1-800-zq4na2:#015292;
  --color-charts-blue-1-900-pu28p4:#014a87;
  --color-charts-blue-1-1000-a1wpur:#01437d;
  --color-charts-blue-1-1100-fyl0u6:#003c75;
  --color-charts-blue-1-1200-y913fp:#00366d;
  --color-charts-blue-2-300-sh1kxw:#688ae8;
  --color-charts-blue-2-400-s269w5:#5978e3;
  --color-charts-blue-2-500-y9grom:#4066df;
  --color-charts-blue-2-600-ljsd9s:#3759ce;
  --color-charts-blue-2-700-8pne2y:#314fbf;
  --color-charts-blue-2-800-v3m6dn:#2c46b1;
  --color-charts-blue-2-900-2rg9qw:#273ea5;
  --color-charts-blue-2-1000-ore46l:#23379b;
  --color-charts-blue-2-1100-rj1e0b:#1f3191;
  --color-charts-blue-2-1200-i1tjtx:#1b2b88;
  --color-charts-purple-300-egsaob:#a783e1;
  --color-charts-purple-400-y2qlpj:#9469d6;
  --color-charts-purple-500-akrt38:#8456ce;
  --color-charts-purple-600-dnhyx7:#7749bf;
  --color-charts-purple-700-sk05tr:#6b40b2;
  --color-charts-purple-800-g5mys1:#6237a7;
  --color-charts-purple-900-ap3bee:#59309d;
  --color-charts-purple-1000-pymhe3:#512994;
  --color-charts-purple-1100-f2iopl:#4a238b;
  --color-charts-purple-1200-aic8dr:#431d84;
  --color-charts-pink-300-cctxfi:#da7596;
  --color-charts-pink-400-kpryka:#ce567c;
  --color-charts-pink-500-tccj7z:#c33d69;
  --color-charts-pink-600-4bki3e:#b1325c;
  --color-charts-pink-700-gexeia:#a32952;
  --color-charts-pink-800-9fchm4:#962249;
  --color-charts-pink-900-z4eeu8:#8b1b42;
  --color-charts-pink-1000-7uou4m:#81143b;
  --color-charts-pink-1100-j0uevl:#780d35;
  --color-charts-pink-1200-jpnl1t:#6f062f;
  --color-charts-status-critical-vygogl:#7d2105;
  --color-charts-status-high-xmh5vl:#ba2e0f;
  --color-charts-status-medium-le4kdn:#cc5f21;
  --color-charts-status-low-seyj13:#b2911c;
  --color-charts-status-positive-g1vr65:#67a353;
  --color-charts-status-info-10v3c7:#3184c2;
  --color-charts-status-neutral-en4ep8:#8c8c94;
  --color-charts-threshold-negative-dj5crf:#db0000;
  --color-charts-threshold-positive-zo9s0l:#00802f;
  --color-charts-threshold-info-rgb5hd:#006ce0;
  --color-charts-threshold-neutral-kq30cz:#656871;
  --color-charts-line-grid-vaxun1:#dedee3;
  --color-charts-line-tick-6i81s1:#dedee3;
  --color-charts-line-axis-d4bcyf:#dedee3;
  --color-charts-palette-categorical-1-ld9m1p:#688ae8;
  --color-charts-palette-categorical-2-jwteam:#c33d69;
  --color-charts-palette-categorical-3-tlwr5r:#2ea597;
  --color-charts-palette-categorical-4-prlvih:#8456ce;
  --color-charts-palette-categorical-5-04vsso:#e07941;
  --color-charts-palette-categorical-6-2wgmsi:#3759ce;
  --color-charts-palette-categorical-7-w06irv:#962249;
  --color-charts-palette-categorical-8-1qnvcp:#096f64;
  --color-charts-palette-categorical-9-0342zm:#6237a7;
  --color-charts-palette-categorical-10-u73kns:#a84401;
  --color-charts-palette-categorical-11-tloc2a:#273ea5;
  --color-charts-palette-categorical-12-23xv0d:#780d35;
  --color-charts-palette-categorical-13-tuq7ce:#03524a;
  --color-charts-palette-categorical-14-bom4ss:#4a238b;
  --color-charts-palette-categorical-15-kpqcpe:#7e3103;
  --color-charts-palette-categorical-16-pzlo2l:#1b2b88;
  --color-charts-palette-categorical-17-spn4mx:#ce567c;
  --color-charts-palette-categorical-18-kxbv8i:#003e38;
  --color-charts-palette-categorical-19-cl97gf:#9469d6;
  --color-charts-palette-categorical-20-gzcnb4:#602400;
  --color-charts-palette-categorical-21-vyqcuq:#4066df;
  --color-charts-palette-categorical-22-6r7gq7:#a32952;
  --color-charts-palette-categorical-23-7c98ot:#0d7d70;
  --color-charts-palette-categorical-24-0i0wmq:#6b40b2;
  --color-charts-palette-categorical-25-7j0tso:#bc4d01;
  --color-charts-palette-categorical-26-eql7mt:#2c46b1;
  --color-charts-palette-categorical-27-8doiqc:#81143b;
  --color-charts-palette-categorical-28-tlstes:#045b52;
  --color-charts-palette-categorical-29-noa7ft:#512994;
  --color-charts-palette-categorical-30-ttbs6s:#8a3603;
  --color-charts-palette-categorical-31-lz0ukh:#1f3191;
  --color-charts-palette-categorical-32-ncpn1t:#da7596;
  --color-charts-palette-categorical-33-fpbcye:#01443e;
  --color-charts-palette-categorical-34-sk46nw:#a783e1;
  --color-charts-palette-categorical-35-wwtyjx:#692801;
  --color-charts-palette-categorical-36-7v0fgy:#5978e3;
  --color-charts-palette-categorical-37-b2rc0w:#b1325c;
  --color-charts-palette-categorical-38-nlzlk1:#1c8e81;
  --color-charts-palette-categorical-39-esgczm:#7749bf;
  --color-charts-palette-categorical-40-0hdln6:#cc5f21;
  --color-charts-palette-categorical-41-bsk8cf:#314fbf;
  --color-charts-palette-categorical-42-97om84:#8b1b42;
  --color-charts-palette-categorical-43-euzcqg:#06645a;
  --color-charts-palette-categorical-44-a7wos6:#59309d;
  --color-charts-palette-categorical-45-iz0wz2:#983c02;
  --color-charts-palette-categorical-46-nzigym:#23379b;
  --color-charts-palette-categorical-47-croxgd:#6f062f;
  --color-charts-palette-categorical-48-tptw1p:#014b44;
  --color-charts-palette-categorical-49-6lrkes:#431d84;
  --color-charts-palette-categorical-50-mqbaxr:#732c02;
  --color-background-notification-severity-critical-m6z340:#870303;
  --color-background-notification-severity-high-zn7yde:#ce3311;
  --color-background-notification-severity-medium-empymi:#f89256;
  --color-background-notification-severity-low-jkxbp4:#f2cd54;
  --color-background-notification-severity-neutral-wtuxqz:#656871;
  --color-text-notification-severity-critical-cmj0dk:#f9f9fa;
  --color-text-notification-severity-high-rgh0m2:#f9f9fa;
  --color-text-notification-severity-medium-9rk0hm:#0f141a;
  --color-text-notification-severity-low-so8wqb:#0f141a;
  --color-text-notification-severity-neutral-h04hdx:#f9f9fa;
  --color-background-badge-icon-n0lc3o:#db0000;
  --color-background-button-link-active-zsvqyt:#d1f1ff;
  --color-background-button-link-hover-7joibg:#f0fbff;
  --color-background-button-normal-active-udb472:#d1f1ff;
  --color-background-button-normal-default-fauhr6:#ffffff;
  --color-background-button-normal-disabled-h6arvb:#ffffff;
  --color-background-button-normal-hover-rw8abf:#f0fbff;
  --color-background-toggle-button-normal-pressed-cpfehj:#d1f1ff;
  --color-background-button-primary-active-qz8503:#002b66;
  --color-background-button-primary-default-j68ske:#006ce0;
  --color-background-button-primary-disabled-l4i17m:#ebebf0;
  --color-background-button-primary-hover-wgg8kk:#002b66;
  --color-background-direction-button-active-hvd0pc:#232b37;
  --color-background-direction-button-default-0p1y3c:#424650;
  --color-background-direction-button-disabled-54epp1:#ebebf0;
  --color-background-direction-button-hover-pwck9a:#333843;
  --color-text-direction-button-default-554zrt:#ffffff;
  --color-text-direction-button-disabled-ok08o0:#b4b4bb;
  --color-background-calendar-current-date-a6ucrp:#f3f3f7;
  --color-background-cell-shaded-sc089k:#f6f6f9;
  --color-background-code-editor-gutter-active-line-default-v8is2q:#656871;
  --color-background-code-editor-gutter-active-line-error-bd4ln4:#db0000;
  --color-background-code-editor-gutter-default-v5e78w:#f3f3f7;
  --color-background-code-editor-loading-2zupgi:#f9f9fa;
  --color-background-code-editor-pane-item-hover-h04m4p:#ebebf0;
  --color-background-code-editor-status-bar-ybpgwu:#f3f3f7;
  --color-background-container-content-epgqkc:#ffffff;
  --color-background-container-header-clzg6q:#ffffff;
  --color-background-control-checked-hz4k40:#006ce0;
  --color-background-control-default-5ol8rv:#ffffff;
  --color-background-control-disabled-5cbvij:#dedee3;
  --color-background-dropdown-item-default-u8emhk:#ffffff;
  --color-background-dropdown-item-dimmed-dlm6wk:transparent;
  --color-background-dropdown-item-filter-match-5pchhq:#f0fbff;
  --color-background-dropdown-item-hover-zhzc84:#f3f3f7;
  --color-background-dropdown-item-selected-idfej7:#f0fbff;
  --color-background-home-header-eungpy:#0f141a;
  --color-background-input-default-k0a6tw:#ffffff;
  --color-background-input-disabled-gvxsk4:#ebebf0;
  --color-background-item-selected-njak80:#f0fbff;
  --color-background-layout-main-cp7lkl:#ffffff;
  --color-background-layout-mobile-panel-o50s90:#0f141a;
  --color-background-layout-panel-content-b4s8gn:#ffffff;
  --color-background-layout-panel-hover-1ftnua:#ebebf0;
  --color-background-layout-toggle-active-d7mkf3:#ffffff;
  --color-background-layout-toggle-default-3db1tp:#ffffff;
  --color-background-layout-toggle-hover-6qw50e:#ffffff;
  --color-background-layout-toggle-selected-active-tv8i8b:#006ce0;
  --color-background-layout-toggle-selected-default-27rnk6:#006ce0;
  --color-background-layout-toggle-selected-hover-eh8bga:#004a9e;
  --color-background-modal-overlay-lal4at:rgba(35, 43, 55, 0.7);
  --color-background-notification-blue-qspr3y:#dddddd;
  --color-background-notification-green-7zevmb:#00802f;
  --color-background-notification-grey-jyn3j2:#424650;
  --color-background-notification-red-f0cy22:#db0000;
  --color-background-notification-yellow-4teyr2:#ffe347;
  --color-background-notification-stack-bar-fl11np:#232b37;
  --color-background-notification-stack-bar-active-4ibdhd:#232b37;
  --color-background-notification-stack-bar-hover-z4ly3h:#424650;
  --color-background-popover-eqi5zi:#ffffff;
  --color-background-progress-bar-content-default-sn7ya6:#006ce0;
  --color-background-progress-bar-content-in-flash-ikihd5:#ffffff;
  --color-background-progress-bar-layout-default-urc8t0:#ebebf0;
  --color-background-progress-bar-layout-in-flash-d3q12s:rgba(255, 255, 255, 0.25);
  --color-background-segment-active-bkvyiy:#006ce0;
  --color-background-segment-default-voaiuw:#ffffff;
  --color-background-segment-disabled-9tsgp1:#ffffff;
  --color-background-segment-hover-detiep:#f0fbff;
  --color-background-slider-handle-default-94lnn1:#006ce0;
  --color-background-slider-handle-active-7w7xg0:#004a9e;
  --color-background-slider-track-uhckut:#8c8c94;
  --color-background-slider-handle-ring-nibud6:#ffffff;
  --color-background-slider-error-pressed-j572od:#700000;
  --color-background-slider-warning-pressed-pg5aih:#855900;
  --color-background-status-error-5fz45j:#fff5f5;
  --color-background-status-info-gflgo4:#f0fbff;
  --color-background-status-success-ua29ej:#effff1;
  --color-background-status-warning-03nxlw:#fffef0;
  --color-background-table-header-inc72b:#ffffff;
  --color-background-tiles-disabled-ozblza:#ebebf0;
  --color-background-toggle-checked-disabled-wk1i1j:#b8e7ff;
  --color-background-toggle-default-ywflhx:#424650;
  --color-background-avatar-gen-ai-i9hcw6:radial-gradient(circle farthest-corner at top left,rgba(0, 150, 250, 1) -25%,rgba(0, 150, 250, 0) 55%),radial-gradient(circle farthest-corner at top right, rgba(216, 178, 255, 1) -10%, rgba(115, 0, 229, 1) 50%);
  --color-background-avatar-default-rci9zy:#424650;
  --color-text-avatar-jddpvm:#ffffff;
  --color-background-loading-bar-gen-ai-7qfi9s:linear-gradient(90deg, #99f7ff 0%, #0096fa 10%, #bf80ff 24%, #7300e5 50%, #bf80ff 76%, #0096fa 90%, #99f7ff 100%);
  --color-background-chat-bubble-outgoing-pfqan2:transparent;
  --color-background-chat-bubble-incoming-8pi0ow:#f6f6f9;
  --color-text-chat-bubble-outgoing-bvbr0v:#0f141a;
  --color-text-chat-bubble-incoming-x2kig8:#0f141a;
  --color-border-button-normal-active-mn0ayd:#002b66;
  --color-border-button-normal-default-x916ug:#006ce0;
  --color-border-toggle-button-normal-pressed-lylxt2:#006ce0;
  --color-border-button-normal-disabled-caqkhj:#b4b4bb;
  --color-text-button-normal-disabled-0s30to:#8c8c94;
  --color-border-button-normal-hover-6plta7:#002b66;
  --color-border-button-primary-disabled-xtv38q:#ebebf0;
  --color-text-button-primary-disabled-klolb5:#8c8c94;
  --color-border-calendar-grid-u86q1u:transparent;
  --color-border-calendar-grid-selected-focus-ring-w7gc9h:#f9f9fa;
  --color-border-code-editor-ace-active-line-light-theme-3a7k5r:#dedee3;
  --color-border-code-editor-ace-active-line-dark-theme-8s541m:#656871;
  --color-border-code-editor-default-u6q2xy:#dedee3;
  --color-border-code-editor-pane-item-hover-qonm1g:#8c8c94;
  --color-border-container-divider-p2uygo:transparent;
  --color-border-container-top-736buh:transparent;
  --color-border-control-checked-vu1puo:#006ce0;
  --color-border-control-default-8m5t80:#8c8c94;
  --color-border-control-disabled-qc5csz:#dedee3;
  --color-border-divider-active-tn8t2p:#0f141a;
  --color-border-divider-default-cx07f2:#c6c6cd;
  --color-border-divider-panel-bottom-c62zji:#c6c6cd;
  --color-border-divider-panel-side-j1ubnj:#c6c6cd;
  --color-border-divider-secondary-alwp8z:#ebebf0;
  --color-border-dropdown-container-fo7aoz:#b4b4bb;
  --color-border-dropdown-group-2xoluv:#c6c6cd;
  --color-border-dropdown-item-default-xiw1xo:#c6c6cd;
  --color-border-dropdown-item-hover-4p0gsi:#8c8c94;
  --color-border-dropdown-item-dimmed-hover-6qayyi:#8c8c94;
  --color-border-dropdown-item-selected-rtthnt:#006ce0;
  --color-border-dropdown-item-top-ky64jq:transparent;
  --color-border-editable-cell-hover-3ju944:#8c8c94;
  --color-border-input-default-l7v83d:#8c8c94;
  --color-border-input-disabled-lb7zn3:#ebebf0;
  --color-border-input-focused-c6w5i5:#002b66;
  --color-border-item-focused-nv6mhz:#006ce0;
  --color-border-dropdown-item-focused-5afjvu:#424650;
  --color-border-item-placeholder-h6zoax:#006ce0;
  --color-border-item-selected-m769ej:#006ce0;
  --color-border-layout-hrw5xj:#b4b4bb;
  --color-border-notification-stack-bar-hrqqk8:#232b37;
  --color-border-panel-header-nnst86:#c6c6cd;
  --color-border-popover-v2sc7l:#b4b4bb;
  --color-border-segment-active-0hw1oi:#424650;
  --color-border-segment-default-qegins:#424650;
  --color-border-segment-disabled-jfhyf7:#424650;
  --color-border-segment-hover-cs1sac:#424650;
  --color-border-status-error-adxa56:#db0000;
  --color-border-status-info-z6pwqy:#006ce0;
  --color-border-status-success-4x6srk:#00802f;
  --color-border-status-warning-3feumr:#855900;
  --color-border-divider-interactive-default-0c10au:#8c8c94;
  --color-border-tabs-divider-djfdfj:#c6c6cd;
  --color-border-tabs-shadow-enjrr2:rgba(15, 20, 26, 0.12);
  --color-border-tabs-underline-a5fmhd:#006ce0;
  --color-border-tiles-disabled-9w9xuo:#ebebf0;
  --color-border-tutorial-upbl0e:#dedee3;
  --color-foreground-control-default-p84gz5:#ffffff;
  --color-foreground-control-disabled-c64ihu:#ffffff;
  --color-foreground-control-read-only-e43ooq:#656871;
  --color-shadow-default-u7prpu:rgba(15, 20, 26, 0.12);
  --color-stroke-chart-line-i586tw:#8c8c94;
  --color-text-accent-fptgkc:#006ce0;
  --color-text-body-default-7v1jfn:#0f141a;
  --color-text-body-secondary-cwla8d:#424650;
  --color-text-breadcrumb-current-9h12sf:#656871;
  --color-text-breadcrumb-icon-u5luu4:#8c8c94;
  --color-text-button-inline-icon-default-p881yb:#006ce0;
  --color-text-button-inline-icon-disabled-yk9lvs:#b4b4bb;
  --color-text-button-inline-icon-hover-vcdou1:#002b66;
  --color-text-button-normal-active-fdnwsh:#002b66;
  --color-text-toggle-button-normal-pressed-n7ydno:#002b66;
  --color-text-button-normal-default-das2ly:#006ce0;
  --color-text-button-normal-hover-pmztmi:#002b66;
  --color-text-link-button-normal-default-4g1o6u:#006ce0;
  --color-text-link-button-normal-hover-9zux4u:#002b66;
  --color-text-link-button-normal-active-awr7yi:#002b66;
  --color-text-button-primary-active-l1tdpc:#ffffff;
  --color-text-button-primary-default-t0t2sj:#ffffff;
  --color-text-button-primary-hover-5z07s7:#ffffff;
  --color-text-calendar-date-hover-qiity9:#0f141a;
  --color-text-calendar-month-7dq80e:#656871;
  --color-text-code-editor-gutter-active-line-xn0lux:#ffffff;
  --color-text-code-editor-gutter-default-wzd6uj:#0f141a;
  --color-text-code-editor-status-bar-disabled-wlvfa0:#8c8c94;
  --color-text-code-editor-tab-button-error-491d5d:#ffffff;
  --color-text-column-header-18yqaj:#424650;
  --color-text-column-sorting-icon-4y2if8:#424650;
  --color-text-control-disabled-7u2yiq:#b4b4bb;
  --color-text-counter-goqopo:#656871;
  --color-text-disabled-e4vsj4:#b4b4bb;
  --color-text-disabled-inline-edit-rcb2pm:#424650;
  --color-text-dropdown-footer-1jyb4n:#656871;
  --color-text-dropdown-group-label-iy2p4t:#424650;
  --color-text-dropdown-item-default-wpxsrk:#0f141a;
  --color-text-dropdown-item-dimmed-7c5xo0:#b4b4bb;
  --color-text-dropdown-item-disabled-7fg5u8:#b4b4bb;
  --color-text-dropdown-item-filter-match-vwk0li:#006ce0;
  --color-text-dropdown-item-highlighted-rnfxxj:#0f141a;
  --color-text-dropdown-item-secondary-xvh5zo:#656871;
  --color-text-dropdown-item-secondary-hover-4xn5ec:#656871;
  --color-text-empty-o6660v:#656871;
  --color-text-expandable-section-default-7w1jwq:#0f141a;
  --color-text-expandable-section-hover-lomw6v:#006ce0;
  --color-text-expandable-section-navigation-icon-default-g3cuok:#424650;
  --color-text-form-default-nsrnwd:#0f141a;
  --color-text-form-label-5nbxa6:#0f141a;
  --color-text-form-secondary-54emib:#656871;
  --color-text-group-label-8pk3es:#424650;
  --color-text-label-gen-ai-76ots7:#7300e5;
  --color-text-heading-default-6yaix2:#0f141a;
  --color-text-heading-secondary-0myrp3:#424650;
  --color-text-home-header-default-ressfo:#ebebf0;
  --color-text-home-header-secondary-iqyyqx:#c6c6cd;
  --color-text-icon-subtle-pt80lq:#656871;
  --color-text-input-disabled-fc4jfl:#b4b4bb;
  --color-text-input-placeholder-f3x213:#656871;
  --color-text-input-placeholder-disabled-7v2f7f:#b4b4bb;
  --color-text-interactive-active-oauice:#0f141a;
  --color-text-interactive-default-tbacaa:#424650;
  --color-text-interactive-disabled-f5b4z5:#b4b4bb;
  --color-text-interactive-hover-r6bflx:#0f141a;
  --color-text-toggle-button-icon-pressed-d40d50:#0f141a;
  --color-text-interactive-inverted-default-8v8d60:#dedee3;
  --color-text-interactive-inverted-hover-0r4deg:#f9f9fa;
  --color-text-inverted-pvc88w:#ffffff;
  --color-text-label-4yezqy:#0f141a;
  --color-text-layout-toggle-i4unsz:#000716;
  --color-text-layout-toggle-active-as4nbt:#ffffff;
  --color-text-layout-toggle-hover-v3lnw5:#006ce0;
  --color-text-layout-toggle-selected-kb3p9r:#ffffff;
  --color-text-link-default-enwvrt:#006ce0;
  --color-text-link-hover-49uoe9:#002b66;
  --color-text-link-inverted-hover-zsv0ev:#ffffff;
  --color-text-link-button-underline-ik5r9i:transparent;
  --color-text-link-button-underline-hover-gyir88:transparent;
  --color-text-notification-default-0l2rzu:#f9f9fa;
  --color-text-notification-stack-bar-jc9tvx:#ffffff;
  --color-text-notification-yellow-p8f867:#0f141a;
  --color-text-pagination-page-number-active-disabled-a19yjt:#b4b4bb;
  --color-text-pagination-page-number-default-s25kr0:#424650;
  --color-text-segment-active-fc6owc:#ffffff;
  --color-text-segment-default-iib3us:#424650;
  --color-text-segment-hover-0xctet:#002b66;
  --color-text-small-nzfntg:#656871;
  --color-text-status-error-5676bj:#db0000;
  --color-text-status-inactive-5megna:#656871;
  --color-text-status-info-7rlubr:#006ce0;
  --color-text-status-success-gqp5xk:#00802f;
  --color-text-status-warning-csaw41:#855900;
  --color-text-top-navigation-title-v9cb3m:#0f141a;
  --color-board-placeholder-active-93q92g:#ebebf0;
  --color-board-placeholder-hover-yu7ixx:#d1f1ff;
  --color-drag-placeholder-active-llvhbu:#ebebf0;
  --color-drag-placeholder-hover-oikifl:#d1f1ff;
  --color-dropzone-background-default-q8kl4f:#ffffff;
  --color-dropzone-background-hover-wl3yez:#f0fbff;
  --color-dropzone-text-default-owo5k2:#424650;
  --color-dropzone-text-hover-wteupn:#424650;
  --color-dropzone-border-default-phwpnt:#8c8c94;
  --color-dropzone-border-hover-tpluhc:#002b66;
  --font-box-value-large-weight-onoq9k:700;
  --font-button-letter-spacing-65ug0o:0.005em;
  --font-chart-detail-size-1n389u:12px;
  --font-display-label-weight-815ja9:700;
  --font-expandable-heading-size-smdiul:16px;
  --font-family-base-dnvic8:"Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  --font-family-monospace-bftdwf:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  --font-header-h2-description-line-height-rtewek:20px;
  --font-header-h2-description-size-2hj97p:14px;
  --font-link-button-letter-spacing-yi9f0l:0.005em;
  --font-link-button-weight-jephf1:700;
  --font-panel-header-line-height-kxi4u2:22px;
  --font-panel-header-size-edjz3l:18px;
  --font-size-body-m-x4okxb:14px;
  --font-size-body-s-asqx2i:12px;
  --font-size-display-l-htkq3e:42px;
  --font-size-heading-xl-ypc05a:24px;
  --font-size-heading-l-ou0d0l:20px;
  --font-size-heading-m-sjd256:18px;
  --font-size-heading-s-yykzif:16px;
  --font-size-heading-xs-wo4hpf:14px;
  --font-smoothing-moz-osx-foywby:grayscale;
  --font-smoothing-webkit-m8nc84:antialiased;
  --font-tabs-disabled-weight-1xcs5l:700;
  --font-tabs-line-height-dzo7k3:20px;
  --font-tabs-size-8zzqp2:16px;
  --font-wayfinding-link-active-weight-rbmzei:700;
  --font-weight-button-c91p4t:700;
  --font-weight-heading-xl-yvsksd:700;
  --font-weight-heading-l-f8711v:700;
  --font-weight-heading-m-m2ekmb:700;
  --font-weight-heading-s-cwn6wc:700;
  --font-weight-heading-xs-l7rqme:700;
  --font-weight-heavy-cqd9pv:700;
  --letter-spacing-body-s-z9jkwp:0.005em;
  --letter-spacing-display-l-9cigop:-0.03em;
  --letter-spacing-heading-xl-sagq9c:-0.02em;
  --letter-spacing-heading-l-qqjd4y:-0.015em;
  --letter-spacing-heading-m-0w575i:-0.010em;
  --letter-spacing-heading-s-jr3mor:-0.005em;
  --line-height-body-m-30ar75:20px;
  --line-height-body-s-7zv1j5:16px;
  --line-height-display-l-8nioft:48px;
  --line-height-heading-xl-avbttk:30px;
  --line-height-heading-l-mmm3my:24px;
  --line-height-heading-m-50evfk:22px;
  --line-height-heading-s-4i6ewn:20px;
  --line-height-heading-xs-aeleja:18px;
  --border-active-width-9dmqf4:4px;
  --border-code-editor-status-divider-width-tf47q3:1px;
  --border-container-sticky-width-6asz33:0px;
  --border-container-top-width-uugwwl:0px;
  --border-control-focus-ring-shadow-spread-0ctpjf:0px;
  --border-control-invalid-focus-ring-shadow-spread-fhmbjn:2px;
  --border-divider-list-width-27y3k5:1px;
  --border-divider-section-width-1061zr:1px;
  --border-dropdown-virtual-offset-width-kekgc0:2px;
  --border-invalid-width-5ktjp2:8px;
  --border-item-width-yel47s:2px;
  --border-line-chart-dash-array-k49cgz:3 5;
  --border-line-chart-line-join-hdz0jf:round;
  --border-line-chart-width-9mzos2:2px;
  --border-panel-header-width-3w1py7:1px;
  --border-panel-top-width-7uc0w9:1px;
  --border-radius-alert-mdtrse:12px;
  --border-radius-badge-0z09dt:4px;
  --border-radius-button-ypmfry:20px;
  --border-radius-calendar-day-focus-ring-545h0w:3px;
  --border-radius-code-editor-qvw11o:8px;
  --border-radius-container-wqv1zi:16px;
  --border-radius-control-circular-focus-ring-w07mub:4px;
  --border-radius-control-default-focus-ring-u8zbsz:4px;
  --border-radius-dropdown-0dhh79:8px;
  --border-radius-dropzone-4sx861:12px;
  --border-radius-flashbar-hcdl9q:12px;
  --border-radius-item-05df9h:8px;
  --border-radius-input-plgbrq:8px;
  --border-radius-popover-clv1ya:8px;
  --border-radius-tabs-focus-ring-1q0rjo:20px;
  --border-radius-tiles-60y8e6:8px;
  --border-radius-token-923jqq:8px;
  --border-radius-chat-bubble-qvkt36:8px;
  --border-radius-tutorial-panel-item-b44p0a:8px;
  --border-table-sticky-width-87kf12:1px;
  --border-link-focus-ring-outline-kkfop6:0;
  --border-link-focus-ring-shadow-spread-v8hkrl:2px;
  --border-width-alert-t0f3yn:2px;
  --border-width-field-h1g7tw:2px;
  --border-width-popover-h2a419:2px;
  --motion-duration-extra-slow-ngjb9g:270ms;
  --motion-duration-fast-sbm3r7:90ms;
  --motion-duration-moderate-5k1phv:135ms;
  --motion-duration-refresh-only-fast-4jn2d7:115ms;
  --motion-duration-refresh-only-medium-nf6485:165ms;
  --motion-duration-refresh-only-slow-zbvbxt:250ms;
  --motion-duration-avatar-gen-ai-gradient-xetl2o:3600ms;
  --motion-duration-avatar-loading-dots-pe8a3q:1200ms;
  --motion-duration-rotate-180-dpvl4m:135ms;
  --motion-duration-rotate-90-4ry26y:135ms;
  --motion-duration-show-paced-q2lo2h:180ms;
  --motion-duration-show-quick-aq3ump:135ms;
  --motion-duration-slow-z2kaah:180ms;
  --motion-duration-transition-quick-6npj01:90ms;
  --motion-duration-transition-show-paced-9s9l5z:180ms;
  --motion-duration-transition-show-quick-osg026:90ms;
  --motion-easing-ease-out-quart-rrej5m:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-refresh-only-a-8flf9q:cubic-bezier(0, 0, 0, 1);
  --motion-easing-refresh-only-b-ufhmjm:cubic-bezier(1, 0, 0.83, 1);
  --motion-easing-refresh-only-c-vg1m9h:cubic-bezier(0.84, 0, 0.16, 1);
  --motion-easing-avatar-gen-ai-gradient-tjyrpp:cubic-bezier(0.7, 0, 0.3, 1);
  --motion-easing-rotate-180-e270ko:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-rotate-90-sb4dk7:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-show-paced-ne2o0s:ease-out;
  --motion-easing-show-quick-vc5zgd:ease-out;
  --motion-easing-transition-quick-l9jfsx:linear;
  --motion-easing-transition-show-paced-eobwvi:ease-out;
  --motion-easing-responsive-vbn2t8:cubic-bezier(0, 0, 0, 1);
  --motion-easing-sticky-56u8cd:cubic-bezier(1, 0, 0.83, 1);
  --motion-easing-expressive-jz8pes:cubic-bezier(0.84, 0, 0.16, 1);
  --motion-duration-responsive-67fgfp:115ms;
  --motion-duration-expressive-0by09d:165ms;
  --motion-duration-complex-vy4sma:250ms;
  --motion-keyframes-fade-in-gpabjb:awsui-fade-in-35003c;
  --motion-keyframes-fade-out-kva0ei:awsui-fade-out-35003c;
  --motion-keyframes-status-icon-error-ldv006:awsui-status-icon-error-35003c;
  --motion-keyframes-scale-popup-d42fd1:awsui-scale-popup-35003c;
  --size-calendar-grid-width-lrunz7:238px;
  --size-control-28i7hr:16px;
  --size-icon-big-zkdryd:32px;
  --size-icon-large-bivhmh:48px;
  --size-icon-medium-y9xuoq:20px;
  --size-icon-normal-0m1722:16px;
  --size-table-selection-horizontal-oqvl1b:40px;
  --size-vertical-input-v5iwwf:32px;
  --size-vertical-panel-icon-offset-wy8j08:15px;
  --space-alert-action-left-ea9czk:12px;
  --space-alert-horizontal-eyt1xx:16px;
  --space-alert-message-right-knlb6u:4px;
  --space-alert-vertical-l6cc71:8px;
  --space-button-focus-outline-gutter-cqfd0c:4px;
  --space-button-horizontal-8jxzea:20px;
  --space-button-icon-focus-outline-gutter-vertical-zgqy8m:0px;
  --space-button-icon-only-horizontal-zsf2nw:6px;
  --space-button-inline-icon-focus-outline-gutter-vk4r2v:0px;
  --space-button-modal-dismiss-vertical-0n4y5m:2px;
  --space-calendar-grid-focus-outline-gutter-g00bgj:-5px;
  --space-calendar-grid-selected-focus-outline-gutter-hxbct6:-5px;
  --space-calendar-grid-gutter-2jsrps:6px;
  --space-card-horizontal-tuwbbd:20px;
  --space-card-vertical-m591he:16px;
  --space-code-editor-status-focus-outline-gutter-dqxswe:-7px;
  --space-container-content-top-1ae0r3:4px;
  --space-container-header-top-5qav00:12px;
  --space-container-header-bottom-vj01hn:8px;
  --space-container-horizontal-wfukh3:20px;
  --space-content-header-padding-bottom-y2enua:16px;
  --space-dark-header-overlap-distance-lxpp0s:36px;
  --space-expandable-section-icon-offset-top-ekk5vc:4px;
  --space-field-horizontal-gg19kw:12px;
  --space-field-icon-offset-csk9vw:36px;
  --space-filtering-token-dismiss-button-focus-outline-gutter-76gsim:-5px;
  --space-filtering-token-operation-select-focus-outline-gutter-0ailok:-5px;
  --space-flashbar-action-left-qkhz5m:12px;
  --space-flashbar-dismiss-right-tcpfax:0px;
  --space-flashbar-horizontal-xmjla3:16px;
  --space-grid-gutter-ieqn7e:20px;
  --space-key-value-gap-0517k5:0px;
  --space-layout-content-bottom-noqzmw:40px;
  --space-layout-content-horizontal-7l52k3:24px;
  --space-layout-toggle-diameter-hb85rk:36px;
  --space-layout-toggle-padding-lcu6qi:12px;
  --space-modal-content-bottom-hqahub:16px;
  --space-modal-horizontal-f89syl:20px;
  --space-option-icon-big-top-hybljv:0px;
  --space-panel-content-bottom-7k6er3:40px;
  --space-panel-content-top-wac3yp:20px;
  --space-panel-divider-margin-horizontal-sbik1z:8px;
  --space-panel-header-vertical-gv9pti:20px;
  --space-panel-nav-left-b1g0mt:28px;
  --space-panel-side-left-5t0who:28px;
  --space-panel-side-right-xofg51:24px;
  --space-panel-split-top-q1pux4:20px;
  --space-panel-split-bottom-qwdduf:20px;
  --space-segmented-control-focus-outline-gutter-ublqj7:6px;
  --space-tabs-content-top-dedu0k:12px;
  --space-tabs-focus-outline-gutter-xm37ly:-8px;
  --space-table-content-bottom-9yr834:4px;
  --space-table-embedded-header-top-bfjeam:0px;
  --space-table-footer-horizontal-ptapb9:0px;
  --space-table-header-focus-outline-gutter-7js4en:0px;
  --space-table-header-horizontal-wl3ykr:0px;
  --space-table-header-tools-bottom-25ubsj:0px;
  --space-table-header-tools-full-page-bottom-xulfq9:4px;
  --space-table-horizontal-8davlm:20px;
  --space-tile-gutter-fuuodu:24px;
  --space-scaled-2x-xxs-7v8ivz:4px;
  --space-scaled-2x-m-owtj1o:16px;
  --space-scaled-2x-l-ujnz41:20px;
  --space-scaled-2x-xl-udij7m:24px;
  --space-scaled-none-vhzhxe:0px;
  --space-scaled-xxxs-27y4hv:2px;
  --space-scaled-xxs-7597g1:4px;
  --space-scaled-xs-26e2du:8px;
  --space-scaled-s-aqzyko:12px;
  --space-scaled-m-mo5yse:16px;
  --space-scaled-l-0hpmd7:20px;
  --space-scaled-xl-kswcw7:24px;
  --space-scaled-xxl-wbot5q:32px;
  --space-scaled-xxxl-fdg8ai:40px;
  --space-static-xxxs-3gu9os:2px;
  --space-static-xxs-82cdfi:4px;
  --space-static-xs-7sfb63:8px;
  --space-static-s-n2eb28:12px;
  --space-static-m-lrhgl2:16px;
  --space-static-l-0xsx8r:20px;
  --space-static-xl-qalw6z:24px;
  --space-static-xxl-02zgl2:32px;
  --space-static-xxxl-qj5g91:40px;
  --space-none-zrljql:0px;
  --space-xxxs-zbmxqb:2px;
  --space-xxs-p8yyaw:4px;
  --space-xs-zb16t3:8px;
  --space-s-34lx8l:12px;
  --space-m-udix3p:16px;
  --space-l-t419sm:20px;
  --space-xl-lmui9r:24px;
  --space-xxl-cu2m1r:32px;
  --space-xxxl-4x2gki:40px;
  --shadow-container-fxmdn3:0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12);
  --shadow-container-active-kl29x9:0px 1px 1px 1px #e9ebed, 0px 6px 36px #0007161a;
  --shadow-dropdown-e5guvm:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-dropup-xqkiza:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-flash-collapsed-s7gipk:0px 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-flash-sticky-pq8cuq:0px 4px 8px rgba(0, 7, 22, 0.10);
  --shadow-modal-2ennni:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-panel-g6fkp1:0px 0px 0px 1px #b6bec9;
  --shadow-panel-toggle-0l64f2:0px 6px 12px 1px rgba(0, 7, 22, 0.12);
  --shadow-popover-8zls8c:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-split-bottom-5e389u:0px -36px 36px -36px rgba(0, 7, 22, 0.10);
  --shadow-split-side-ggdtnk:-1px 0px 1px 0px #e9ebed, -36px 6px 36px -36px rgba(0, 7, 22, 0.10);
  --shadow-sticky-blixp9:0px 4px 8px 1px rgba(0, 7, 22, 0.10);
  --shadow-sticky-embedded-uvh5ry:0px 2px 0px 0px #e9ebed, 0px 16px 16px -12px rgba(0, 7, 22, 0.10);
  --shadow-sticky-column-first-039dqs:4px 0px 8px 1px rgba(0, 7, 22, 0.1);
  --shadow-sticky-column-last-n170vw:-4px 0 8px 1px rgba(0, 28, 36, 0.1);
}

@media not print {
  .awsui-dark-mode:not(#\9) {
    --color-charts-red-300-l64jkq:#d63f38;
    --color-charts-red-400-wa91b2:#ed5958;
    --color-charts-red-500-clvvu0:#fe6e73;
    --color-charts-red-600-n5f5z7:#ff8a8a;
    --color-charts-red-700-9qnabz:#ffa09e;
    --color-charts-red-800-03myov:#ffb3b0;
    --color-charts-red-900-t7y4st:#ffc4c0;
    --color-charts-red-1000-2569eu:#ffd2cf;
    --color-charts-red-1100-utabtk:#ffe0dd;
    --color-charts-red-1200-p41vo4:#ffecea;
    --color-charts-orange-300-8xdemd:#c55305;
    --color-charts-orange-400-98kh01:#de6923;
    --color-charts-orange-500-3exfo1:#f27c36;
    --color-charts-orange-600-tyzxch:#f89256;
    --color-charts-orange-700-9es7jg:#fca572;
    --color-charts-orange-800-zzyr3p:#ffb68b;
    --color-charts-orange-900-ozn3mk:#ffc6a4;
    --color-charts-orange-1000-2j9x6g:#ffd4bb;
    --color-charts-orange-1100-gbb7zd:#ffe1cf;
    --color-charts-orange-1200-k1a2bc:#ffede2;
    --color-charts-yellow-300-ud5z8m:#977001;
    --color-charts-yellow-400-er893i:#b08400;
    --color-charts-yellow-500-zf6yw3:#c59600;
    --color-charts-yellow-600-e1b0zr:#d3a61c;
    --color-charts-yellow-700-3ihyrv:#dfb52c;
    --color-charts-yellow-800-2luor7:#eac33a;
    --color-charts-yellow-900-k8cds4:#f1cf65;
    --color-charts-yellow-1000-9ibbxn:#f7db8a;
    --color-charts-yellow-1100-igbqae:#fce5a8;
    --color-charts-yellow-1200-qg1amh:#ffefc9;
    --color-charts-green-300-gduyir:#48851a;
    --color-charts-green-400-jvx3c1:#5a9b29;
    --color-charts-green-500-uj364g:#69ae34;
    --color-charts-green-600-mbov5u:#7dbd4c;
    --color-charts-green-700-m7xx1p:#8fca61;
    --color-charts-green-800-97d577:#9fd673;
    --color-charts-green-900-79vtkm:#b2df8d;
    --color-charts-green-1000-dw0ace:#c5e7a8;
    --color-charts-green-1100-fdxgm3:#d5efbe;
    --color-charts-green-1200-0w78ld:#e4f7d5;
    --color-charts-teal-300-b1amlp:#018977;
    --color-charts-teal-400-75fmn4:#009d89;
    --color-charts-teal-500-5p6o7k:#00b09b;
    --color-charts-teal-600-1ar5in:#40bfa9;
    --color-charts-teal-700-3m4vci:#5fccb7;
    --color-charts-teal-800-0fx1b9:#77d7c3;
    --color-charts-teal-900-a0x7u4:#94e0d0;
    --color-charts-teal-1000-n2wukj:#ace9db;
    --color-charts-teal-1100-3rvzfn:#c2f0e6;
    --color-charts-teal-1200-hfik8s:#d7f7f0;
    --color-charts-blue-1-300-uwr1wq:#00819c;
    --color-charts-blue-1-400-y4dmzv:#0497ba;
    --color-charts-blue-1-500-jj2qqx:#08aad2;
    --color-charts-blue-1-600-ntcnzw:#44b9dd;
    --color-charts-blue-1-700-vhr7bu:#63c6e7;
    --color-charts-blue-1-800-zq4na2:#79d2f0;
    --color-charts-blue-1-900-pu28p4:#98dcf5;
    --color-charts-blue-1-1000-a1wpur:#b3e4f8;
    --color-charts-blue-1-1100-fyl0u6:#caedfc;
    --color-charts-blue-1-1200-y913fp:#ddf4ff;
    --color-charts-blue-2-300-sh1kxw:#486de8;
    --color-charts-blue-2-400-s269w5:#6384f5;
    --color-charts-blue-2-500-y9grom:#7698fe;
    --color-charts-blue-2-600-ljsd9s:#8ea9ff;
    --color-charts-blue-2-700-8pne2y:#a2b8ff;
    --color-charts-blue-2-800-v3m6dn:#b1c5ff;
    --color-charts-blue-2-900-2rg9qw:#c3d1ff;
    --color-charts-blue-2-1000-ore46l:#d2dcff;
    --color-charts-blue-2-1100-rj1e0b:#dfe6ff;
    --color-charts-blue-2-1200-i1tjtx:#ecf0ff;
    --color-charts-purple-300-egsaob:#8d59de;
    --color-charts-purple-400-y2qlpj:#a173ea;
    --color-charts-purple-500-akrt38:#b088f5;
    --color-charts-purple-600-dnhyx7:#bf9bf9;
    --color-charts-purple-700-sk05tr:#cbabfc;
    --color-charts-purple-800-g5mys1:#d6baff;
    --color-charts-purple-900-ap3bee:#dfc8ff;
    --color-charts-purple-1000-pymhe3:#e8d5ff;
    --color-charts-purple-1100-f2iopl:#efe2ff;
    --color-charts-purple-1200-aic8dr:#f5edff;
    --color-charts-pink-300-cctxfi:#c64a70;
    --color-charts-pink-400-kpryka:#d56889;
    --color-charts-pink-500-tccj7z:#e07f9d;
    --color-charts-pink-600-4bki3e:#eb92ad;
    --color-charts-pink-700-gexeia:#f5a2bb;
    --color-charts-pink-800-9fchm4:#ffb0c8;
    --color-charts-pink-900-z4eeu8:#ffc1d4;
    --color-charts-pink-1000-7uou4m:#ffd1de;
    --color-charts-pink-1100-j0uevl:#ffdfe8;
    --color-charts-pink-1200-jpnl1t:#ffecf1;
    --color-charts-status-critical-vygogl:#d63f38;
    --color-charts-status-high-xmh5vl:#fe6e73;
    --color-charts-status-medium-le4kdn:#f89256;
    --color-charts-status-low-seyj13:#dfb52c;
    --color-charts-status-positive-g1vr65:#69ae34;
    --color-charts-status-info-10v3c7:#08aad2;
    --color-charts-threshold-negative-dj5crf:#ff7a7a;
    --color-charts-threshold-positive-zo9s0l:#2bb534;
    --color-charts-threshold-info-rgb5hd:#75cfff;
    --color-charts-threshold-neutral-kq30cz:#a4a4ad;
    --color-charts-line-grid-vaxun1:#424650;
    --color-charts-line-tick-6i81s1:#424650;
    --color-charts-line-axis-d4bcyf:#424650;
    --color-charts-palette-categorical-1-ld9m1p:#486de8;
    --color-charts-palette-categorical-2-jwteam:#e07f9d;
    --color-charts-palette-categorical-3-tlwr5r:#018977;
    --color-charts-palette-categorical-4-prlvih:#b088f5;
    --color-charts-palette-categorical-5-04vsso:#c55305;
    --color-charts-palette-categorical-6-2wgmsi:#8ea9ff;
    --color-charts-palette-categorical-7-w06irv:#ffb0c8;
    --color-charts-palette-categorical-8-1qnvcp:#40bfa9;
    --color-charts-palette-categorical-9-0342zm:#d6baff;
    --color-charts-palette-categorical-10-u73kns:#f89256;
    --color-charts-palette-categorical-11-tloc2a:#c3d1ff;
    --color-charts-palette-categorical-12-23xv0d:#ffdfe8;
    --color-charts-palette-categorical-13-tuq7ce:#94e0d0;
    --color-charts-palette-categorical-14-bom4ss:#efe2ff;
    --color-charts-palette-categorical-15-kpqcpe:#ffc6a4;
    --color-charts-palette-categorical-16-pzlo2l:#ecf0ff;
    --color-charts-palette-categorical-17-spn4mx:#d56889;
    --color-charts-palette-categorical-18-kxbv8i:#d7f7f0;
    --color-charts-palette-categorical-19-cl97gf:#a173ea;
    --color-charts-palette-categorical-20-gzcnb4:#ffede2;
    --color-charts-palette-categorical-21-vyqcuq:#7698fe;
    --color-charts-palette-categorical-22-6r7gq7:#f5a2bb;
    --color-charts-palette-categorical-23-7c98ot:#00b09b;
    --color-charts-palette-categorical-24-0i0wmq:#cbabfc;
    --color-charts-palette-categorical-25-7j0tso:#f27c36;
    --color-charts-palette-categorical-26-eql7mt:#b1c5ff;
    --color-charts-palette-categorical-27-8doiqc:#ffd1de;
    --color-charts-palette-categorical-28-tlstes:#77d7c3;
    --color-charts-palette-categorical-29-noa7ft:#e8d5ff;
    --color-charts-palette-categorical-30-ttbs6s:#ffb68b;
    --color-charts-palette-categorical-31-lz0ukh:#dfe6ff;
    --color-charts-palette-categorical-32-ncpn1t:#c64a70;
    --color-charts-palette-categorical-33-fpbcye:#c2f0e6;
    --color-charts-palette-categorical-34-sk46nw:#8d59de;
    --color-charts-palette-categorical-35-wwtyjx:#ffe1cf;
    --color-charts-palette-categorical-36-7v0fgy:#6384f5;
    --color-charts-palette-categorical-37-b2rc0w:#eb92ad;
    --color-charts-palette-categorical-38-nlzlk1:#009d89;
    --color-charts-palette-categorical-39-esgczm:#bf9bf9;
    --color-charts-palette-categorical-40-0hdln6:#de6923;
    --color-charts-palette-categorical-41-bsk8cf:#a2b8ff;
    --color-charts-palette-categorical-42-97om84:#ffc1d4;
    --color-charts-palette-categorical-43-euzcqg:#5fccb7;
    --color-charts-palette-categorical-44-a7wos6:#dfc8ff;
    --color-charts-palette-categorical-45-iz0wz2:#fca572;
    --color-charts-palette-categorical-46-nzigym:#d2dcff;
    --color-charts-palette-categorical-47-croxgd:#ffecf1;
    --color-charts-palette-categorical-48-tptw1p:#ace9db;
    --color-charts-palette-categorical-49-6lrkes:#f5edff;
    --color-charts-palette-categorical-50-mqbaxr:#ffd4bb;
    --color-background-notification-severity-critical-m6z340:#d63f38;
    --color-background-notification-severity-high-zn7yde:#fe6e73;
    --color-text-notification-severity-critical-cmj0dk:#000000;
    --color-text-notification-severity-high-rgh0m2:#0f141a;
    --color-background-badge-icon-n0lc3o:#ff7a7a;
    --color-background-button-link-active-zsvqyt:#333843;
    --color-background-button-link-hover-7joibg:#1b232d;
    --color-background-button-normal-active-udb472:#333843;
    --color-background-button-normal-default-fauhr6:#161d26;
    --color-background-button-normal-disabled-h6arvb:#161d26;
    --color-background-button-normal-hover-rw8abf:#1b232d;
    --color-background-toggle-button-normal-pressed-cpfehj:#333843;
    --color-background-button-primary-active-qz8503:#42b4ff;
    --color-background-button-primary-default-j68ske:#42b4ff;
    --color-background-button-primary-disabled-l4i17m:#232b37;
    --color-background-button-primary-hover-wgg8kk:#75cfff;
    --color-background-direction-button-disabled-54epp1:#232b37;
    --color-text-direction-button-disabled-ok08o0:#656871;
    --color-background-calendar-current-date-a6ucrp:#333843;
    --color-background-cell-shaded-sc089k:#1b232d;
    --color-background-code-editor-gutter-active-line-default-v8is2q:#8c8c94;
    --color-background-code-editor-gutter-active-line-error-bd4ln4:#ff7a7a;
    --color-background-code-editor-gutter-default-v5e78w:#1b232d;
    --color-background-code-editor-loading-2zupgi:#1b232d;
    --color-background-code-editor-pane-item-hover-h04m4p:#333843;
    --color-background-code-editor-status-bar-ybpgwu:#1b232d;
    --color-background-container-content-epgqkc:#161d26;
    --color-background-container-header-clzg6q:#161d26;
    --color-background-control-checked-hz4k40:#42b4ff;
    --color-background-control-default-5ol8rv:#161d26;
    --color-background-control-disabled-5cbvij:#333843;
    --color-background-dropdown-item-default-u8emhk:#1b232d;
    --color-background-dropdown-item-filter-match-5pchhq:#333843;
    --color-background-dropdown-item-hover-zhzc84:#131920;
    --color-background-dropdown-item-selected-idfej7:#001129;
    --color-background-input-default-k0a6tw:#161d26;
    --color-background-input-disabled-gvxsk4:#1b232d;
    --color-background-item-selected-njak80:#001129;
    --color-background-layout-main-cp7lkl:#161d26;
    --color-background-layout-panel-content-b4s8gn:#161d26;
    --color-background-layout-panel-hover-1ftnua:#333843;
    --color-background-layout-toggle-selected-active-tv8i8b:#42b4ff;
    --color-background-layout-toggle-selected-default-27rnk6:#42b4ff;
    --color-background-layout-toggle-selected-hover-eh8bga:#75cfff;
    --color-background-modal-overlay-lal4at:rgba(15, 20, 26, 0.7);
    --color-background-notification-grey-jyn3j2:#656871;
    --color-background-popover-eqi5zi:#1b232d;
    --color-background-progress-bar-content-default-sn7ya6:#42b4ff;
    --color-background-progress-bar-layout-default-urc8t0:#333843;
    --color-background-segment-active-bkvyiy:#42b4ff;
    --color-background-segment-default-voaiuw:#161d26;
    --color-background-segment-disabled-9tsgp1:#161d26;
    --color-background-segment-hover-detiep:#1b232d;
    --color-background-slider-handle-default-94lnn1:#42b4ff;
    --color-background-slider-handle-active-7w7xg0:#75cfff;
    --color-background-slider-track-uhckut:#656871;
    --color-background-slider-handle-ring-nibud6:#161d26;
    --color-background-slider-error-pressed-j572od:#ff7a7a;
    --color-background-slider-warning-pressed-pg5aih:#fbd332;
    --color-background-status-error-5fz45j:#1f0000;
    --color-background-status-info-gflgo4:#001129;
    --color-background-status-success-ua29ej:#001401;
    --color-background-status-warning-03nxlw:#191100;
    --color-background-table-header-inc72b:#161d26;
    --color-background-tiles-disabled-ozblza:#1b232d;
    --color-background-toggle-checked-disabled-wk1i1j:#002b66;
    --color-background-toggle-default-ywflhx:#8c8c94;
    --color-background-chat-bubble-incoming-8pi0ow:#0f141a;
    --color-text-chat-bubble-outgoing-bvbr0v:#c6c6cd;
    --color-text-chat-bubble-incoming-x2kig8:#c6c6cd;
    --color-border-button-normal-active-mn0ayd:#75cfff;
    --color-border-button-normal-default-x916ug:#42b4ff;
    --color-border-toggle-button-normal-pressed-lylxt2:#42b4ff;
    --color-border-button-normal-disabled-caqkhj:#656871;
    --color-border-button-normal-hover-6plta7:#75cfff;
    --color-border-button-primary-disabled-xtv38q:#232b37;
    --color-border-calendar-grid-selected-focus-ring-w7gc9h:#161d26;
    --color-border-code-editor-default-u6q2xy:#656871;
    --color-border-code-editor-pane-item-hover-qonm1g:#656871;
    --color-border-control-checked-vu1puo:#42b4ff;
    --color-border-control-disabled-qc5csz:#333843;
    --color-border-divider-active-tn8t2p:#f9f9fa;
    --color-border-divider-default-cx07f2:#424650;
    --color-border-divider-panel-bottom-c62zji:#424650;
    --color-border-divider-panel-side-j1ubnj:#424650;
    --color-border-divider-secondary-alwp8z:#232b37;
    --color-border-dropdown-container-fo7aoz:#656871;
    --color-border-dropdown-group-2xoluv:#424650;
    --color-border-dropdown-item-default-xiw1xo:#424650;
    --color-border-dropdown-item-hover-4p0gsi:#656871;
    --color-border-dropdown-item-selected-rtthnt:#42b4ff;
    --color-border-editable-cell-hover-3ju944:#656871;
    --color-border-input-default-l7v83d:#656871;
    --color-border-input-disabled-lb7zn3:#1b232d;
    --color-border-input-focused-c6w5i5:#b8e7ff;
    --color-border-item-focused-nv6mhz:#42b4ff;
    --color-border-dropdown-item-focused-5afjvu:#dedee3;
    --color-border-item-placeholder-h6zoax:#42b4ff;
    --color-border-item-selected-m769ej:#42b4ff;
    --color-border-layout-hrw5xj:#424650;
    --color-border-panel-header-nnst86:#424650;
    --color-border-popover-v2sc7l:#656871;
    --color-border-segment-active-0hw1oi:#dedee3;
    --color-border-segment-default-qegins:#dedee3;
    --color-border-segment-disabled-jfhyf7:#dedee3;
    --color-border-segment-hover-cs1sac:#dedee3;
    --color-border-status-error-adxa56:#ff7a7a;
    --color-border-status-info-z6pwqy:#42b4ff;
    --color-border-status-success-4x6srk:#2bb534;
    --color-border-status-warning-3feumr:#fbd332;
    --color-border-divider-interactive-default-0c10au:#dedee3;
    --color-border-tabs-divider-djfdfj:#424650;
    --color-border-tabs-shadow-enjrr2:rgba(15, 20, 26, 1);
    --color-border-tabs-underline-a5fmhd:#42b4ff;
    --color-border-tiles-disabled-9w9xuo:#1b232d;
    --color-border-tutorial-upbl0e:#424650;
    --color-foreground-control-default-p84gz5:#0f141a;
    --color-foreground-control-disabled-c64ihu:#161d26;
    --color-foreground-control-read-only-e43ooq:#a4a4ad;
    --color-shadow-default-u7prpu:rgba(15, 20, 26, 1);
    --color-text-accent-fptgkc:#42b4ff;
    --color-text-body-default-7v1jfn:#c6c6cd;
    --color-text-body-secondary-cwla8d:#c6c6cd;
    --color-text-breadcrumb-current-9h12sf:#8c8c94;
    --color-text-breadcrumb-icon-u5luu4:#656871;
    --color-text-button-inline-icon-default-p881yb:#42b4ff;
    --color-text-button-inline-icon-disabled-yk9lvs:#656871;
    --color-text-button-inline-icon-hover-vcdou1:#75cfff;
    --color-text-button-normal-active-fdnwsh:#75cfff;
    --color-text-toggle-button-normal-pressed-n7ydno:#75cfff;
    --color-text-button-normal-default-das2ly:#42b4ff;
    --color-text-button-normal-hover-pmztmi:#75cfff;
    --color-text-link-button-normal-default-4g1o6u:#42b4ff;
    --color-text-link-button-normal-hover-9zux4u:#75cfff;
    --color-text-link-button-normal-active-awr7yi:#75cfff;
    --color-text-button-primary-active-l1tdpc:#0f141a;
    --color-text-button-primary-default-t0t2sj:#0f141a;
    --color-text-button-primary-hover-5z07s7:#0f141a;
    --color-text-calendar-date-hover-qiity9:#dedee3;
    --color-text-calendar-month-7dq80e:#a4a4ad;
    --color-text-code-editor-gutter-active-line-xn0lux:#0f141a;
    --color-text-code-editor-gutter-default-wzd6uj:#dedee3;
    --color-text-code-editor-status-bar-disabled-wlvfa0:#656871;
    --color-text-code-editor-tab-button-error-491d5d:#0f141a;
    --color-text-column-header-18yqaj:#b4b4bb;
    --color-text-column-sorting-icon-4y2if8:#b4b4bb;
    --color-text-control-disabled-7u2yiq:#656871;
    --color-text-counter-goqopo:#a4a4ad;
    --color-text-disabled-e4vsj4:#656871;
    --color-text-disabled-inline-edit-rcb2pm:#b4b4bb;
    --color-text-dropdown-footer-1jyb4n:#a4a4ad;
    --color-text-dropdown-group-label-iy2p4t:#c6c6cd;
    --color-text-dropdown-item-default-wpxsrk:#dedee3;
    --color-text-dropdown-item-dimmed-7c5xo0:#656871;
    --color-text-dropdown-item-disabled-7fg5u8:#656871;
    --color-text-dropdown-item-filter-match-vwk0li:#75cfff;
    --color-text-dropdown-item-highlighted-rnfxxj:#ebebf0;
    --color-text-dropdown-item-secondary-xvh5zo:#a4a4ad;
    --color-text-dropdown-item-secondary-hover-4xn5ec:#dedee3;
    --color-text-empty-o6660v:#dedee3;
    --color-text-expandable-section-default-7w1jwq:#dedee3;
    --color-text-expandable-section-hover-lomw6v:#42b4ff;
    --color-text-expandable-section-navigation-icon-default-g3cuok:#dedee3;
    --color-text-form-default-nsrnwd:#dedee3;
    --color-text-form-label-5nbxa6:#dedee3;
    --color-text-form-secondary-54emib:#a4a4ad;
    --color-text-group-label-8pk3es:#c6c6cd;
    --color-text-label-gen-ai-76ots7:#bf80ff;
    --color-text-heading-default-6yaix2:#ebebf0;
    --color-text-heading-secondary-0myrp3:#a4a4ad;
    --color-text-icon-subtle-pt80lq:#b4b4bb;
    --color-text-input-disabled-fc4jfl:#656871;
    --color-text-input-placeholder-f3x213:#a4a4ad;
    --color-text-input-placeholder-disabled-7v2f7f:#656871;
    --color-text-interactive-active-oauice:#f9f9fa;
    --color-text-interactive-default-tbacaa:#dedee3;
    --color-text-interactive-disabled-f5b4z5:#656871;
    --color-text-interactive-hover-r6bflx:#f9f9fa;
    --color-text-toggle-button-icon-pressed-d40d50:#f9f9fa;
    --color-text-inverted-pvc88w:#0f141a;
    --color-text-label-4yezqy:#dedee3;
    --color-text-layout-toggle-active-as4nbt:#161d26;
    --color-text-layout-toggle-hover-v3lnw5:#42b4ff;
    --color-text-layout-toggle-selected-kb3p9r:#0f141a;
    --color-text-link-default-enwvrt:#42b4ff;
    --color-text-link-hover-49uoe9:#75cfff;
    --color-text-pagination-page-number-active-disabled-a19yjt:#656871;
    --color-text-pagination-page-number-default-s25kr0:#b4b4bb;
    --color-text-segment-active-fc6owc:#0f141a;
    --color-text-segment-default-iib3us:#dedee3;
    --color-text-segment-hover-0xctet:#75cfff;
    --color-text-small-nzfntg:#a4a4ad;
    --color-text-status-error-5676bj:#ff7a7a;
    --color-text-status-inactive-5megna:#a4a4ad;
    --color-text-status-info-7rlubr:#42b4ff;
    --color-text-status-success-gqp5xk:#2bb534;
    --color-text-status-warning-csaw41:#fbd332;
    --color-text-top-navigation-title-v9cb3m:#f9f9fa;
    --color-board-placeholder-active-93q92g:#656871;
    --color-board-placeholder-hover-yu7ixx:#006ce0;
    --color-drag-placeholder-active-llvhbu:#656871;
    --color-drag-placeholder-hover-oikifl:#006ce0;
    --color-dropzone-background-default-q8kl4f:#161d26;
    --color-dropzone-background-hover-wl3yez:#001129;
    --color-dropzone-text-default-owo5k2:#c6c6cd;
    --color-dropzone-text-hover-wteupn:#c6c6cd;
    --color-dropzone-border-default-phwpnt:#656871;
    --color-dropzone-border-hover-tpluhc:#75cfff;
    --shadow-container-fxmdn3:0px 1px 8px 2px rgba(0, 7, 22, 0.6);
    --shadow-container-active-kl29x9:0px 1px 1px 1px #192534, 0px 6px 36px #00040c;
    --shadow-dropdown-e5guvm:0px 4px 20px 1px rgba(0, 4, 12, 1);
    --shadow-dropup-xqkiza:0px 4px 20px 1px rgba(0, 4, 12, 1);
    --shadow-flash-sticky-pq8cuq:0px 4px 8px rgba(0, 7, 22, 0.5);
    --shadow-modal-2ennni:0px 4px 20px 1px rgba(0, 4, 12, 1);
    --shadow-panel-g6fkp1:0px 0px 0px 1px #414d5c;
    --shadow-panel-toggle-0l64f2:0px 6px 12px 1px rgba(0, 7, 22, 1);
    --shadow-popover-8zls8c:0px 4px 20px 1px rgba(0, 4, 12, 1);
    --shadow-split-bottom-5e389u:0px -36px 36px -36px rgba(0, 7, 22, 1);
    --shadow-split-side-ggdtnk:-1px 0px 1px 0px #192534, -36px 6px 36px -36px rgba(0, 7, 22, 1);
    --shadow-sticky-blixp9:0px 4px 8px 1px rgba(0, 7, 22, 0.5);
    --shadow-sticky-embedded-uvh5ry:0px 2px 0px 0px #414d5c, 0px 16px 16px -12px rgba(0, 7, 22, 1);
    --shadow-sticky-column-first-039dqs:0px 4px 8px 1px rgba(0, 7, 22, 0.5);
    --shadow-sticky-column-last-n170vw:0px 4px 8px 1px rgba(0, 7, 22, 0.5);
  }
}
.awsui-compact-mode:not(#\9) {
  --size-vertical-input-v5iwwf:28px;
  --size-vertical-panel-icon-offset-wy8j08:13px;
  --space-alert-vertical-l6cc71:4px;
  --space-button-horizontal-8jxzea:16px;
  --space-button-icon-only-horizontal-zsf2nw:4px;
  --space-button-modal-dismiss-vertical-0n4y5m:0px;
  --space-card-vertical-m591he:12px;
  --space-container-header-bottom-vj01hn:4px;
  --space-content-header-padding-bottom-y2enua:12px;
  --space-dark-header-overlap-distance-lxpp0s:32px;
  --space-expandable-section-icon-offset-top-ekk5vc:0px;
  --space-grid-gutter-ieqn7e:16px;
  --space-layout-content-bottom-noqzmw:24px;
  --space-layout-content-horizontal-7l52k3:16px;
  --space-modal-content-bottom-hqahub:8px;
  --space-panel-content-bottom-7k6er3:32px;
  --space-panel-content-top-wac3yp:16px;
  --space-panel-header-vertical-gv9pti:16px;
  --space-panel-side-right-xofg51:20px;
  --space-panel-split-top-q1pux4:16px;
  --space-panel-split-bottom-qwdduf:16px;
  --space-tabs-content-top-dedu0k:8px;
  --space-table-header-focus-outline-gutter-7js4en:-1px;
  --space-tile-gutter-fuuodu:16px;
  --space-scaled-2x-xxs-7v8ivz:0px;
  --space-scaled-2x-m-owtj1o:8px;
  --space-scaled-2x-l-ujnz41:12px;
  --space-scaled-2x-xl-udij7m:16px;
  --space-scaled-xxxs-27y4hv:0px;
  --space-scaled-xxs-7597g1:2px;
  --space-scaled-xs-26e2du:4px;
  --space-scaled-s-aqzyko:8px;
  --space-scaled-m-mo5yse:12px;
  --space-scaled-l-0hpmd7:16px;
  --space-scaled-xl-kswcw7:20px;
  --space-scaled-xxl-wbot5q:24px;
  --space-scaled-xxxl-fdg8ai:32px;
}

.awsui-motion-disabled:not(#\9) {
  --motion-duration-extra-slow-ngjb9g:0ms;
  --motion-duration-fast-sbm3r7:0ms;
  --motion-duration-moderate-5k1phv:0ms;
  --motion-duration-refresh-only-fast-4jn2d7:0ms;
  --motion-duration-refresh-only-medium-nf6485:0ms;
  --motion-duration-refresh-only-slow-zbvbxt:0ms;
  --motion-duration-avatar-gen-ai-gradient-xetl2o:0ms;
  --motion-duration-avatar-loading-dots-pe8a3q:0ms;
  --motion-duration-rotate-180-dpvl4m:0ms;
  --motion-duration-rotate-90-4ry26y:0ms;
  --motion-duration-show-paced-q2lo2h:0ms;
  --motion-duration-show-quick-aq3ump:0ms;
  --motion-duration-slow-z2kaah:0ms;
  --motion-duration-transition-quick-6npj01:0ms;
  --motion-duration-transition-show-paced-9s9l5z:0ms;
  --motion-duration-transition-show-quick-osg026:0ms;
  --motion-duration-responsive-67fgfp:0ms;
  --motion-duration-expressive-0by09d:0ms;
  --motion-duration-complex-vy4sma:0ms;
}

.awsui-context-compact-table:not(#\9) {
  --size-vertical-input-v5iwwf:28px;
  --space-alert-vertical-l6cc71:4px;
  --space-button-horizontal-8jxzea:16px;
  --space-button-modal-dismiss-vertical-0n4y5m:0px;
  --space-card-vertical-m591he:12px;
  --space-container-header-bottom-vj01hn:4px;
  --space-content-header-padding-bottom-y2enua:12px;
  --space-panel-content-bottom-7k6er3:32px;
  --space-panel-content-top-wac3yp:16px;
  --space-panel-header-vertical-gv9pti:16px;
  --space-panel-side-right-xofg51:20px;
  --space-panel-split-top-q1pux4:16px;
  --space-panel-split-bottom-qwdduf:16px;
  --space-tabs-content-top-dedu0k:8px;
  --space-scaled-xxxs-27y4hv:0px;
  --space-scaled-xxs-7597g1:2px;
  --space-scaled-xs-26e2du:4px;
  --space-scaled-s-aqzyko:8px;
  --space-scaled-m-mo5yse:12px;
  --space-scaled-l-0hpmd7:16px;
  --space-scaled-xl-kswcw7:20px;
  --space-scaled-xxl-wbot5q:24px;
  --space-scaled-xxxl-fdg8ai:32px;
}

.awsui-context-top-navigation:not(#\9) {
  --color-background-badge-icon-n0lc3o:#ff7a7a;
  --color-background-button-link-active-zsvqyt:#333843;
  --color-background-button-link-hover-7joibg:#1b232d;
  --color-background-button-normal-active-udb472:#333843;
  --color-background-button-normal-default-fauhr6:#161d26;
  --color-background-button-normal-disabled-h6arvb:#161d26;
  --color-background-button-normal-hover-rw8abf:#1b232d;
  --color-background-toggle-button-normal-pressed-cpfehj:#333843;
  --color-background-button-primary-active-qz8503:#42b4ff;
  --color-background-button-primary-default-j68ske:#42b4ff;
  --color-background-button-primary-disabled-l4i17m:#232b37;
  --color-background-button-primary-hover-wgg8kk:#75cfff;
  --color-background-direction-button-disabled-54epp1:#232b37;
  --color-text-direction-button-disabled-ok08o0:#656871;
  --color-background-calendar-current-date-a6ucrp:#333843;
  --color-background-cell-shaded-sc089k:#1b232d;
  --color-background-code-editor-gutter-active-line-default-v8is2q:#8c8c94;
  --color-background-code-editor-gutter-active-line-error-bd4ln4:#ff7a7a;
  --color-background-code-editor-gutter-default-v5e78w:#1b232d;
  --color-background-code-editor-loading-2zupgi:#1b232d;
  --color-background-code-editor-pane-item-hover-h04m4p:#333843;
  --color-background-code-editor-status-bar-ybpgwu:#1b232d;
  --color-background-container-header-clzg6q:#161d26;
  --color-background-control-checked-hz4k40:#42b4ff;
  --color-background-control-default-5ol8rv:#161d26;
  --color-background-control-disabled-5cbvij:#333843;
  --color-background-dropdown-item-default-u8emhk:#161d26;
  --color-background-dropdown-item-filter-match-5pchhq:#333843;
  --color-background-dropdown-item-hover-zhzc84:#131920;
  --color-background-dropdown-item-selected-idfej7:#001129;
  --color-background-input-default-k0a6tw:#161d26;
  --color-background-input-disabled-gvxsk4:#1b232d;
  --color-background-item-selected-njak80:#001129;
  --color-background-layout-main-cp7lkl:#161d26;
  --color-background-layout-panel-hover-1ftnua:#333843;
  --color-background-layout-toggle-active-d7mkf3:#424650;
  --color-background-layout-toggle-default-3db1tp:#424650;
  --color-background-layout-toggle-hover-6qw50e:#656871;
  --color-background-layout-toggle-selected-active-tv8i8b:#42b4ff;
  --color-background-layout-toggle-selected-default-27rnk6:#42b4ff;
  --color-background-layout-toggle-selected-hover-eh8bga:#75cfff;
  --color-background-modal-overlay-lal4at:rgba(15, 20, 26, 0.7);
  --color-background-notification-blue-qspr3y:#006ce0;
  --color-background-notification-grey-jyn3j2:#656871;
  --color-background-popover-eqi5zi:#1b232d;
  --color-background-progress-bar-content-default-sn7ya6:#42b4ff;
  --color-background-progress-bar-layout-default-urc8t0:#333843;
  --color-background-segment-active-bkvyiy:#42b4ff;
  --color-background-segment-default-voaiuw:#161d26;
  --color-background-segment-disabled-9tsgp1:#161d26;
  --color-background-segment-hover-detiep:#1b232d;
  --color-background-slider-handle-default-94lnn1:#42b4ff;
  --color-background-slider-handle-active-7w7xg0:#75cfff;
  --color-background-slider-track-uhckut:#656871;
  --color-background-slider-handle-ring-nibud6:#161d26;
  --color-background-slider-error-pressed-j572od:#ff7a7a;
  --color-background-slider-warning-pressed-pg5aih:#fbd332;
  --color-background-status-error-5fz45j:#1f0000;
  --color-background-status-info-gflgo4:#001129;
  --color-background-status-success-ua29ej:#001401;
  --color-background-status-warning-03nxlw:#191100;
  --color-background-table-header-inc72b:#161d26;
  --color-background-tiles-disabled-ozblza:#1b232d;
  --color-background-toggle-checked-disabled-wk1i1j:#002b66;
  --color-background-toggle-default-ywflhx:#8c8c94;
  --color-background-chat-bubble-incoming-8pi0ow:#0f141a;
  --color-text-chat-bubble-outgoing-bvbr0v:#c6c6cd;
  --color-text-chat-bubble-incoming-x2kig8:#c6c6cd;
  --color-border-button-normal-active-mn0ayd:#75cfff;
  --color-border-button-normal-default-x916ug:#42b4ff;
  --color-border-toggle-button-normal-pressed-lylxt2:#42b4ff;
  --color-border-button-normal-disabled-caqkhj:#656871;
  --color-border-button-normal-hover-6plta7:#75cfff;
  --color-border-button-primary-disabled-xtv38q:#232b37;
  --color-border-calendar-grid-selected-focus-ring-w7gc9h:#161d26;
  --color-border-code-editor-default-u6q2xy:#656871;
  --color-border-code-editor-pane-item-hover-qonm1g:#656871;
  --color-border-control-checked-vu1puo:#42b4ff;
  --color-border-control-disabled-qc5csz:#333843;
  --color-border-divider-active-tn8t2p:#f9f9fa;
  --color-border-divider-default-cx07f2:#424650;
  --color-border-divider-panel-bottom-c62zji:#424650;
  --color-border-divider-panel-side-j1ubnj:#424650;
  --color-border-divider-secondary-alwp8z:#232b37;
  --color-border-dropdown-container-fo7aoz:#656871;
  --color-border-dropdown-group-2xoluv:#424650;
  --color-border-dropdown-item-default-xiw1xo:#424650;
  --color-border-dropdown-item-hover-4p0gsi:#656871;
  --color-border-dropdown-item-selected-rtthnt:#42b4ff;
  --color-border-editable-cell-hover-3ju944:#656871;
  --color-border-input-default-l7v83d:#656871;
  --color-border-input-disabled-lb7zn3:#1b232d;
  --color-border-input-focused-c6w5i5:#b8e7ff;
  --color-border-item-focused-nv6mhz:#42b4ff;
  --color-border-dropdown-item-focused-5afjvu:#dedee3;
  --color-border-item-placeholder-h6zoax:#42b4ff;
  --color-border-item-selected-m769ej:#42b4ff;
  --color-border-layout-hrw5xj:#424650;
  --color-border-panel-header-nnst86:#424650;
  --color-border-popover-v2sc7l:#656871;
  --color-border-segment-active-0hw1oi:#dedee3;
  --color-border-segment-default-qegins:#dedee3;
  --color-border-segment-disabled-jfhyf7:#dedee3;
  --color-border-segment-hover-cs1sac:#dedee3;
  --color-border-status-error-adxa56:#ff7a7a;
  --color-border-status-info-z6pwqy:#42b4ff;
  --color-border-status-success-4x6srk:#2bb534;
  --color-border-status-warning-3feumr:#fbd332;
  --color-border-divider-interactive-default-0c10au:#dedee3;
  --color-border-tabs-divider-djfdfj:#424650;
  --color-border-tabs-shadow-enjrr2:rgba(15, 20, 26, 1);
  --color-border-tabs-underline-a5fmhd:#42b4ff;
  --color-border-tiles-disabled-9w9xuo:#1b232d;
  --color-border-tutorial-upbl0e:#424650;
  --color-foreground-control-default-p84gz5:#0f141a;
  --color-foreground-control-disabled-c64ihu:#161d26;
  --color-foreground-control-read-only-e43ooq:#a4a4ad;
  --color-shadow-default-u7prpu:rgba(15, 20, 26, 1);
  --color-text-accent-fptgkc:#42b4ff;
  --color-text-body-default-7v1jfn:#c6c6cd;
  --color-text-body-secondary-cwla8d:#c6c6cd;
  --color-text-breadcrumb-current-9h12sf:#8c8c94;
  --color-text-breadcrumb-icon-u5luu4:#656871;
  --color-text-button-inline-icon-default-p881yb:#42b4ff;
  --color-text-button-inline-icon-disabled-yk9lvs:#656871;
  --color-text-button-inline-icon-hover-vcdou1:#75cfff;
  --color-text-button-normal-active-fdnwsh:#75cfff;
  --color-text-toggle-button-normal-pressed-n7ydno:#75cfff;
  --color-text-button-normal-default-das2ly:#42b4ff;
  --color-text-button-normal-hover-pmztmi:#75cfff;
  --color-text-link-button-normal-default-4g1o6u:#42b4ff;
  --color-text-link-button-normal-hover-9zux4u:#75cfff;
  --color-text-link-button-normal-active-awr7yi:#75cfff;
  --color-text-button-primary-active-l1tdpc:#0f141a;
  --color-text-button-primary-default-t0t2sj:#0f141a;
  --color-text-button-primary-hover-5z07s7:#0f141a;
  --color-text-calendar-date-hover-qiity9:#dedee3;
  --color-text-calendar-month-7dq80e:#a4a4ad;
  --color-text-code-editor-gutter-active-line-xn0lux:#0f141a;
  --color-text-code-editor-gutter-default-wzd6uj:#dedee3;
  --color-text-code-editor-status-bar-disabled-wlvfa0:#656871;
  --color-text-code-editor-tab-button-error-491d5d:#0f141a;
  --color-text-column-header-18yqaj:#b4b4bb;
  --color-text-column-sorting-icon-4y2if8:#b4b4bb;
  --color-text-control-disabled-7u2yiq:#656871;
  --color-text-counter-goqopo:#a4a4ad;
  --color-text-disabled-e4vsj4:#656871;
  --color-text-disabled-inline-edit-rcb2pm:#b4b4bb;
  --color-text-dropdown-footer-1jyb4n:#a4a4ad;
  --color-text-dropdown-group-label-iy2p4t:#c6c6cd;
  --color-text-dropdown-item-default-wpxsrk:#dedee3;
  --color-text-dropdown-item-dimmed-7c5xo0:#656871;
  --color-text-dropdown-item-disabled-7fg5u8:#656871;
  --color-text-dropdown-item-filter-match-vwk0li:#75cfff;
  --color-text-dropdown-item-highlighted-rnfxxj:#ebebf0;
  --color-text-dropdown-item-secondary-xvh5zo:#a4a4ad;
  --color-text-dropdown-item-secondary-hover-4xn5ec:#dedee3;
  --color-text-empty-o6660v:#dedee3;
  --color-text-expandable-section-default-7w1jwq:#dedee3;
  --color-text-expandable-section-hover-lomw6v:#42b4ff;
  --color-text-expandable-section-navigation-icon-default-g3cuok:#d1d5db;
  --color-text-form-default-nsrnwd:#dedee3;
  --color-text-form-label-5nbxa6:#dedee3;
  --color-text-form-secondary-54emib:#a4a4ad;
  --color-text-group-label-8pk3es:#c6c6cd;
  --color-text-label-gen-ai-76ots7:#bf80ff;
  --color-text-heading-default-6yaix2:#ebebf0;
  --color-text-heading-secondary-0myrp3:#a4a4ad;
  --color-text-icon-subtle-pt80lq:#b4b4bb;
  --color-text-input-disabled-fc4jfl:#656871;
  --color-text-input-placeholder-f3x213:#a4a4ad;
  --color-text-input-placeholder-disabled-7v2f7f:#656871;
  --color-text-interactive-active-oauice:#fbfbfb;
  --color-text-interactive-default-tbacaa:#d1d5db;
  --color-text-interactive-disabled-f5b4z5:#656871;
  --color-text-interactive-hover-r6bflx:#fbfbfb;
  --color-text-toggle-button-icon-pressed-d40d50:#f9f9fa;
  --color-text-inverted-pvc88w:#0f141a;
  --color-text-label-4yezqy:#dedee3;
  --color-text-layout-toggle-i4unsz:#ffffff;
  --color-text-layout-toggle-active-as4nbt:#161d26;
  --color-text-layout-toggle-hover-v3lnw5:#42b4ff;
  --color-text-layout-toggle-selected-kb3p9r:#0f141a;
  --color-text-link-default-enwvrt:#42b4ff;
  --color-text-link-hover-49uoe9:#75cfff;
  --color-text-pagination-page-number-active-disabled-a19yjt:#656871;
  --color-text-pagination-page-number-default-s25kr0:#b4b4bb;
  --color-text-segment-active-fc6owc:#0f141a;
  --color-text-segment-default-iib3us:#dedee3;
  --color-text-segment-hover-0xctet:#75cfff;
  --color-text-small-nzfntg:#a4a4ad;
  --color-text-status-error-5676bj:#ff7a7a;
  --color-text-status-inactive-5megna:#a4a4ad;
  --color-text-status-info-7rlubr:#42b4ff;
  --color-text-status-success-gqp5xk:#2bb534;
  --color-text-status-warning-csaw41:#fbd332;
  --color-text-top-navigation-title-v9cb3m:#f9f9fa;
  --color-board-placeholder-active-93q92g:#656871;
  --color-board-placeholder-hover-yu7ixx:#006ce0;
  --color-drag-placeholder-active-llvhbu:#656871;
  --color-drag-placeholder-hover-oikifl:#006ce0;
  --color-dropzone-background-default-q8kl4f:#161d26;
  --color-dropzone-background-hover-wl3yez:#001129;
  --color-dropzone-text-default-owo5k2:#c6c6cd;
  --color-dropzone-text-hover-wteupn:#c6c6cd;
  --color-dropzone-border-default-phwpnt:#656871;
  --color-dropzone-border-hover-tpluhc:#75cfff;
}

.awsui-context-content-header:not(#\9) {
  --color-background-badge-icon-n0lc3o:#ff7a7a;
  --color-background-button-link-active-zsvqyt:#333843;
  --color-background-button-link-hover-7joibg:#1b232d;
  --color-background-button-normal-active-udb472:#333843;
  --color-background-button-normal-disabled-h6arvb:#0f141a;
  --color-background-button-normal-hover-rw8abf:#f2f8fd;
  --color-background-toggle-button-normal-pressed-cpfehj:#333843;
  --color-background-button-primary-active-qz8503:#42b4ff;
  --color-background-button-primary-default-j68ske:#0972d3;
  --color-background-button-primary-disabled-l4i17m:#232b37;
  --color-background-button-primary-hover-wgg8kk:#000716;
  --color-background-direction-button-disabled-54epp1:#232b37;
  --color-text-direction-button-disabled-ok08o0:#656871;
  --color-background-calendar-current-date-a6ucrp:#333843;
  --color-background-cell-shaded-sc089k:#1b232d;
  --color-background-code-editor-gutter-active-line-default-v8is2q:#8c8c94;
  --color-background-code-editor-gutter-active-line-error-bd4ln4:#ff7a7a;
  --color-background-code-editor-gutter-default-v5e78w:#1b232d;
  --color-background-code-editor-loading-2zupgi:#1b232d;
  --color-background-code-editor-pane-item-hover-h04m4p:#333843;
  --color-background-code-editor-status-bar-ybpgwu:#1b232d;
  --color-background-container-content-epgqkc:#161d26;
  --color-background-container-header-clzg6q:#161d26;
  --color-background-control-checked-hz4k40:#42b4ff;
  --color-background-control-default-5ol8rv:#0f141a;
  --color-background-control-disabled-5cbvij:#333843;
  --color-background-dropdown-item-default-u8emhk:#1b232d;
  --color-background-dropdown-item-filter-match-5pchhq:#333843;
  --color-background-dropdown-item-hover-zhzc84:#131920;
  --color-background-dropdown-item-selected-idfej7:#001129;
  --color-background-input-disabled-gvxsk4:#1b232d;
  --color-background-item-selected-njak80:#001129;
  --color-background-layout-panel-content-b4s8gn:#161d26;
  --color-background-layout-panel-hover-1ftnua:#333843;
  --color-background-layout-toggle-active-d7mkf3:#424650;
  --color-background-layout-toggle-default-3db1tp:#424650;
  --color-background-layout-toggle-hover-6qw50e:#656871;
  --color-background-layout-toggle-selected-active-tv8i8b:#42b4ff;
  --color-background-layout-toggle-selected-default-27rnk6:#42b4ff;
  --color-background-layout-toggle-selected-hover-eh8bga:#75cfff;
  --color-background-modal-overlay-lal4at:rgba(15, 20, 26, 0.7);
  --color-background-notification-blue-qspr3y:#006ce0;
  --color-background-notification-grey-jyn3j2:#656871;
  --color-background-popover-eqi5zi:#1b232d;
  --color-background-progress-bar-content-default-sn7ya6:#42b4ff;
  --color-background-progress-bar-layout-default-urc8t0:#333843;
  --color-background-segment-active-bkvyiy:#42b4ff;
  --color-background-segment-default-voaiuw:#0f141a;
  --color-background-segment-disabled-9tsgp1:#0f141a;
  --color-background-segment-hover-detiep:#f2f8fd;
  --color-background-slider-handle-default-94lnn1:#42b4ff;
  --color-background-slider-handle-active-7w7xg0:#75cfff;
  --color-background-slider-track-uhckut:#656871;
  --color-background-slider-handle-ring-nibud6:#161d26;
  --color-background-slider-error-pressed-j572od:#ff7a7a;
  --color-background-slider-warning-pressed-pg5aih:#fbd332;
  --color-background-status-error-5fz45j:#1f0000;
  --color-background-status-info-gflgo4:#001129;
  --color-background-status-success-ua29ej:#001401;
  --color-background-status-warning-03nxlw:#191100;
  --color-background-table-header-inc72b:#0f141a;
  --color-background-tiles-disabled-ozblza:#1b232d;
  --color-background-toggle-checked-disabled-wk1i1j:#002b66;
  --color-background-toggle-default-ywflhx:#8c8c94;
  --color-background-chat-bubble-incoming-8pi0ow:#0f141a;
  --color-text-chat-bubble-outgoing-bvbr0v:#c6c6cd;
  --color-text-chat-bubble-incoming-x2kig8:#c6c6cd;
  --color-border-button-normal-active-mn0ayd:#75cfff;
  --color-border-button-normal-default-x916ug:#0972d3;
  --color-border-toggle-button-normal-pressed-lylxt2:#42b4ff;
  --color-border-button-normal-disabled-caqkhj:#656871;
  --color-border-button-normal-hover-6plta7:#000716;
  --color-border-button-primary-disabled-xtv38q:#232b37;
  --color-border-calendar-grid-selected-focus-ring-w7gc9h:#161d26;
  --color-border-code-editor-default-u6q2xy:#656871;
  --color-border-code-editor-pane-item-hover-qonm1g:#656871;
  --color-border-control-checked-vu1puo:#42b4ff;
  --color-border-control-disabled-qc5csz:#333843;
  --color-border-divider-active-tn8t2p:#f9f9fa;
  --color-border-divider-default-cx07f2:#424650;
  --color-border-divider-panel-bottom-c62zji:#424650;
  --color-border-divider-panel-side-j1ubnj:#424650;
  --color-border-divider-secondary-alwp8z:#232b37;
  --color-border-dropdown-container-fo7aoz:#656871;
  --color-border-dropdown-group-2xoluv:#424650;
  --color-border-dropdown-item-default-xiw1xo:#424650;
  --color-border-dropdown-item-hover-4p0gsi:#656871;
  --color-border-dropdown-item-selected-rtthnt:#42b4ff;
  --color-border-editable-cell-hover-3ju944:#656871;
  --color-border-input-default-l7v83d:#656871;
  --color-border-input-disabled-lb7zn3:#1b232d;
  --color-border-input-focused-c6w5i5:#b8e7ff;
  --color-border-item-focused-nv6mhz:#42b4ff;
  --color-border-dropdown-item-focused-5afjvu:#dedee3;
  --color-border-item-placeholder-h6zoax:#42b4ff;
  --color-border-item-selected-m769ej:#42b4ff;
  --color-border-layout-hrw5xj:#424650;
  --color-border-panel-header-nnst86:#424650;
  --color-border-popover-v2sc7l:#656871;
  --color-border-segment-active-0hw1oi:#dedee3;
  --color-border-segment-default-qegins:#dedee3;
  --color-border-segment-disabled-jfhyf7:#dedee3;
  --color-border-segment-hover-cs1sac:#dedee3;
  --color-border-status-error-adxa56:#ff7a7a;
  --color-border-status-info-z6pwqy:#42b4ff;
  --color-border-status-success-4x6srk:#2bb534;
  --color-border-status-warning-3feumr:#fbd332;
  --color-border-divider-interactive-default-0c10au:#dedee3;
  --color-border-tabs-divider-djfdfj:#424650;
  --color-border-tabs-shadow-enjrr2:rgba(15, 20, 26, 1);
  --color-border-tabs-underline-a5fmhd:#42b4ff;
  --color-border-tiles-disabled-9w9xuo:#1b232d;
  --color-border-tutorial-upbl0e:#424650;
  --color-foreground-control-default-p84gz5:#0f141a;
  --color-foreground-control-disabled-c64ihu:#161d26;
  --color-foreground-control-read-only-e43ooq:#a4a4ad;
  --color-shadow-default-u7prpu:rgba(15, 20, 26, 1);
  --color-text-accent-fptgkc:#42b4ff;
  --color-text-body-default-7v1jfn:#c6c6cd;
  --color-text-body-secondary-cwla8d:#c6c6cd;
  --color-text-breadcrumb-current-9h12sf:#8c8c94;
  --color-text-breadcrumb-icon-u5luu4:#656871;
  --color-text-button-inline-icon-default-p881yb:#42b4ff;
  --color-text-button-inline-icon-disabled-yk9lvs:#656871;
  --color-text-button-inline-icon-hover-vcdou1:#75cfff;
  --color-text-button-normal-active-fdnwsh:#75cfff;
  --color-text-toggle-button-normal-pressed-n7ydno:#75cfff;
  --color-text-button-normal-default-das2ly:#0972d3;
  --color-text-button-normal-hover-pmztmi:#033160;
  --color-text-link-button-normal-default-4g1o6u:#0972d3;
  --color-text-link-button-normal-hover-9zux4u:#033160;
  --color-text-link-button-normal-active-awr7yi:#75cfff;
  --color-text-button-primary-active-l1tdpc:#0f141a;
  --color-text-button-primary-default-t0t2sj:#0f141a;
  --color-text-button-primary-hover-5z07s7:#0f141a;
  --color-text-calendar-date-hover-qiity9:#dedee3;
  --color-text-calendar-month-7dq80e:#a4a4ad;
  --color-text-code-editor-gutter-active-line-xn0lux:#0f141a;
  --color-text-code-editor-gutter-default-wzd6uj:#dedee3;
  --color-text-code-editor-status-bar-disabled-wlvfa0:#656871;
  --color-text-code-editor-tab-button-error-491d5d:#0f141a;
  --color-text-column-header-18yqaj:#b4b4bb;
  --color-text-column-sorting-icon-4y2if8:#b4b4bb;
  --color-text-control-disabled-7u2yiq:#656871;
  --color-text-counter-goqopo:#a4a4ad;
  --color-text-disabled-e4vsj4:#656871;
  --color-text-disabled-inline-edit-rcb2pm:#b4b4bb;
  --color-text-dropdown-footer-1jyb4n:#a4a4ad;
  --color-text-dropdown-group-label-iy2p4t:#c6c6cd;
  --color-text-dropdown-item-default-wpxsrk:#dedee3;
  --color-text-dropdown-item-dimmed-7c5xo0:#656871;
  --color-text-dropdown-item-disabled-7fg5u8:#656871;
  --color-text-dropdown-item-filter-match-vwk0li:#75cfff;
  --color-text-dropdown-item-highlighted-rnfxxj:#ebebf0;
  --color-text-dropdown-item-secondary-xvh5zo:#a4a4ad;
  --color-text-dropdown-item-secondary-hover-4xn5ec:#dedee3;
  --color-text-empty-o6660v:#dedee3;
  --color-text-expandable-section-default-7w1jwq:#dedee3;
  --color-text-expandable-section-hover-lomw6v:#42b4ff;
  --color-text-expandable-section-navigation-icon-default-g3cuok:#dedee3;
  --color-text-form-default-nsrnwd:#dedee3;
  --color-text-form-label-5nbxa6:#dedee3;
  --color-text-form-secondary-54emib:#a4a4ad;
  --color-text-group-label-8pk3es:#c6c6cd;
  --color-text-label-gen-ai-76ots7:#bf80ff;
  --color-text-heading-default-6yaix2:#000716;
  --color-text-heading-secondary-0myrp3:#a4a4ad;
  --color-text-icon-subtle-pt80lq:#b4b4bb;
  --color-text-input-disabled-fc4jfl:#656871;
  --color-text-input-placeholder-f3x213:#a4a4ad;
  --color-text-input-placeholder-disabled-7v2f7f:#656871;
  --color-text-interactive-active-oauice:#f9f9fa;
  --color-text-interactive-default-tbacaa:#dedee3;
  --color-text-interactive-disabled-f5b4z5:#656871;
  --color-text-interactive-hover-r6bflx:#f9f9fa;
  --color-text-toggle-button-icon-pressed-d40d50:#f9f9fa;
  --color-text-inverted-pvc88w:#0f141a;
  --color-text-label-4yezqy:#dedee3;
  --color-text-layout-toggle-i4unsz:#ffffff;
  --color-text-layout-toggle-active-as4nbt:#161d26;
  --color-text-layout-toggle-hover-v3lnw5:#42b4ff;
  --color-text-layout-toggle-selected-kb3p9r:#0f141a;
  --color-text-link-default-enwvrt:#42b4ff;
  --color-text-link-hover-49uoe9:#75cfff;
  --color-text-pagination-page-number-active-disabled-a19yjt:#656871;
  --color-text-pagination-page-number-default-s25kr0:#b4b4bb;
  --color-text-segment-active-fc6owc:#0f141a;
  --color-text-segment-default-iib3us:#dedee3;
  --color-text-segment-hover-0xctet:#033160;
  --color-text-small-nzfntg:#a4a4ad;
  --color-text-status-error-5676bj:#ff7a7a;
  --color-text-status-inactive-5megna:#a4a4ad;
  --color-text-status-info-7rlubr:#42b4ff;
  --color-text-status-success-gqp5xk:#2bb534;
  --color-text-status-warning-csaw41:#fbd332;
  --color-text-top-navigation-title-v9cb3m:#f9f9fa;
  --color-board-placeholder-active-93q92g:#656871;
  --color-board-placeholder-hover-yu7ixx:#006ce0;
  --color-drag-placeholder-active-llvhbu:#656871;
  --color-drag-placeholder-hover-oikifl:#006ce0;
  --color-dropzone-background-default-q8kl4f:#161d26;
  --color-dropzone-background-hover-wl3yez:#001129;
  --color-dropzone-text-default-owo5k2:#c6c6cd;
  --color-dropzone-text-hover-wteupn:#c6c6cd;
  --color-dropzone-border-default-phwpnt:#656871;
  --color-dropzone-border-hover-tpluhc:#75cfff;
  --shadow-container-fxmdn3:0px 1px 8px 2px rgba(0, 7, 22, 0.6);
  --shadow-container-active-kl29x9:0px 1px 1px 1px #192534, 0px 6px 36px #00040c;
  --shadow-dropdown-e5guvm:0px 4px 20px 1px rgba(0, 4, 12, 1);
  --shadow-dropup-xqkiza:0px 4px 20px 1px rgba(0, 4, 12, 1);
  --shadow-modal-2ennni:0px 4px 20px 1px rgba(0, 4, 12, 1);
  --shadow-popover-8zls8c:0px 4px 20px 1px rgba(0, 4, 12, 1);
  --shadow-split-bottom-5e389u:0px -36px 36px -36px rgba(0, 7, 22, 1);
  --shadow-split-side-ggdtnk:-1px 0px 1px 0px #192534, -36px 6px 36px -36px rgba(0, 7, 22, 1);
  --shadow-sticky-blixp9:0px 4px 8px 1px rgba(0, 7, 22, 0.5);
  --shadow-sticky-embedded-uvh5ry:0px 2px 0px 0px #414d5c, 0px 16px 16px -12px rgba(0, 7, 22, 1);
  --shadow-sticky-column-first-039dqs:0px 4px 8px 1px rgba(0, 7, 22, 0.5);
  --shadow-sticky-column-last-n170vw:0px 4px 8px 1px rgba(0, 7, 22, 0.5);
}

.awsui-context-flashbar:not(#\9) {
  --color-background-button-normal-active-udb472:rgba(0, 7, 22, 0.2);
  --color-background-button-normal-default-fauhr6:transparent;
  --color-background-button-normal-hover-rw8abf:rgba(0, 7, 22, 0.15);
  --color-background-button-primary-default-j68ske:#f9f9fa;
  --color-background-button-primary-hover-wgg8kk:#ffffff;
  --color-background-layout-toggle-active-d7mkf3:#424650;
  --color-background-layout-toggle-default-3db1tp:#424650;
  --color-background-layout-toggle-hover-6qw50e:#656871;
  --color-background-notification-blue-qspr3y:#006ce0;
  --color-background-segment-default-voaiuw:transparent;
  --color-background-segment-hover-detiep:rgba(0, 7, 22, 0.15);
  --color-text-chat-bubble-outgoing-bvbr0v:#f9f9fa;
  --color-text-chat-bubble-incoming-x2kig8:#f9f9fa;
  --color-border-button-normal-active-mn0ayd:#ffffff;
  --color-border-button-normal-default-x916ug:#f9f9fa;
  --color-border-button-normal-hover-6plta7:#ffffff;
  --color-border-divider-default-cx07f2:#f9f9fa;
  --color-border-divider-panel-bottom-c62zji:#f9f9fa;
  --color-border-divider-panel-side-j1ubnj:#f9f9fa;
  --color-border-dropdown-group-2xoluv:#f9f9fa;
  --color-border-dropdown-item-default-xiw1xo:#f9f9fa;
  --color-border-item-focused-nv6mhz:#f9f9fa;
  --color-border-panel-header-nnst86:#f9f9fa;
  --color-text-body-default-7v1jfn:#f9f9fa;
  --color-text-button-normal-active-fdnwsh:#ffffff;
  --color-text-button-normal-default-das2ly:#f9f9fa;
  --color-text-button-normal-hover-pmztmi:#ffffff;
  --color-text-link-button-normal-default-4g1o6u:#f9f9fa;
  --color-text-link-button-normal-hover-9zux4u:#ffffff;
  --color-text-link-button-normal-active-awr7yi:#ffffff;
  --color-text-expandable-section-default-7w1jwq:#f9f9fa;
  --color-text-expandable-section-hover-lomw6v:#ffffff;
  --color-text-heading-secondary-0myrp3:#f9f9fa;
  --color-text-layout-toggle-i4unsz:#ffffff;
  --color-text-segment-hover-0xctet:#ffffff;
}

.awsui-context-flashbar-warning:not(#\9) {
  --color-background-button-normal-active-udb472:rgba(0, 7, 22, 0.1);
  --color-background-button-normal-default-fauhr6:transparent;
  --color-background-button-normal-hover-rw8abf:rgba(0, 7, 22, 0.05);
  --color-background-button-primary-default-j68ske:#424650;
  --color-background-button-primary-hover-wgg8kk:#0f141a;
  --color-background-layout-toggle-active-d7mkf3:#424650;
  --color-background-layout-toggle-default-3db1tp:#424650;
  --color-background-layout-toggle-hover-6qw50e:#656871;
  --color-background-notification-blue-qspr3y:#006ce0;
  --color-background-progress-bar-content-in-flash-ikihd5:#0f141a;
  --color-background-progress-bar-layout-in-flash-d3q12s:rgba(0, 0, 0, 0.1);
  --color-background-segment-default-voaiuw:transparent;
  --color-background-segment-hover-detiep:rgba(0, 7, 22, 0.05);
  --color-border-button-normal-active-mn0ayd:#0f141a;
  --color-border-button-normal-default-x916ug:#424650;
  --color-border-button-normal-hover-6plta7:#0f141a;
  --color-border-divider-default-cx07f2:#0f141a;
  --color-border-divider-panel-bottom-c62zji:#0f141a;
  --color-border-divider-panel-side-j1ubnj:#0f141a;
  --color-border-dropdown-group-2xoluv:#0f141a;
  --color-border-dropdown-item-default-xiw1xo:#0f141a;
  --color-border-item-focused-nv6mhz:#0f141a;
  --color-border-panel-header-nnst86:#0f141a;
  --color-text-button-normal-active-fdnwsh:#0f141a;
  --color-text-button-normal-default-das2ly:#424650;
  --color-text-button-normal-hover-pmztmi:#0f141a;
  --color-text-link-button-normal-active-awr7yi:#0f141a;
  --color-text-expandable-section-hover-lomw6v:#0f141a;
  --color-text-heading-secondary-0myrp3:#0f141a;
  --color-text-interactive-inverted-default-8v8d60:#424650;
  --color-text-interactive-inverted-hover-0r4deg:#0f141a;
  --color-text-layout-toggle-i4unsz:#ffffff;
  --color-text-link-inverted-hover-zsv0ev:#0f141a;
  --color-text-notification-default-0l2rzu:#0f141a;
  --color-text-segment-hover-0xctet:#0f141a;
}

.awsui-context-alert:not(#\9) {
  --color-background-button-normal-active-udb472:rgba(0, 7, 22, 0.1);
  --color-background-button-normal-default-fauhr6:transparent;
  --color-background-button-normal-hover-rw8abf:rgba(0, 7, 22, 0.05);
  --color-background-button-primary-default-j68ske:#424650;
  --color-background-button-primary-hover-wgg8kk:#0f141a;
  --color-background-layout-toggle-active-d7mkf3:#424650;
  --color-background-layout-toggle-default-3db1tp:#424650;
  --color-background-layout-toggle-hover-6qw50e:#656871;
  --color-background-notification-blue-qspr3y:#006ce0;
  --color-background-segment-default-voaiuw:transparent;
  --color-background-segment-hover-detiep:rgba(0, 7, 22, 0.05);
  --color-border-button-normal-active-mn0ayd:#0f141a;
  --color-border-button-normal-default-x916ug:#424650;
  --color-border-button-normal-hover-6plta7:#0f141a;
  --color-border-divider-default-cx07f2:#424650;
  --color-border-divider-panel-bottom-c62zji:#424650;
  --color-border-divider-panel-side-j1ubnj:#424650;
  --color-border-dropdown-group-2xoluv:#424650;
  --color-border-dropdown-item-default-xiw1xo:#424650;
  --color-border-panel-header-nnst86:#424650;
  --color-text-button-normal-active-fdnwsh:#0f141a;
  --color-text-button-normal-default-das2ly:#424650;
  --color-text-button-normal-hover-pmztmi:#0f141a;
  --color-text-link-button-normal-active-awr7yi:#0f141a;
  --color-text-expandable-section-default-7w1jwq:#424650;
  --color-text-expandable-section-hover-lomw6v:#0f141a;
  --color-text-layout-toggle-i4unsz:#ffffff;
  --color-text-segment-hover-0xctet:#0f141a;
  --font-expandable-heading-size-smdiul:14px;
}

.awsui-context-content-header .awsui-context-alert:not(#\9) {
  --color-background-badge-icon-n0lc3o:#ff7a7a;
  --color-background-button-link-active-zsvqyt:#333843;
  --color-background-button-link-hover-7joibg:#1b232d;
  --color-background-button-normal-active-udb472:rgba(255, 255, 255, 0.15);
  --color-background-button-normal-default-fauhr6:transparent;
  --color-background-button-normal-disabled-h6arvb:#161d26;
  --color-background-button-normal-hover-rw8abf:rgba(255, 255, 255, 0.1);
  --color-background-toggle-button-normal-pressed-cpfehj:#333843;
  --color-background-button-primary-active-qz8503:#42b4ff;
  --color-background-button-primary-default-j68ske:#dedee3;
  --color-background-button-primary-disabled-l4i17m:#232b37;
  --color-background-button-primary-hover-wgg8kk:#ffffff;
  --color-background-direction-button-disabled-54epp1:#232b37;
  --color-text-direction-button-disabled-ok08o0:#656871;
  --color-background-calendar-current-date-a6ucrp:#333843;
  --color-background-cell-shaded-sc089k:#1b232d;
  --color-background-code-editor-gutter-active-line-default-v8is2q:#8c8c94;
  --color-background-code-editor-gutter-active-line-error-bd4ln4:#ff7a7a;
  --color-background-code-editor-gutter-default-v5e78w:#1b232d;
  --color-background-code-editor-loading-2zupgi:#1b232d;
  --color-background-code-editor-pane-item-hover-h04m4p:#333843;
  --color-background-code-editor-status-bar-ybpgwu:#1b232d;
  --color-background-container-content-epgqkc:#161d26;
  --color-background-container-header-clzg6q:#161d26;
  --color-background-control-checked-hz4k40:#42b4ff;
  --color-background-control-default-5ol8rv:#161d26;
  --color-background-control-disabled-5cbvij:#333843;
  --color-background-dropdown-item-default-u8emhk:#1b232d;
  --color-background-dropdown-item-filter-match-5pchhq:#333843;
  --color-background-dropdown-item-hover-zhzc84:#131920;
  --color-background-dropdown-item-selected-idfej7:#001129;
  --color-background-input-default-k0a6tw:#161d26;
  --color-background-input-disabled-gvxsk4:#1b232d;
  --color-background-item-selected-njak80:#001129;
  --color-background-layout-main-cp7lkl:#161d26;
  --color-background-layout-panel-content-b4s8gn:#161d26;
  --color-background-layout-panel-hover-1ftnua:#333843;
  --color-background-layout-toggle-active-d7mkf3:#424650;
  --color-background-layout-toggle-default-3db1tp:#424650;
  --color-background-layout-toggle-hover-6qw50e:#656871;
  --color-background-layout-toggle-selected-active-tv8i8b:#42b4ff;
  --color-background-layout-toggle-selected-default-27rnk6:#42b4ff;
  --color-background-layout-toggle-selected-hover-eh8bga:#75cfff;
  --color-background-modal-overlay-lal4at:rgba(15, 20, 26, 0.7);
  --color-background-notification-blue-qspr3y:#006ce0;
  --color-background-notification-grey-jyn3j2:#656871;
  --color-background-popover-eqi5zi:#1b232d;
  --color-background-progress-bar-content-default-sn7ya6:#42b4ff;
  --color-background-progress-bar-layout-default-urc8t0:#333843;
  --color-background-segment-active-bkvyiy:#42b4ff;
  --color-background-segment-default-voaiuw:transparent;
  --color-background-segment-disabled-9tsgp1:#161d26;
  --color-background-segment-hover-detiep:rgba(255, 255, 255, 0.1);
  --color-background-slider-handle-default-94lnn1:#42b4ff;
  --color-background-slider-handle-active-7w7xg0:#75cfff;
  --color-background-slider-track-uhckut:#656871;
  --color-background-slider-handle-ring-nibud6:#161d26;
  --color-background-slider-error-pressed-j572od:#ff7a7a;
  --color-background-slider-warning-pressed-pg5aih:#fbd332;
  --color-background-status-error-5fz45j:#1f0000;
  --color-background-status-info-gflgo4:#001129;
  --color-background-status-success-ua29ej:#001401;
  --color-background-status-warning-03nxlw:#191100;
  --color-background-table-header-inc72b:#161d26;
  --color-background-tiles-disabled-ozblza:#1b232d;
  --color-background-toggle-checked-disabled-wk1i1j:#002b66;
  --color-background-toggle-default-ywflhx:#8c8c94;
  --color-background-chat-bubble-incoming-8pi0ow:#0f141a;
  --color-text-chat-bubble-outgoing-bvbr0v:#c6c6cd;
  --color-text-chat-bubble-incoming-x2kig8:#c6c6cd;
  --color-border-button-normal-active-mn0ayd:#ffffff;
  --color-border-button-normal-default-x916ug:#dedee3;
  --color-border-toggle-button-normal-pressed-lylxt2:#42b4ff;
  --color-border-button-normal-disabled-caqkhj:#656871;
  --color-border-button-normal-hover-6plta7:#ffffff;
  --color-border-button-primary-disabled-xtv38q:#232b37;
  --color-border-calendar-grid-selected-focus-ring-w7gc9h:#161d26;
  --color-border-code-editor-default-u6q2xy:#656871;
  --color-border-code-editor-pane-item-hover-qonm1g:#656871;
  --color-border-control-checked-vu1puo:#42b4ff;
  --color-border-control-disabled-qc5csz:#333843;
  --color-border-divider-active-tn8t2p:#f9f9fa;
  --color-border-divider-default-cx07f2:#dedee3;
  --color-border-divider-panel-bottom-c62zji:#dedee3;
  --color-border-divider-panel-side-j1ubnj:#dedee3;
  --color-border-divider-secondary-alwp8z:#232b37;
  --color-border-dropdown-container-fo7aoz:#656871;
  --color-border-dropdown-group-2xoluv:#dedee3;
  --color-border-dropdown-item-default-xiw1xo:#dedee3;
  --color-border-dropdown-item-hover-4p0gsi:#656871;
  --color-border-dropdown-item-selected-rtthnt:#42b4ff;
  --color-border-editable-cell-hover-3ju944:#656871;
  --color-border-input-default-l7v83d:#656871;
  --color-border-input-disabled-lb7zn3:#1b232d;
  --color-border-input-focused-c6w5i5:#b8e7ff;
  --color-border-item-focused-nv6mhz:#f9f9fa;
  --color-border-dropdown-item-focused-5afjvu:#dedee3;
  --color-border-item-placeholder-h6zoax:#42b4ff;
  --color-border-item-selected-m769ej:#42b4ff;
  --color-border-layout-hrw5xj:#424650;
  --color-border-panel-header-nnst86:#dedee3;
  --color-border-popover-v2sc7l:#656871;
  --color-border-segment-active-0hw1oi:#dedee3;
  --color-border-segment-default-qegins:#dedee3;
  --color-border-segment-disabled-jfhyf7:#dedee3;
  --color-border-segment-hover-cs1sac:#dedee3;
  --color-border-status-error-adxa56:#ff7a7a;
  --color-border-status-info-z6pwqy:#42b4ff;
  --color-border-status-success-4x6srk:#2bb534;
  --color-border-status-warning-3feumr:#fbd332;
  --color-border-divider-interactive-default-0c10au:#dedee3;
  --color-border-tabs-divider-djfdfj:#424650;
  --color-border-tabs-shadow-enjrr2:rgba(15, 20, 26, 1);
  --color-border-tabs-underline-a5fmhd:#42b4ff;
  --color-border-tiles-disabled-9w9xuo:#1b232d;
  --color-border-tutorial-upbl0e:#424650;
  --color-foreground-control-default-p84gz5:#0f141a;
  --color-foreground-control-disabled-c64ihu:#161d26;
  --color-foreground-control-read-only-e43ooq:#a4a4ad;
  --color-shadow-default-u7prpu:rgba(15, 20, 26, 1);
  --color-text-accent-fptgkc:#42b4ff;
  --color-text-body-default-7v1jfn:#c6c6cd;
  --color-text-body-secondary-cwla8d:#c6c6cd;
  --color-text-breadcrumb-current-9h12sf:#8c8c94;
  --color-text-breadcrumb-icon-u5luu4:#656871;
  --color-text-button-inline-icon-default-p881yb:#42b4ff;
  --color-text-button-inline-icon-disabled-yk9lvs:#656871;
  --color-text-button-inline-icon-hover-vcdou1:#75cfff;
  --color-text-button-normal-active-fdnwsh:#ffffff;
  --color-text-toggle-button-normal-pressed-n7ydno:#75cfff;
  --color-text-button-normal-default-das2ly:#dedee3;
  --color-text-button-normal-hover-pmztmi:#ffffff;
  --color-text-link-button-normal-default-4g1o6u:#42b4ff;
  --color-text-link-button-normal-hover-9zux4u:#75cfff;
  --color-text-link-button-normal-active-awr7yi:#ffffff;
  --color-text-button-primary-active-l1tdpc:#0f141a;
  --color-text-button-primary-default-t0t2sj:#0f141a;
  --color-text-button-primary-hover-5z07s7:#0f141a;
  --color-text-calendar-date-hover-qiity9:#dedee3;
  --color-text-calendar-month-7dq80e:#a4a4ad;
  --color-text-code-editor-gutter-active-line-xn0lux:#0f141a;
  --color-text-code-editor-gutter-default-wzd6uj:#dedee3;
  --color-text-code-editor-status-bar-disabled-wlvfa0:#656871;
  --color-text-code-editor-tab-button-error-491d5d:#0f141a;
  --color-text-column-header-18yqaj:#b4b4bb;
  --color-text-column-sorting-icon-4y2if8:#b4b4bb;
  --color-text-control-disabled-7u2yiq:#656871;
  --color-text-counter-goqopo:#a4a4ad;
  --color-text-disabled-e4vsj4:#656871;
  --color-text-disabled-inline-edit-rcb2pm:#b4b4bb;
  --color-text-dropdown-footer-1jyb4n:#a4a4ad;
  --color-text-dropdown-group-label-iy2p4t:#c6c6cd;
  --color-text-dropdown-item-default-wpxsrk:#dedee3;
  --color-text-dropdown-item-dimmed-7c5xo0:#656871;
  --color-text-dropdown-item-disabled-7fg5u8:#656871;
  --color-text-dropdown-item-filter-match-vwk0li:#75cfff;
  --color-text-dropdown-item-highlighted-rnfxxj:#ebebf0;
  --color-text-dropdown-item-secondary-xvh5zo:#a4a4ad;
  --color-text-dropdown-item-secondary-hover-4xn5ec:#dedee3;
  --color-text-empty-o6660v:#dedee3;
  --color-text-expandable-section-default-7w1jwq:#dedee3;
  --color-text-expandable-section-hover-lomw6v:#ffffff;
  --color-text-expandable-section-navigation-icon-default-g3cuok:#dedee3;
  --color-text-form-default-nsrnwd:#dedee3;
  --color-text-form-label-5nbxa6:#dedee3;
  --color-text-form-secondary-54emib:#a4a4ad;
  --color-text-group-label-8pk3es:#c6c6cd;
  --color-text-label-gen-ai-76ots7:#bf80ff;
  --color-text-heading-default-6yaix2:#ebebf0;
  --color-text-heading-secondary-0myrp3:#a4a4ad;
  --color-text-icon-subtle-pt80lq:#b4b4bb;
  --color-text-input-disabled-fc4jfl:#656871;
  --color-text-input-placeholder-f3x213:#a4a4ad;
  --color-text-input-placeholder-disabled-7v2f7f:#656871;
  --color-text-interactive-active-oauice:#f9f9fa;
  --color-text-interactive-default-tbacaa:#dedee3;
  --color-text-interactive-disabled-f5b4z5:#656871;
  --color-text-interactive-hover-r6bflx:#f9f9fa;
  --color-text-toggle-button-icon-pressed-d40d50:#f9f9fa;
  --color-text-inverted-pvc88w:#0f141a;
  --color-text-label-4yezqy:#dedee3;
  --color-text-layout-toggle-i4unsz:#ffffff;
  --color-text-layout-toggle-active-as4nbt:#161d26;
  --color-text-layout-toggle-hover-v3lnw5:#42b4ff;
  --color-text-layout-toggle-selected-kb3p9r:#0f141a;
  --color-text-link-default-enwvrt:#42b4ff;
  --color-text-link-hover-49uoe9:#75cfff;
  --color-text-pagination-page-number-active-disabled-a19yjt:#656871;
  --color-text-pagination-page-number-default-s25kr0:#b4b4bb;
  --color-text-segment-active-fc6owc:#0f141a;
  --color-text-segment-default-iib3us:#dedee3;
  --color-text-segment-hover-0xctet:#ffffff;
  --color-text-small-nzfntg:#a4a4ad;
  --color-text-status-error-5676bj:#ff7a7a;
  --color-text-status-inactive-5megna:#a4a4ad;
  --color-text-status-info-7rlubr:#42b4ff;
  --color-text-status-success-gqp5xk:#2bb534;
  --color-text-status-warning-csaw41:#fbd332;
  --color-text-top-navigation-title-v9cb3m:#f9f9fa;
  --color-board-placeholder-active-93q92g:#656871;
  --color-board-placeholder-hover-yu7ixx:#006ce0;
  --color-drag-placeholder-active-llvhbu:#656871;
  --color-drag-placeholder-hover-oikifl:#006ce0;
  --color-dropzone-background-default-q8kl4f:#161d26;
  --color-dropzone-background-hover-wl3yez:#001129;
  --color-dropzone-text-default-owo5k2:#c6c6cd;
  --color-dropzone-text-hover-wteupn:#c6c6cd;
  --color-dropzone-border-default-phwpnt:#656871;
  --color-dropzone-border-hover-tpluhc:#75cfff;
}

@media not print {
  .awsui-dark-mode .awsui-context-top-navigation:not(#\9) {
    --color-background-container-content-epgqkc:#ffffff;
    --color-background-layout-panel-content-b4s8gn:#ffffff;
  }
}
@media not print {
  .awsui-context-top-navigation.awsui-dark-mode:not(#\9) {
    --color-background-container-content-epgqkc:#ffffff;
    --color-background-layout-panel-content-b4s8gn:#ffffff;
  }
}
@media not print {
  .awsui-dark-mode .awsui-context-content-header:not(#\9) {
    --color-background-button-normal-default-fauhr6:#ffffff;
    --color-background-input-default-k0a6tw:#ffffff;
    --color-background-layout-main-cp7lkl:#ffffff;
  }
}
@media not print {
  .awsui-context-content-header.awsui-dark-mode:not(#\9) {
    --color-background-button-normal-default-fauhr6:#ffffff;
    --color-background-input-default-k0a6tw:#ffffff;
    --color-background-layout-main-cp7lkl:#ffffff;
  }
}
@media not print {
  .awsui-dark-mode .awsui-context-flashbar-warning:not(#\9) {
    --color-text-chat-bubble-outgoing-bvbr0v:#0f141a;
    --color-text-chat-bubble-incoming-x2kig8:#0f141a;
    --color-text-body-default-7v1jfn:#0f141a;
    --color-text-expandable-section-default-7w1jwq:#0f141a;
  }
}
@media not print {
  .awsui-context-flashbar-warning.awsui-dark-mode:not(#\9) {
    --color-text-chat-bubble-outgoing-bvbr0v:#0f141a;
    --color-text-chat-bubble-incoming-x2kig8:#0f141a;
    --color-text-body-default-7v1jfn:#0f141a;
    --color-text-expandable-section-default-7w1jwq:#0f141a;
  }
}
@media not print {
  .awsui-dark-mode .awsui-context-alert:not(#\9) {
    --color-background-button-normal-active-udb472:rgba(255, 255, 255, 0.15);
    --color-background-button-normal-hover-rw8abf:rgba(255, 255, 255, 0.1);
    --color-background-button-primary-default-j68ske:#dedee3;
    --color-background-button-primary-hover-wgg8kk:#ffffff;
    --color-background-segment-hover-detiep:rgba(255, 255, 255, 0.1);
    --color-border-button-normal-active-mn0ayd:#ffffff;
    --color-border-button-normal-default-x916ug:#dedee3;
    --color-border-button-normal-hover-6plta7:#ffffff;
    --color-border-divider-default-cx07f2:#dedee3;
    --color-border-divider-panel-bottom-c62zji:#dedee3;
    --color-border-divider-panel-side-j1ubnj:#dedee3;
    --color-border-dropdown-group-2xoluv:#dedee3;
    --color-border-dropdown-item-default-xiw1xo:#dedee3;
    --color-border-item-focused-nv6mhz:#f9f9fa;
    --color-border-panel-header-nnst86:#dedee3;
    --color-text-button-normal-active-fdnwsh:#ffffff;
    --color-text-button-normal-default-das2ly:#dedee3;
    --color-text-button-normal-hover-pmztmi:#ffffff;
    --color-text-link-button-normal-active-awr7yi:#ffffff;
    --color-text-expandable-section-default-7w1jwq:#dedee3;
    --color-text-expandable-section-hover-lomw6v:#ffffff;
    --color-text-segment-hover-0xctet:#ffffff;
  }
}
@media not print {
  .awsui-context-alert.awsui-dark-mode:not(#\9) {
    --color-background-button-normal-active-udb472:rgba(255, 255, 255, 0.15);
    --color-background-button-normal-hover-rw8abf:rgba(255, 255, 255, 0.1);
    --color-background-button-primary-default-j68ske:#dedee3;
    --color-background-button-primary-hover-wgg8kk:#ffffff;
    --color-background-segment-hover-detiep:rgba(255, 255, 255, 0.1);
    --color-border-button-normal-active-mn0ayd:#ffffff;
    --color-border-button-normal-default-x916ug:#dedee3;
    --color-border-button-normal-hover-6plta7:#ffffff;
    --color-border-divider-default-cx07f2:#dedee3;
    --color-border-divider-panel-bottom-c62zji:#dedee3;
    --color-border-divider-panel-side-j1ubnj:#dedee3;
    --color-border-dropdown-group-2xoluv:#dedee3;
    --color-border-dropdown-item-default-xiw1xo:#dedee3;
    --color-border-item-focused-nv6mhz:#f9f9fa;
    --color-border-panel-header-nnst86:#dedee3;
    --color-text-button-normal-active-fdnwsh:#ffffff;
    --color-text-button-normal-default-das2ly:#dedee3;
    --color-text-button-normal-hover-pmztmi:#ffffff;
    --color-text-link-button-normal-active-awr7yi:#ffffff;
    --color-text-expandable-section-default-7w1jwq:#dedee3;
    --color-text-expandable-section-hover-lomw6v:#ffffff;
    --color-text-segment-hover-0xctet:#ffffff;
  }
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@keyframes awsui-fade-in-35003c {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui-fade-out-35003c {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes awsui-status-icon-error-35003c {
  0% {
    transform: translateX(-5px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(5px);
    animation-timing-function: var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes awsui-scale-popup-35003c {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}