/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_option_1p2cx_4qlzt_153:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  align-items: flex-start;
  inline-size: 100%;
  min-inline-size: 0;
  box-sizing: border-box;
  color: inherit;
}
.awsui_option_1p2cx_4qlzt_153:not(#\9):not(.awsui_disabled_1p2cx_4qlzt_190) {
  cursor: inherit;
}
.awsui_option_1p2cx_4qlzt_153.awsui_parent_1p2cx_4qlzt_193:not(#\9) {
  font-weight: bold;
}
.awsui_option_1p2cx_4qlzt_153.awsui_parent_1p2cx_4qlzt_193:not(#\9):not(.awsui_disabled_1p2cx_4qlzt_190):not(.awsui_highlighted_1p2cx_4qlzt_196) {
  color: var(--color-text-dropdown-group-label-iy2p4t, #424650);
}

.awsui_content_1p2cx_4qlzt_200:not(#\9) {
  inline-size: 100%;
  display: flex;
  flex-direction: column;
  min-inline-size: 0;
}

.awsui_label-content_1p2cx_4qlzt_207:not(#\9) {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.awsui_label_1p2cx_4qlzt_207:not(#\9),
.awsui_tag_1p2cx_4qlzt_214:not(#\9),
.awsui_label-tag_1p2cx_4qlzt_215:not(#\9) {
  min-inline-size: 0;
  word-break: break-word;
}

.awsui_label_1p2cx_4qlzt_207:not(#\9),
.awsui_tag_1p2cx_4qlzt_214:not(#\9) {
  flex-wrap: wrap;
}

.awsui_label-prefix_1p2cx_4qlzt_225:not(#\9) {
  font-weight: 700;
}

.awsui_label-tag_1p2cx_4qlzt_215:not(#\9) {
  padding-inline-start: var(--space-s-34lx8l, 12px);
  flex: auto;
  text-align: end;
}
.awsui_label-tag_1p2cx_4qlzt_215:not(#\9):empty {
  display: none;
}

.awsui_tags_1p2cx_4qlzt_238:not(#\9),
.awsui_description_1p2cx_4qlzt_239:not(#\9) {
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  color: var(--color-text-dropdown-item-secondary-xvh5zo, #656871);
  flex-wrap: wrap;
}
.awsui_tags_1p2cx_4qlzt_238.awsui_highlighted_1p2cx_4qlzt_196:not(#\9),
.awsui_description_1p2cx_4qlzt_239.awsui_highlighted_1p2cx_4qlzt_196:not(#\9) {
  color: var(--color-text-dropdown-item-secondary-hover-4xn5ec, #656871);
}
.awsui_tags_1p2cx_4qlzt_238.awsui_selected_1p2cx_4qlzt_250:not(#\9),
.awsui_description_1p2cx_4qlzt_239.awsui_selected_1p2cx_4qlzt_250:not(#\9) {
  color: var(--color-text-dropdown-item-secondary-xvh5zo, #656871);
}

.awsui_tags_1p2cx_4qlzt_238:not(#\9) {
  display: flex;
  align-items: stretch;
}

.awsui_tag_1p2cx_4qlzt_214:not(#\9):not(:last-child) {
  padding-inline-end: var(--space-m-udix3p, 16px);
}

.awsui_icon_1p2cx_4qlzt_264:not(#\9) {
  padding-inline-end: var(--space-xs-zb16t3, 8px);
  align-content: center;
  display: flex;
  flex-shrink: 0;
}

.awsui_icon-size-big_1p2cx_4qlzt_271:not(#\9) {
  padding-block-start: var(--space-option-icon-big-top-hybljv, 0px);
}

.awsui_filtering-match-highlight_1p2cx_4qlzt_275:not(#\9) {
  background-color: var(--color-background-dropdown-item-filter-match-5pchhq, #f0fbff);
  color: var(--color-text-dropdown-item-filter-match-vwk0li, #006ce0);
  font-weight: bold;
}

.awsui_disabled_1p2cx_4qlzt_190:not(#\9) {
  color: var(--color-text-dropdown-item-disabled-7fg5u8, #b4b4bb);
  pointer-events: none;
}
.awsui_disabled_1p2cx_4qlzt_190 > .awsui_content_1p2cx_4qlzt_200 > .awsui_tags_1p2cx_4qlzt_238:not(#\9),
.awsui_disabled_1p2cx_4qlzt_190 > .awsui_content_1p2cx_4qlzt_200 > .awsui_description_1p2cx_4qlzt_239:not(#\9) {
  color: currentColor;
}

.awsui_trigger-variant_1p2cx_4qlzt_290:not(#\9) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}