/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_37gf8_14wux_9:not(#\9) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-xs-zb16t3, 8px);
}
.awsui_root_37gf8_14wux_9 > :not(#\9):empty {
  display: none;
}