/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_2qdw9_1cxpr_181:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  cursor: inherit;
  display: flex;
  inline-size: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.awsui_root_2qdw9_1cxpr_181.awsui_refresh_2qdw9_1cxpr_217:not(#\9), .awsui_root_2qdw9_1cxpr_181:not(#\9):not(.awsui_root-no-actions_2qdw9_1cxpr_217) {
  row-gap: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_root-no-actions_2qdw9_1cxpr_217.awsui_root-variant-h2_2qdw9_1cxpr_220:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217), .awsui_root-no-actions_2qdw9_1cxpr_217.awsui_root-variant-h3_2qdw9_1cxpr_220:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217), .awsui_root-has-description_2qdw9_1cxpr_220.awsui_root-variant-h2_2qdw9_1cxpr_220:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217), .awsui_root-has-description_2qdw9_1cxpr_220.awsui_root-variant-h3_2qdw9_1cxpr_220:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_root-no-actions_2qdw9_1cxpr_217:not(#\9):not(.awsui_root-has-description_2qdw9_1cxpr_220):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block-end: calc(var(--space-scaled-xs-26e2du, 8px) + var(--space-xxxs-zbmxqb, 2px));
}
.awsui_root-no-actions_2qdw9_1cxpr_217.awsui_refresh_2qdw9_1cxpr_217.awsui_root-variant-h2_2qdw9_1cxpr_220:not(#\9), .awsui_root-no-actions_2qdw9_1cxpr_217.awsui_refresh_2qdw9_1cxpr_217.awsui_root-variant-h3_2qdw9_1cxpr_220:not(#\9) {
  row-gap: var(--space-scaled-xxxs-27y4hv, 2px);
}
.awsui_root_2qdw9_1cxpr_181.awsui_root-variant-h1_2qdw9_1cxpr_229:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  row-gap: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_root-no-actions_2qdw9_1cxpr_217:not(#\9):not(.awsui_root-has-description_2qdw9_1cxpr_220).awsui_refresh_2qdw9_1cxpr_217.awsui_root-variant-h3_2qdw9_1cxpr_220 {
  padding-block-end: var(--space-scaled-xs-26e2du, 8px);
}
.awsui_root_2qdw9_1cxpr_181.awsui_root-variant-h1_2qdw9_1cxpr_229.awsui_root-has-description_2qdw9_1cxpr_220:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block-end: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}

.awsui_main_2qdw9_1cxpr_239:not(#\9) {
  word-wrap: break-word;
  max-inline-size: 100%;
  display: flex;
  justify-content: space-between;
  inline-size: 100%;
  flex-wrap: wrap;
  column-gap: var(--space-xs-zb16t3, 8px);
  row-gap: calc(var(--space-scaled-xxs-7597g1, 4px) + var(--space-scaled-xxxs-27y4hv, 2px));
}
.awsui_main_2qdw9_1cxpr_239.awsui_no-wrap_2qdw9_1cxpr_249:not(#\9) {
  flex-wrap: nowrap;
}
.awsui_main_2qdw9_1cxpr_239.awsui_refresh_2qdw9_1cxpr_217:not(#\9) {
  row-gap: var(--space-xxs-p8yyaw, 4px);
}
.awsui_main-variant-h1_2qdw9_1cxpr_255:not(#\9) {
  row-gap: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}

.awsui_root-has-description_2qdw9_1cxpr_220 > .awsui_main_2qdw9_1cxpr_239:not(#\9) {
  row-gap: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}

.awsui_actions_2qdw9_1cxpr_263:not(#\9) {
  display: flex;
  align-items: flex-start;
  min-block-size: var(--size-vertical-input-v5iwwf, 32px);
}
.awsui_actions-centered_2qdw9_1cxpr_268:not(#\9) {
  align-items: center;
}
.awsui_actions-variant-h1_2qdw9_1cxpr_271.awsui_refresh_2qdw9_1cxpr_217:not(#\9) {
  padding-block: calc((var(--line-height-heading-xl-avbttk, 30px) - var(--size-vertical-input-v5iwwf, 32px)) / 2);
  padding-inline: 0;
}
.awsui_actions-variant-h2_2qdw9_1cxpr_275.awsui_refresh_2qdw9_1cxpr_217:not(#\9) {
  padding-block: calc((var(--line-height-heading-l-mmm3my, 24px) - var(--size-vertical-input-v5iwwf, 32px)) / 2);
  padding-inline: 0;
}
.awsui_actions-variant-h3_2qdw9_1cxpr_279.awsui_refresh_2qdw9_1cxpr_217:not(#\9) {
  padding-block: calc((var(--line-height-heading-m-50evfk, 22px) - var(--size-vertical-input-v5iwwf, 32px)) / 2);
  padding-inline: 0;
}
.awsui_actions-variant-h1_2qdw9_1cxpr_271:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block: var(--space-scaled-xs-26e2du, 8px);
  padding-inline: 0;
}
.awsui_actions-variant-h2_2qdw9_1cxpr_275:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217), .awsui_actions-variant-h3_2qdw9_1cxpr_279:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: 0;
}
.awsui_root-has-description_2qdw9_1cxpr_220 > .awsui_main_2qdw9_1cxpr_239 > .awsui_actions_2qdw9_1cxpr_263:not(#\9) {
  padding-block-end: 0;
}

.awsui_title_2qdw9_1cxpr_295:not(#\9) {
  min-inline-size: 0;
  word-break: break-word;
  color: var(--color-text-heading-default-6yaix2, #0f141a);
}
.awsui_title-variant-h1_2qdw9_1cxpr_300:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  padding-block-start: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}
.awsui_title-variant-h1_2qdw9_1cxpr_300.awsui_refresh_2qdw9_1cxpr_217:not(#\9) {
  padding-block-start: calc((var(--size-vertical-input-v5iwwf, 32px) - var(--line-height-heading-xl-avbttk, 30px)) / 2);
}
.awsui_title-variant-h2_2qdw9_1cxpr_307:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
}
.awsui_title-variant-h2_2qdw9_1cxpr_307.awsui_refresh_2qdw9_1cxpr_217:not(#\9) {
  padding-block-start: calc((var(--size-vertical-input-v5iwwf, 32px) - var(--line-height-heading-l-mmm3my, 24px)) / 2);
}
.awsui_title-variant-h3_2qdw9_1cxpr_313:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
}
.awsui_title-variant-h3_2qdw9_1cxpr_313.awsui_refresh_2qdw9_1cxpr_217:not(#\9) {
  padding-block-start: calc((var(--size-vertical-input-v5iwwf, 32px) - var(--line-height-heading-m-50evfk, 22px)) / 2);
}
.awsui_title-variant-h2_2qdw9_1cxpr_307:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217), .awsui_title-variant-h3_2qdw9_1cxpr_313:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block-start: var(--space-scaled-xs-26e2du, 8px);
}
.awsui_no-wrap_2qdw9_1cxpr_249 > .awsui_title_2qdw9_1cxpr_295:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block-end: calc(var(--space-scaled-xxs-7597g1, 4px) + var(--space-scaled-xxxs-27y4hv, 2px));
}

.awsui_root-no-actions_2qdw9_1cxpr_217.awsui_root-has-description_2qdw9_1cxpr_220 > .awsui_main_2qdw9_1cxpr_239 > :not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217).awsui_title-variant-h2_2qdw9_1cxpr_307, .awsui_root-no-actions_2qdw9_1cxpr_217.awsui_root-has-description_2qdw9_1cxpr_220 > .awsui_main_2qdw9_1cxpr_239 > :not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217).awsui_title-variant-h3_2qdw9_1cxpr_313 {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_root-variant-h1_2qdw9_1cxpr_229.awsui_root-no-actions_2qdw9_1cxpr_217:not(#\9):not(.awsui_root-has-description_2qdw9_1cxpr_220):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block-end: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}

.awsui_virtual-space_2qdw9_1cxpr_334:not(#\9) {
  position: absolute !important;
  inset-block-start: -9999px !important;
  inset-inline-start: -9999px !important;
}

.awsui_info_2qdw9_1cxpr_340:not(#\9) {
  padding-inline-end: var(--space-s-34lx8l, 12px);
}

.awsui_description_2qdw9_1cxpr_344:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  color: var(--color-text-heading-secondary-0myrp3, #424650);
}
.awsui_description-variant-h1_2qdw9_1cxpr_351:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
}
.awsui_description-variant-h2_2qdw9_1cxpr_355:not(#\9) {
  font-size: var(--font-header-h2-description-size-2hj97p, 14px);
  line-height: var(--font-header-h2-description-line-height-rtewek, 20px);
}
.awsui_description-variant-h2_2qdw9_1cxpr_355:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_description-variant-h3_2qdw9_1cxpr_362:not(#\9) {
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
}
.awsui_description-variant-h3_2qdw9_1cxpr_362:not(#\9):not(.awsui_refresh_2qdw9_1cxpr_217) {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_heading_2qdw9_1cxpr_371:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  display: inline;
  font-size: inherit;
  margin-inline-end: var(--space-xs-zb16t3, 8px);
}
.awsui_heading_2qdw9_1cxpr_371:not(#\9):only-child {
  margin-block: 0;
  margin-inline: 0;
}
.awsui_heading-variant-h1_2qdw9_1cxpr_382:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  line-height: var(--line-height-heading-xl-avbttk, 30px);
  letter-spacing: var(--letter-spacing-heading-xl-sagq9c, -0.02em);
}
.awsui_heading-variant-h2_2qdw9_1cxpr_387:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
  line-height: var(--line-height-heading-l-mmm3my, 24px);
  letter-spacing: var(--letter-spacing-heading-l-qqjd4y, -0.015em);
}
.awsui_heading-variant-h3_2qdw9_1cxpr_392:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
}

.awsui_heading-text_2qdw9_1cxpr_398:not(#\9) {
  /* used in test-utils */
}
.awsui_heading-text-variant-h1_2qdw9_1cxpr_401:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  line-height: var(--line-height-heading-xl-avbttk, 30px);
  letter-spacing: var(--letter-spacing-heading-xl-sagq9c, -0.02em);
  font-weight: var(--font-weight-heading-xl-yvsksd, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_heading-text-variant-h2_2qdw9_1cxpr_409:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
  line-height: var(--line-height-heading-l-mmm3my, 24px);
  letter-spacing: var(--letter-spacing-heading-l-qqjd4y, -0.015em);
  font-weight: var(--font-weight-heading-l-f8711v, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_heading-text-variant-h3_2qdw9_1cxpr_417:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
  font-weight: var(--font-weight-heading-m-m2ekmb, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}

.awsui_counter_2qdw9_1cxpr_426:not(#\9) {
  color: var(--color-text-counter-goqopo, #656871);
  font-weight: 400;
}