/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_sticky-notifications_154bi_14rkj_9:not(#\9) {
  position: sticky;
  z-index: 850;
}
.awsui_sticky-notifications-with-background_154bi_14rkj_13:not(#\9) {
  background-color: var(--color-background-layout-main-cp7lkl, #ffffff);
}

.awsui_has-notifications-content_154bi_14rkj_17:not(#\9) {
  padding-block-start: var(--space-scaled-xs-26e2du, 8px);
}