@import "./common/constants.scss";

body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1024px) {
  main {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.global-spinner {
  position: fixed !important;
  left: 50%;
  top: 50%;
  margin-left: -24px;
  margin-top: -24px;
}

img.profile {
  border: 1px solid #ccc;
  width: 96px;
  height: 96px;
}

.rmwc-avatar {
  border: 2px solid #ccc;
  margin-bottom: $spacing-small;
}


a[class^="awsui_header-link"] {
  display: block !important;
}

span[class^="awsui_header-link-text"] {
  display: block;
  margin-top: 16px;
  font-weight: 700 !important;
}

div[class^="awsui_trigger-wrapper"] {
  box-shadow: none !important;
}

button.transparent-text {
  color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    border: 2px solid #0972d3;
    border-radius: 20px;
  }
}

div.search-results {
  position: absolute;
  top: 50px;
  left: 25%;
  width: 50%;
  padding: 12px 20px;
  color: #000716;
  background-color: white;
  border: 2px solid #7d8998;
  border-radius: 16px;
  z-index: 1000;
  max-height: 80%;
  overflow-y: scroll;
}


div.scrim {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(35, 47, 62, 0.7);
  z-index: 2;
}

.ask-the-librarian {
  position: fixed !important;
  margin-left: -144px;
  left: 50%;
  bottom: 20px;
}

// RadFormField
.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.required {
  &::after {
    content: " *";
    color: red;
    font-weight: 900;
  }
}

.font-size-16 {
  font-size: 16px !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

button.sort {
  background: url('../public/sort_icon.svg') no-repeat !important;
  width: 32px;
  height: 32px;
  border: none !important;
  padding: 0;
}


button.filter {
  background: url('../public/filter_icon.svg') no-repeat !important;
  width: 32px;
  height: 32px;
  border: none !important;
  padding: 0;
}

a {
  text-decoration: none !important;
}

div[class*="awsui_large"] {
  max-width: 1024px !important;
}

img[class^="awsui_logo"] {
  max-height: 64px !important;
  padding-top: 8px;
}

div[class^="awsui_section"] {
  a {
    padding-left: 5px !important;
  }
}

div[class^="awsui_section"] {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div[class^="awsui_header-wrapper"] {
  font-size: 14px !important;
  font-weight: 400 !important;
}

div[class^="awsui-context-top-navigation"] {
  margin-left: 30px;
  margin-right: 40px;
  margin-bottom: 10px;
  padding-bottom: 15px;
  padding-left: -10px;
}

nav[class^="awsui_navigation"] {
  border-right: none !important;
}

div.feedback {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  & button {
    background-color: transparent;
    border: none;
    padding: 0;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }

  & button.thumbs-up {
    background-image: url('../public/thumbs_up.svg');
  }

  & button.thumbs-up-active {
    background-image: url('../public/thumbs_up_active.svg');
  }

  & button.thumbs-down {
    background-image: url('../public/thumbs_down.svg');
  }

  & button.thumbs-down-active {
    background-image: url('../public/thumbs_down_active.svg');
  }
}

.breadcrumb-container {
  display: flex;
  align-items: center;
}

.breadcrumb-carat {
  display: inline-block;
  width: 32px;
  height: 16px;
  background-image: url('../public/breadcrumb_carat.png');
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: center; // calc(50% + 2px);
}

div[class^="awsui_list-container"] {
  padding-bottom: 66px;
}

.nowrap {
  white-space: nowrap;
}