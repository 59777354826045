.sign-in {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  & .left {
    position: absolute;
    top: 0;
    bottom: 50%;
    left: 0;
    right: 0;
    background: url('../public/image11.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  & .right {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 100px 20px;
    background: url('../public/logo.png');
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: 20px 20px;
  }
}

#firebaseui-auth-container {
  position: absolute;
  top: 50%;
  margin-top: 220px;
  text-align: center;

  & .firebaseui-container {
    margin: 0;

    & .firebaseui-card-content {
      padding: 0;

      & .firebaseui-idp-list {
        padding-inline-start: 20px;

        & .firebaseui-list-item {
          text-align: left;
        }
      }
    }
  }
}


@media only screen and (min-width: 768px) {
  .sign-in {
    background: url('../public/logo.png');
    background-repeat: no-repeat;
    background-position: calc(50% + 140px) 40px;
    background-size: 200px;

    & .left {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 50%;
      background: url('../public/image11.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    & .right {
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 50%;
      right: 0;
      margin-top: -180px;
      padding: 40px;
      background: none;
    }
  }

  #firebaseui-auth-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(50% - 40px);
    margin-left: 40px;
    margin-top: -20px;
    text-align: center;

    & .firebaseui-container {
      margin: 0;
      padding: 20px;

      & .firebaseui-card-header {
        padding-left: 0;
      }

      & .firebaseui-card-content {
        padding: 0;

        & .firebaseui-idp-list {
          padding-inline-start: 40px;

          & .firebaseui-list-item {
            text-align: left;
          }
        }
      }
    }
  }
}