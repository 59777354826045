/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_card-inner_p8a6i_cn0q0_161:not(#\9) {
  transition-property: background-color;
  transition-duration: var(--motion-duration-transition-show-paced-9s9l5z, 180ms);
  transition-timing-function: var(--motion-easing-transition-show-paced-eobwvi, ease-out);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_card-inner_p8a6i_cn0q0_161:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_card-inner_p8a6i_cn0q0_161:not(#\9), .awsui-mode-entering .awsui_card-inner_p8a6i_cn0q0_161:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_card-inner_p8a6i_cn0q0_161:not(#\9)::before {
  transition-property: border-top-color, border-right-color, border-bottom-color, border-left-color;
  transition-duration: var(--motion-duration-transition-show-paced-9s9l5z, 180ms);
  transition-timing-function: var(--motion-easing-transition-show-paced-eobwvi, ease-out);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_card-inner_p8a6i_cn0q0_161:not(#\9)::before {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_card-inner_p8a6i_cn0q0_161:not(#\9)::before, .awsui-mode-entering .awsui_card-inner_p8a6i_cn0q0_161:not(#\9)::before {
  animation: none;
  transition: none;
}

.awsui_root_p8a6i_cn0q0_193:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.awsui_header-variant-full-page_p8a6i_cn0q0_232.awsui_header-refresh_p8a6i_cn0q0_232:not(#\9) {
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: calc(var(--space-container-header-bottom-vj01hn, 8px) + var(--space-table-header-tools-full-page-bottom-xulfq9, 4px));
}

.awsui_list_p8a6i_cn0q0_239:not(#\9) {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-block: 0;
  padding-inline: 0;
  list-style: none;
  margin-block: 0;
  margin-inline-start: calc(var(--space-grid-gutter-ieqn7e, 20px) * -1);
  margin-inline-end: 0;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-1_p8a6i_cn0q0_250 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 100%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-2_p8a6i_cn0q0_253 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 50%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-3_p8a6i_cn0q0_256 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 33.3333333333%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-4_p8a6i_cn0q0_259 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 25%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-5_p8a6i_cn0q0_262 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 20%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-6_p8a6i_cn0q0_265 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 16.6666666667%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-7_p8a6i_cn0q0_268 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 14.2857142857%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-8_p8a6i_cn0q0_271 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 12.5%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-9_p8a6i_cn0q0_274 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 11.1111111111%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-10_p8a6i_cn0q0_277 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 10%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-11_p8a6i_cn0q0_280 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 9.0909090909%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-12_p8a6i_cn0q0_283 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 8.3333333333%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-13_p8a6i_cn0q0_286 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 7.6923076923%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-14_p8a6i_cn0q0_289 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 7.1428571429%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-15_p8a6i_cn0q0_292 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 6.6666666667%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-16_p8a6i_cn0q0_295 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 6.25%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-17_p8a6i_cn0q0_298 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 5.8823529412%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-18_p8a6i_cn0q0_301 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 5.5555555556%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-19_p8a6i_cn0q0_304 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 5.2631578947%;
}
.awsui_list_p8a6i_cn0q0_239.awsui_list-grid-20_p8a6i_cn0q0_307 > .awsui_card_p8a6i_cn0q0_161:not(#\9) {
  inline-size: 5%;
}

.awsui_selection-control_p8a6i_cn0q0_311:not(#\9) {
  position: absolute;
  box-sizing: border-box;
  inline-size: calc(var(--size-control-28i7hr, 16px) + 2 * var(--space-card-horizontal-tuwbbd, 20px));
  inset-block-start: 0;
  inset-inline-end: 0;
  padding-block: var(--space-card-vertical-m591he, 16px);
  padding-inline: var(--space-card-horizontal-tuwbbd, 20px);
}

.awsui_loading_p8a6i_cn0q0_321:not(#\9),
.awsui_empty_p8a6i_cn0q0_322:not(#\9) {
  overflow: hidden;
  text-align: center;
  color: var(--color-text-empty-o6660v, #656871);
  margin-block-end: var(--space-scaled-l-0hpmd7, 20px);
}

.awsui_has-header_p8a6i_cn0q0_329:not(#\9) {
  margin-block-start: var(--space-grid-gutter-ieqn7e, 20px);
}
.awsui_has-header_p8a6i_cn0q0_329.awsui_refresh_p8a6i_cn0q0_332.awsui_header-variant-full-page_p8a6i_cn0q0_232:not(#\9) {
  margin-block-start: var(--space-scaled-s-aqzyko, 12px);
}

.awsui_card_p8a6i_cn0q0_161:not(#\9) {
  display: flex;
  overflow-wrap: break-word;
  word-wrap: break-word;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  list-style: none;
}
.awsui_card-inner_p8a6i_cn0q0_161:not(#\9) {
  position: relative;
  background-color: var(--color-background-container-content-epgqkc, #ffffff);
  margin-block-start: 0;
  margin-block-end: var(--space-grid-gutter-ieqn7e, 20px);
  margin-inline-start: var(--space-grid-gutter-ieqn7e, 20px);
  margin-inline-end: 0;
  padding-block: var(--space-card-vertical-m591he, 16px);
  padding-inline: var(--space-card-horizontal-tuwbbd, 20px);
  inline-size: 100%;
  min-inline-size: 0;
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  box-sizing: border-box;
}
.awsui_card-inner_p8a6i_cn0q0_161:not(#\9)::before {
  content: "";
  position: absolute;
  inset-inline-start: 0px;
  inset-block-start: 0px;
  inline-size: 100%;
  block-size: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-block-start: var(--border-container-top-width-uugwwl, 0px) solid var(--color-border-container-top-736buh, transparent);
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  z-index: 1;
}
.awsui_card-inner_p8a6i_cn0q0_161:not(#\9)::after {
  content: "";
  position: absolute;
  inset-inline-start: 0px;
  inset-block-start: 0px;
  inline-size: 100%;
  block-size: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
}
.awsui_card-inner_p8a6i_cn0q0_161:not(#\9):not(.awsui_refresh_p8a6i_cn0q0_332)::after {
  box-shadow: var(--shadow-container-fxmdn3, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
}
.awsui_card-inner_p8a6i_cn0q0_161.awsui_refresh_p8a6i_cn0q0_332:not(#\9)::after {
  border-block: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
  border-inline: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_card-header_p8a6i_cn0q0_403:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
  font-weight: var(--font-weight-heading-m-m2ekmb, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_card-header-inner_p8a6i_cn0q0_411:not(#\9) {
  inline-size: 100%;
  display: inline-block;
}
.awsui_card-selectable_p8a6i_cn0q0_415 > .awsui_card-inner_p8a6i_cn0q0_161 > .awsui_card-header_p8a6i_cn0q0_403:not(#\9) {
  inline-size: 90%;
}
.awsui_card-selected_p8a6i_cn0q0_418 > .awsui_card-inner_p8a6i_cn0q0_161:not(#\9) {
  background-color: var(--color-background-item-selected-njak80, #f0fbff);
}
.awsui_card-selected_p8a6i_cn0q0_418 > .awsui_card-inner_p8a6i_cn0q0_161:not(#\9)::before {
  border-block: var(--border-item-width-yel47s, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
  border-inline: var(--border-item-width-yel47s, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
}

.awsui_section_p8a6i_cn0q0_426:not(#\9) {
  display: inline-block;
  box-sizing: border-box;
  padding-block-start: var(--space-xs-zb16t3, 8px);
  padding-block-end: var(--space-scaled-xs-26e2du, 8px);
  padding-inline: 0;
  vertical-align: top;
}
.awsui_section-header_p8a6i_cn0q0_434:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  font-weight: var(--font-display-label-weight-815ja9, 700);
  color: var(--color-text-label-4yezqy, #0f141a);
}
.awsui_section-content_p8a6i_cn0q0_440:not(#\9) {
  /* used in test-utils */
}

.awsui_section_p8a6i_cn0q0_426:not(#\9):last-child {
  padding-block-start: var(--space-xs-zb16t3, 8px);
  padding-block-end: 0;
  padding-inline: 0;
}

.awsui_footer-pagination_p8a6i_cn0q0_450:not(#\9) {
  display: flex;
  justify-content: flex-end;
}